import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../../utils/Api";
import Skeleton from "react-loading-skeleton";
import ModalCreateQr from "./ModalCreateQr/ModalCreateQr";
import ModalViewQr from "./ModalViewQr/ModalViewQr";
import ModalScanner from "../../../components/ModalScanner/ModalScanner";
import toast from "react-hot-toast";
import QrReader from "../../../components/QrReader/QrReader";

export default function BrandManageProducts(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [width, SetWidth] = useState(innerWidth);
  const [id_value, SerIdValue] = useState(useParams());
  const [qr_data, SetQrData]: any = useState([]);
  const [is_loading, SetIsLoading] = useState(true);
  const [modal_create_qr, SetModalCreateQr] = useState({
    is_open: false,
    data: "",
    type: "",
  });
  const [modal_view_qr, SetModalViewQr] = useState({
    is_open: false,
    data: "",
    type: "",
  });
  const [outlet_list, SetOutletList] = useState([]);
  const [selected_outlet, SetSelectedOutlet] = useState({});
  const [modal_scanner_data, SetModalScannerData] = useState({
    is_open: false,
    // from: "company",
    data: {},
  });

  useEffect(() => {
    console.log("QrCodeList on mount:", props);
    get_qrcode();
    get_outlets_and_channels();
  }, []);

  async function get_outlets_and_channels() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };
    let response: any = await api("/brand/outlet_channel_list", pass_data);
    console.log("/brand/outlet_channel_list response :", response.response);
    if (response.status_code == 200) {
      let outlet_data: any = response.response.outlets;
      outlet_data.map((ele: any, index: any) => {
        ele.lable = ele.name;
        ele.value = ele.id;
        if (index == 0) {
          SetSelectedOutlet(ele);
        }
      });
      SetOutletList(outlet_data);
    }
  }

  async function get_qrcode() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };
    let response: any = await api("/brand/qrs", pass_data);
    console.log("/brand/qrs response :", response.response);
    if (response.status_code == 200) {
      SetQrData(response.response.qrs);
      SetIsLoading(false);
    }
  }

  return (
    <div className="">
      <div
        className={OutletContext ? "head-bar sticky-sm py-3" : "head-bar py-3"}
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="row align-items-center">
          <div className="col-5">
            <h6 className="fs-5  mb-0">Qr Code</h6>
          </div>
          {/* scan qr / create new */}
          {!is_loading ? (
            <div className="d-flex col-7 px-0 text-end">
              <button
                className="btn bg-prime text-white btn-radius px-3 btn-sm"
                onClick={() => {
                  SetModalScannerData((prevValue) => {
                    prevValue.is_open = true;
                    // prevValue.from = "";
                    // prevValue.data = {};
                    prevValue.data = {
                      outlet_list: outlet_list,
                      selected_outlet: selected_outlet,
                    };
                    return { ...prevValue };
                  });
                }}
              >
                Scan QR
              </button>
              <button
                className="btn bg-prime text-white btn-radius px-3 btn-sm"
                onClick={() => {
                  console.log("Create new");
                  SetModalCreateQr((prevalue: any) => {
                    prevalue.is_open = true;
                    prevalue.data = {
                      outlet_list: outlet_list,
                      selected_outlet: selected_outlet,
                    };
                    prevalue.type = "";
                    return { ...prevalue };
                  });
                }}
              >
                Create New
              </button>
            </div>
          ) : null}

        </div>
      </div>

      <div
        className={
          OutletContext ? "mt-3 px-md-3 px-sm-0" : "mt-3 px-md-3 px-sm-0"
        }
        style={OutletContext == false ? { width: width } : {}}
      >
        <div className="mt-3 mb-5">
          {is_loading ? (
            // skeleton
            <div className="row">
            {[1, 2, 3, 4, 5, 6].map((ele, i) => (
            <div className="card col-lg-6 p-3 m-2">
            <Skeleton height={10} width={150} />
            <Skeleton height={10} width={200} /> 
            
            <Skeleton height={10} width={150} />
            
            <Skeleton height={10} width={120} />
            <Skeleton height={10} width={180} />
            
            <div className="text-center pt-3">
              <Skeleton height={30} width={400} /> 
            </div>
          </div>
            ))}
          </div>
          ) : Object.keys(qr_data).length > 0 ? (
            <div className="row d-flex">
              {qr_data.map((ele: any) => (
                <div className="card col-lg-6 p-3 m-2">
                  <p>Id : {ele.id}</p>
                  <p>Qr Link :{ele.qr_link}</p>
                  {ele.hasOwnProperty("outlet_name") ? (
                    <p>Outlet name :{ele.outlet_name}</p>
                  ) : null}
                  <p>Created At : {ele.date}</p>
                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      SetModalViewQr((prevalue) => {
                        prevalue.is_open = true;
                        prevalue.data = {
                          qr_link: ele.qr_link,
                          id: ele.id,
                        };
                        prevalue.type = "";
                        return { ...prevalue };
                      });
                    }}
                  >
                    View
                  </button>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center">
              <img
                src={require("../../../assets/icon/empty.png")}
                className="empty-img pb-2"
              />
              <p className="v-small mb-0">No Data, Please Add Collections</p>
            </div>
          )}
        </div>
      </div>

      <ModalCreateQr
        open={modal_create_qr.is_open}
        data={modal_create_qr.data}
        close={(data: any) => {
          console.log("ModalCreateQr on close :", data);
          SetModalCreateQr((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            SetQrData(data.data.whole_data);
            toast.success("Qr code created")
            SetModalViewQr((prevalue) => {
              prevalue.is_open = true;
              prevalue.data = {
                qr_link: data.data.qr_link,
                id: data.data.id,
              };
              prevalue.type = "";
              return { ...prevalue };
            });
          }
        }}
      />

      <ModalViewQr
        open={modal_view_qr.is_open}
        data={modal_view_qr.data}
        close={(data: any) => {
          console.log("SetModalViewQr on close :", data);
          SetModalViewQr((prevValue: any) => {
            prevValue.is_open = false;
            prevValue.data = "";
            prevValue.type = "";
            return { ...prevValue };
          });
          if (data.action == "save") {
          }
        }}
      />

      <QrReader
        open={modal_scanner_data.is_open}
        data={modal_scanner_data.data}
        from={modal_scanner_data.from}
        close={(data : any) => {
          console.log("ModalReward on close :", data);
          SetModalScannerData((prevValue) => {
            prevValue.is_open = false;
            // prevValue.type = "company";
            prevValue.data = {};
            return { ...prevValue };
          });
          if (data.action == "save") {
            console.log(data.data.whole_data.status)
            if(data.data.whole_data.status){
            toast.success("Qr code activated")
            get_qrcode();
          }else{

            toast.error(data.data.whole_data.message)
          }
          }
        }}
      />
    </div>
  );
}
