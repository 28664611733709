import React, { useState, useEffect } from "react";
import bootstrap from "../../../../assets/libraries/bootstrap/js/bootstrap";
import Select from "react-select";
import { api } from "../../../../utils/Api";
import { useParams } from "react-router-dom";
import SelectUserName from "../../../../components/SelectUserName/SelectUserName";

let myModal = {};

const franchise_initial_value = {
  organization_id: "",
  user_id: "",
  roles: {},
  address: "",
  mobile: "",
  email: "",
  whatsapp: "",
  website: "",
  selected_user: { name: "" },
};

export default function ModalCreateQr(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());
  const [save_data, SetSaveData] = useState(franchise_initial_value);
  const [roles, SetRoles] = useState([]);
  const [custom_roles, SetCustomRoles] = useState([]);
  const [selected_roles, SetSelectedRoles] = useState([]);
  const [selected_custom_roles, SetSelectedCustomRoles] = useState([]);
  const [userOpen, setUserOpen] = useState(false);
  const [organization_data, SetOrganizationData] = useState([]);
  const [user_data, SetUserData] = useState({});
  const [radio_val, SetRadioVal] = useState("1");
  const [selected_outlet, SetSelectedOutlet]: any = useState({});

  useEffect(() => {
    if (props.open) {
      console.log("ModalCreateQr On Open :", props);
      myModal = new bootstrap.Modal(
        document.getElementById("CreateQrModal"),
        {}
      );
      myModal.show();
      SetSelectedOutlet(props.data.selected_outlet);
    }
  }, [props.open]);

  useEffect(() => {
    console.log("ModalCreateQr On mount :", props);
    let myModal1 = document.getElementById("CreateQrModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      props.close(close_data);
    });
  }, []);

  async function generate_qr_code() {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
      },
      post: {},
    };
    if (radio_val == "2") {
      pass_data.post.outlet_id = selected_outlet.id;
    }
    let response: any = await api("/brand/generate_qr", pass_data);
    console.log("/brand/generate_qr :", response);
    if (response.status_code == 200) {
      let response_data = response.response.qrs;
      let item = close_data;
      item.action = "save";
      item.data = {
        qr_link : response_data[0].qr_link,
        id : response_data[0].id,
        whole_data : response_data,
      };
      SetCloseData(item);
      myModal.hide();
    }
  }

  return (
    <div
      className="modal"
      id="CreateQrModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-fullscreen-sm-down modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header shadow-bottom">
            <h6 className="  modal-title" id="ModalLabel">
              Create Qr
            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>

          <div className="modal-body">
            {/* type 1 */}
            <div className="form-check mb-2">
              <input
                className="form-check-input"
                checked={radio_val == "1"}
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                onChange={(e) => {
                  SetRadioVal(e.target.value);
                }}
                value={1}
              />
              <label
                className="form-check-label text-black"
                htmlFor="flexRadioDefault1"
              >
                Generate Qr For Brand
              </label>
            </div>
            {/* type 2 */}
            <div className="form-check mb-2">
              <input
                className="form-check-input"
                checked={radio_val == "2"}
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                onChange={(e) => {
                  SetRadioVal(e.target.value);
                }}
                value={2}
              />
              <label
                className="form-check-label text-black"
                htmlFor="flexRadioDefault2"
              >
                Generate Qr For Outlet
              </label>
            </div>
            {/* outlet list */}
            {radio_val == "2" ? (
              <div className="">
                <label className="item-center">Outlet</label>
                <select
                  data-show-content="true"
                  className="form-select mb-2"
                  id="exampleFormControlSelect1"
                  value={selected_outlet.id}
                  onChange={(e) => {
                    console.log("on change :", e.target.value);
                    let value_data = e.target.value;
                    props.data.outlet_list.map((ele: any, index: any) => {
                      if (ele.id == value_data) {
                        SetSelectedOutlet(ele);
                      }
                    });
                  }}
                >
                  {props.data.outlet_list?.map((ele: any, i: any) => (
                    <option key={i} value={ele?.id}>
                      {ele?.name}
                    </option>
                  ))}
                </select>
              </div>
            ) : null}

            <div className="">
              <button
                type="button"
                className="btn btn-secondary-outline btn-radius px-3 me-2"
                onClick={() => {
                  let item = close_data;
                  item.action = "close";
                  SetCloseData(item);
                  // SetSaveData(initial_value);
                  myModal.hide();
                }}
              >
                Close
              </button>
              <button
                type="button"
                className="btn bg-prime text-white btn-radius px-3"
                onClick={() => {
                  console.log("save");
                  // get_fcreate();
                  if (radio_val == 2) {
                    console.log("selected outlet :", selected_outlet);
                    if (Object.keys(selected_outlet).length > 0) {
                      generate_qr_code();
                    } else {
                      console.log("select outlet");
                    }
                  } else {
                    generate_qr_code();
                  }
                }}
              >
                Generate
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
