import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import Chart from "react-apexcharts";
import Statistics from "./Statistics";

export default function BrandDashboard(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext()
  const [incomeseries, SetIncomeSeries] = useState([
    {
      name: "Income",
      data: [5000, 8000, 10000, 25000, 40000, 50000, 100000],
    },
  ]);
  const [expenseseries, SetExpenseSeries] = useState([
    {
      name: "Expenses",
      data: [5000, 8000, 10000, 5000, 4000, 50000, 10000],
    },
  ]);
  const [incomeptions, SetIncomeOptions] = useState({
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "Income Details",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      // max: 100,
      count: 12,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#0267ff"],
        // shadeIntensity: 1,
        type: "horizontal",
        // opacityFrom: 1,
        // opacityTo: 1,
        stops: [0, 100, 100, 100],
      },
    },
    stroke: {
      curve: "smooth",
    },

    markers: {
      size: 0,
    },
  });
  const [expenseoptions, SetExpenseOptions] = useState({
    chart: {
      type: "line",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    title: {
      text: "Expense Details",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    yaxis: {
      // max: 100,
      count: 12,
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        gradientToColors: ["#d90318"],
        // shadeIntensity: 1,
        type: "horizontal",
        // opacityFrom: 1,
        // opacityTo: 1,
        stops: [50, 10, 4, 40],
      },
    },
    stroke: {
      curve: "smooth",
    },

    markers: {
      size: 0,
    },
  });
  const [width, SetWidth] = useState(innerWidth);

  useEffect(() => {
    console.log("AdminDashboard On mount :", props);
    console.log("AdminDashboard context :", context);
  }, []);

  return (
    <div 
    // style={{minHeight: "95vh"}}
    className="mb-5"
    >
      <div className={OutletContext ? "head-bar sticky-sm" : "head-bar sticky-sm"}
        style={OutletContext == false ? { width: width } : {}} >
        <h6 className="  mb-1">Brand Dashboard</h6>
        {/* <small>Income, Expense, Outlets</small> */}
      </div>
      <div className="mt-3 mb-3">
        <Statistics />
      </div>
{/* 
     <div className="table-headpage80 px-md-3 px-sm-0"
          style={width < 786 ? (
            OutletContext == false ? { width: width } : {}
          ) : (
            OutletContext == false ? { width: width, paddingTop: "3rem" } : {}
          )} >
        <div className="row">
          <div className="col-md-6">
            <div className="card p-3">
              <div id="linechart">
          
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card p-3">
              <div id="linechart">
           
              </div>
            </div>
          </div>
        </div>
        <div className="row p-2">
          <div className="pt-4 col-md-5">
            <h6 className="  mb-0">
              <b>Recent Orders</b>
            </h6>
            <small className="text-gray">Project reports</small>
            <div className="card mt-3 p-3">
              <div className="row align-items-center">
                <div className="p-2 col-2 col-md-1 col-lg-2">
                  <img
                    className="w-75"
                    src={require("../../../assets/img/folders.png")}
                  />
                </div>
                <div className="col-8 col-md-9 col-lg-8">
                  <h6 className="mb-0">videos</h6>
                  <small className="text-dark">3 files</small>
                </div>
                <div className="col-2 text-end">
                  <i
                    className="fas fa-ellipsis-v cursor"
                    style={{ color: "gray" }}
                  ></i>
                </div>
              </div>
            </div>

            <div className="card mt-3 p-3">
              <div className="row align-items-center">
                <div className="p-2 col-2 col-md-1 col-lg-2">
                  <img
                    className="w-75"
                    src={require("../../../assets/img/folders.png")}
                  />
                </div>
                <div className="col-8 col-md-9 col-lg-8">
                  <h6 className="mb-0">Images</h6>
                  <small className="text-dark">150 files</small>
                </div>
                <div className="col-2 text-end">
                  <i
                    className="fas fa-ellipsis-v cursor"
                    style={{ color: "gray" }}
                  ></i>
                </div>
              </div>
            </div>

            <div className="card mt-3 p-3">
              <div className="row align-items-center">
                <div className="p-2 col-2 col-md-1 col-lg-2">
                  <img
                    className="w-75"
                    src={require("../../../assets/img/folders.png")}
                  />
                </div>
                <div className="col-8 col-md-9 col-lg-8">
                  <h6 className="mb-0">audio</h6>
                  <small className="text-dark">3 files</small>
                </div>
                <div className="col-2 text-end">
                  <i
                    className="fas fa-ellipsis-v cursor"
                    style={{ color: "gray" }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div className="pt-4 col-md-7">
            <div className="card mt-3 p-3">
              <div className="d-flex align-items-end border-bottom pb-2 px-2 mb-2">
                <div>
                  <h6 className="  mb-0">
                    <b>Outlets</b>
                  </h6>
                  <small className="text-gray">Outlet reports</small>
                </div>
                <div className="text-end ms-auto cursor">
                  <small>View more</small>
                </div>
              </div>
              <div className="mt-2 mx-3">
                <h6>Outlet Name</h6>
                <p className="v-small">Album</p>
              </div>
              <div className="mt-2 mx-3">
                <h6>Outlet Name</h6>
                <p className="v-small">Album</p>
              </div>
              <div className="mt-2 mx-3">
                <h6>Outlet Name</h6>
                <p className="v-small">Album</p>
              </div>
              <div className="mt-2 mx-3">
                <h6>Outlet Name</h6>
                <p className="v-small">Album</p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
