import React, { useState, useEffect, useContext, useRef } from "react";
import OrderComponent from "./OrderComponent";
import ModalLocationLive from "./ModalLocationLive";
import ModalRejected from "./ModalRejected";
import ModalChooseItems from "./ModalChooseItems";
import ModalEditPackageDetails from "./ModalEditPackageDetails";
import { api } from "../../../utils/Api";
import ModalPackageDetails from "./ModalPackageDetails";
import { AppHelper } from "../../../utils/Helper";
import { mobileApp } from "../../../utils/MobileApp";
import { useNavigate } from 'react-router-dom';

import DataContext from "../../../utils/Context/DataContext";

import { AntDesign, Entypo, Feather } from "@expo/vector-icons";

import { makeStyles } from "@material-ui/core/styles";
import { Link, useParams } from "react-router-dom";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@material-ui/core";
import {
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  FontAwesome,
  FontAwesome5,
} from "@expo/vector-icons";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import ModalOrderTracking from "./ModalOrderTracking";
import ModalCancelOrder from "./ModalCancelOrder";
import { Dialog } from "@material-ui/core";
import { AppConfig } from "../../../config";
import OrderChat from "../../../components/OrderChat/OrderChat";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default function OutletOrderDetails(props: any) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const [counter, setCounter] = useState(0);
  const [view_type, SetViewType] = useState("all");
  const [is_loading, SetIsLoading] = useState(false);

  const anchorRef = React.useRef(null);
  const context : any = useContext(DataContext);
  const [id_value, SetIdValue] = useState(useParams());
  const [response_data, SetResponseData] = useState({});
  const [order_data, SetOrderData]: any = useState([]);
  const [opened_timeline, SetOpenTimeline] = useState([]);
  const [data, SetData] = useState([]);
  const [ack, SetAck] = useState(false);
  const [width, SetWidth] = useState(innerWidth);
  const [mapdriverModal, SetMapDriverModal] = useState({
    is_open: false,
    lat: "",
    lon: "",
    id: "",
  });

  const [order_tracking_modal, SetOrderTrackingModal] = useState({
    is_open: false,
    lat: "",
    lon: "",
    id: "",
    delivery_id: "",
    delivery_index: "",
    brand_id:"",
    outlet_id:"",
    
  });

  const [rejectModal, SetRejectModal] = useState({
    is_open: false,
    data: {},
  });

  const [chooseItemModal, SetChooseItemModal] = useState({
    is_open: false,
    data: {},
    id: "",
  });

  const [prepTimeModal, SetPrepTimeModal] = useState({
    is_open: false,

    brand: id_value.brand_id,
    outlet: id_value.outlet_id,
    approx_time: "",
    order_id: "",
    data: {},
    index: "",
    max_prep_time: 0,
  });

  const [createPackageModal, SetCreatePackageModal] = useState({
    is_open: false,
    data: {},
    id: "",
  });

  const [editPackageModal, SetEditPackageModal] = useState({
    is_open: false,
    data: {},
    id: "",
  });

  const [cancel_order_modal, SetCancelOrderModal] = useState({
    open: false,
    data: [],
  });

  const [is_cancel_loading, SetIsCancelLoading] = useState(false);

  let order_details_call_interval: any;

  const prevOpen = React.useRef(open);

  const Spinner = () => <div className="loader-spinner"></div>;
  const [chatModal, SetChatModal]: any = useState({
    is_open: false,
    id: "",
    data: {},
  });

  // React.useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     anchorRef.current.focus();
  //   }

  //   prevOpen.current = open;
  // }, [open]);

  useEffect(() => {
    if(context.app_data.pages.hasOwnProperty('11')){
      if(context.app_data.pages[11]){
        SetIsLoading(true)
    get_order(view_type);
    order_details_call_interval = setInterval(function () {
      // if (order_details.hasOwnProperty("order_status_id")) {
      SetOrderData((prevalue: any) => {
        console.log("order_data in setInterval prevalue:", prevalue);
        if (
          prevalue.length > 0 &&
          prevalue[0].order_status_id != 6 &&
          prevalue[0].order_status_id != 4
        ) {
          console.log("Calling get_order_details");
          get_order(view_type);
        }
        return [...prevalue];
      });

      // }
    }, 20000);
    return () => {
      clearInterval(order_details_call_interval);
    };
  }else{
    console.log("hi")
    navigate("/no_access");

  }}else{
    SetIsLoading(true)
  }
  }, [props.view,context.app_data.pages?.[11]]);

  // useEffect(() => {
  //   console.log("useEffect On id_value change  :", id_value);

  //   get_order();
  // }, [id_value]);

  async function get_order(view_type: any) {
   
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        order_id: id_value.order_id,
      },
    };

    let response: any = await api("/outlet/order_details", pass_data);

    if (response?.status_code == 200) {
      if (response.response.hasOwnProperty("orders")) {
        let orders = response.response.orders;

        // orders.map((ele: any) => {
        //   ele.open_prepare_data = false;
        // });
        SetResponseData(response?.response);
        console.log(response?.response);
        SetData(response.response.options);
        SetAck(response.response.orders[0].ask_cancelled_ack);
        // SetData(response?.response?.options);
        // SetOrderData(orders);
        SetOrderData((prevalue: any) => {
          const old_value = prevalue;
          console.log("old_value :", old_value);
          orders.map((ele: any, index: any) => {
            if (old_value.length > 0) {
              if (old_value[index].hasOwnProperty("open_prepare_data")) {
                if (old_value[index].id == ele.id) {
                  if (ele.order_status_id == 1) {
                    ele.open_prepare_data = old_value[index].open_prepare_data;
                  } else {
                    ele.open_prepare_data = false;
                  }
                }
              } else {
                console.log("no key");
                ele.open_prepare_data = false;
              }
            } else {
              console.log("length == 0");
              ele.open_prepare_data = false;
            }
          });
          prevalue = orders;
          return [...prevalue];
        });
    SetIsLoading(false)

      }
    }
  }

  async function submitAcknowledge() {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        order_id: id_value.order_id,
      },
    };

    let response: any = await api(
      "/outlet/order_cancel_acknowledge",
      pass_data
    );

    if (response?.status_code == 200) {
      if (response.response.hasOwnProperty("orders")) {
        // let orders = response.response.orders;
        const ring = {
          function: "refreshringnotifications",
          app_type: "react_web",
        };
        mobileApp(ring);
        // SetOrderData(orders)
        get_order();
      }
    }
  }

  const handleClick1 = (get_data: any) => {
    // Counter state is incremented
    // setCounter(counter + 1);
    SetOrderData((prevalue: any) => {
      let sec_value = prevalue[get_data.index].max_preparation_duration;
      let minutes = Math.floor(sec_value / 60);
      setCounter((prevalue1: any) => {
        if (prevalue1 < minutes) {
          prevalue1 = prevalue1 + 1;
        } else {
          console.log("on high counter prevalue :", prevalue1);
          console.log("on high minutes :", minutes);
        }
        return prevalue1;
      });
      return [...prevalue];
    });
  };

  // Function is called everytime decrement button is clicked
  const handleClick2 = () => {
    // Counter state is decremented
    // setCounter(counter - 1);
    setCounter((prevalue: any) => {
      if (prevalue > 0) {
        prevalue = prevalue - 1;
      }
      return prevalue;
    });
  };

  async function submit_ready_ship(r_id: any) {
    // props.close();

    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        delivery_id: r_id,
      },
      post: {
        status: "Ready",
      },
    };

    console.log("pass_data :", pass_data);

    let data_res = await api("/outlet/update_delivery_status", pass_data);
    console.log("/outlet/update_delivery_status :", data_res.response);
    if (data_res.status_code == 200) {
      let item = close_data;
      // SetStatus(true);
      // item.action = "edit";
      item.value = data_res.response.orders[0];
      // item.index = props.data.index;
      // console.log("Close data : ", item);
      // SetCloseData(item);
      // SetFranchiseData(franchise_initial_value);
      // myModal.hide();
      // console.log("gfrhjgf")
      // props.close();
    }
  }

  function check_timeline_open(get_data: any) {
    let index_value = opened_timeline.findIndex(
      (ele: any) => ele == get_data.id
    );
    if (index_value != -1) {
      return true;
    } else {
      return false;
    }
  }

  async function accept(order_id: any) {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        order_id: order_id,
      },

      post: {
        status: "Accept",
        // outlet : id_value.outlet_id,
        // order_id : order_id,
      },
    };
    console.log("remove_owner record :", order_id);

    let data = await api("/outlet/update_order_status", pass_data);
    console.log("/outlet/update_order_status :", data);
    if (data.status_code == 200) {
      const ring = {
        function: "refreshringnotifications",
        app_type: "react_web",
      };
      mobileApp(ring);
      get_order(view_type);
    }
  }

  async function update_prepare(get_data: any) {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        order_id: get_data.id_value,
      },
      post: {
        duration: counter,
        status: "Accept",
      },
    };
    console.log("pass_data :", pass_data);

    let data_res = await api("/outlet/update_order_status", pass_data);
    console.log("/outlet/update_delivery_package :", data_res);

    if (data_res.status_code == 200) {
      // SetPrepTimeModal({
      //   is_open: false,
      //   id: "",
      //   data:{},
      //   index:"",
      // });
      SetOrderData((prevalue1: any) => {
        prevalue1[get_data.index_value].open_prepare_data = false;
        return [...prevalue1];
      });
      SetPrepTimeModal((prevalue: any) => {
        prevalue.is_open = false;
        prevalue.id = "";
        prevalue.data = {};
        prevalue.max_prep_time = 0;
        prevalue.index = "";
        return { ...prevalue };
      });

      get_order(view_type);
    }
  }

  async function del_partner_number(id: any, del_id: any) {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        order_id: id,
        callto: del_id != "cus" ? "delivery_partner" : "customer",
        delivery_id: del_id != "cus" ? del_id : "",
      },
    };
    let data_res: any = await api("/outlet/call", pass_data);
    if ((data_res.status_code = 200)) {
      // if (data_res?.response?.phone_number) {
      //   window.open("tel:" + data_res?.response?.phone_number);
      // }
      if (data_res?.response?.phone_number) {
        if (context?.app_data.application?.platform == "android") {
          window.open("tel:" + data_res?.response?.phone_number);
        } else {
          window.location.href = "tel:" + data_res?.response?.phone_number;
        }
      }
    }
  }

  async function cancel_order() {
    console.log("cancel order");
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        order_id: id_value.order_id,
      },
      post: {
        status: "Cancel",
      },
    };

    let response: any = await api("/outlet/update_order_status", pass_data);

    if (response?.status_code == 200) {
      if (response.response.hasOwnProperty("orders")) {
        let orders = response.response.orders;

        // orders.map((ele: any) => {
        //   ele.open_prepare_data = false;
        // });
        SetResponseData(response?.response);
        console.log(response?.response);
        SetData(response.response.options);
        SetAck(response.response.orders[0].ask_cancelled_ack);
        // SetData(response?.response?.options);
        // SetOrderData(orders);
        SetOrderData((prevalue: any) => {
          const old_value = prevalue;
          console.log("old_value :", old_value);
          orders.map((ele: any, index: any) => {
            if (old_value.length > 0) {
              if (old_value[index].hasOwnProperty("open_prepare_data")) {
                if (old_value[index].id == ele.id) {
                  if (ele.order_status_id == 1) {
                    ele.open_prepare_data = old_value[index].open_prepare_data;
                  } else {
                    ele.open_prepare_data = false;
                  }
                }
              } else {
                console.log("no key");
                ele.open_prepare_data = false;
              }
            } else {
              console.log("length == 0");
              ele.open_prepare_data = false;
            }
          });
          prevalue = orders;
          return [...prevalue];
        });
      }
    }
  }

  async function get_order_details_for_cancel() {
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
        order_id: id_value.order_id,
      },
    };

    let response: any = await api("/outlet/order_details", pass_data);

    if (response?.status_code == 200) {
      if (response.response.hasOwnProperty("orders")) {
        let orders = response.response.orders;
        SetResponseData(response?.response);
        SetOrderData((prevalue: any) => {
          const old_value = prevalue;
          console.log("old_value :", old_value);
          orders.map((ele: any, index: any) => {
            if (old_value.length > 0) {
              if (old_value[index].hasOwnProperty("open_prepare_data")) {
                if (old_value[index].id == ele.id) {
                  if (ele.order_status_id == 1) {
                    ele.open_prepare_data = old_value[index].open_prepare_data;
                  } else {
                    ele.open_prepare_data = false;
                  }
                }
              } else {
                console.log("no key");
                ele.open_prepare_data = false;
              }
            } else {
              console.log("length == 0");
              ele.open_prepare_data = false;
            }
          });
          prevalue = orders;
          SetIsCancelLoading(false);
          SetCancelOrderModal((prevalue1: any) => {
            prevalue1.open = true;
            prevalue1.data = prevalue;
            return { ...prevalue1 };
          });
          return [...prevalue];
        });
      }
    }
  }

  async function chat_pickup(get_data: any) {
    let pass_data : any = {
      get: {
        order_id: get_data.order_id,
        chatto: get_data.chatto,
        brand: id_value.brand_id,
        outlet: id_value.outlet_id,
      },
    };
    if(get_data.chatto == "delivery_partner"){
      pass_data.get.delivery_id = get_data.delivery_id
    }
    let data: any = await api("/outlet/chat", pass_data);
    if (data.status_code == 200) {
      if (data?.response?.sb_user_id && data?.response?.channel_url) {
        SetChatModal({
          is_open: true,
          data: {
            orderId: get_data.order_id,
            sb_user_id: data?.response?.sb_user_id,
            channel_url: data?.response?.channel_url,
            title: data?.response?.user_name,
            profile_url: data?.response?.profile_url,
          },
        });
      }
    }
    // setCallBtnLoader(false);
  }

  return (
    <div className="order_details_page">
      <div
        className="head-bar px-0 "
        style={width < 780 ? { width: width } : {}}
      >
        <div className="d-flex align-items-center mb-1">
          <div
            className="px-3 cursor"
            onClick={() => {
              // history.go(-1);
              // history.back();
              console.log("go back")
              navigate("/outlet/" +
              id_value.brand_id +
              "/" +
              id_value.outlet_id +
              "/orders/all")
              // window.history.back();

              // navigate(-1);
            }}
          >
            <img
              src={
                AppConfig.CDN_Media_url + "3760dcf7e8daefc2196a2b9f357fbec1.svg"
              }
              className="w-14px"
            />
            {/* <Ionicons name="arrow-back-outline" size={18} color="black" /> */}
          </div>
          <h6 className=" mb-0">Order Details</h6>
        </div>
      </div>
      <div className="row">
        {is_loading ? (
          // {/* Skeleton start */}
          <div className="col-md-6 px-sm-0 px-md-2">
            <div className="mb-5 pb-5">
              <div className=" pb-5">
                <div className="mb-2">
                  <div className="px-2 py-3 card mt-2">
                    <div className=" border-bottom p-2 row align-items-center">
                      <div className="col-3 ps-0">
                        <Skeleton height={10} width={80} />
                      </div>
                      <div className="col-9 pe-0 text-end">
                        <Skeleton height={10} width={80} />
                      </div>
                    </div>

                    <div className="bg-fff p-2 pb-3">
                      <div className="pt-2">
                        <div className="d-flex py-1">
                          {/* <img
                              src={require("../../../assets/icon/pin2.png")}
                              className="bank-img"
                            /> */}
                          <Skeleton height={16} width={16} />

                          <div className="px-2">
                            <Skeleton height={10} width={160} />
                            <div className="pt-1">
                              <Skeleton height={8} width={100} />
                            </div>
                          </div>
                        </div>
                        <div className="d-flex  py-1">
                          {/* <img
                          src={require("../../../assets/icon/location-pin1.png")}
                          className="bank-img"
                        /> */}
                          <Skeleton height={14} width={14} />

                          <div className="px-2">
                            <Skeleton height={10} width={160} />

                            <div className="pt-1">
                              <Skeleton height={8} width={190} count={2} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="card px-2 py-3 mt-2 ">
                    <div className="d-flex align-items-center py-2">
                      <Skeleton height={40} width={40} />
                      <div className="px-1 w-100">
                        <div className="d-flex align-items-center pt-1">
                          <Skeleton height={10} width={140} />

                          <div className="d-flex align-items-center ms-auto">
                            <Skeleton height={10} width={10} />
                            <div className="ps-1">
                              <Skeleton height={10} width={80} />
                            </div>
                          </div>
                        </div>

                        <div className=" mt-1">
                          <Skeleton height={8} width={100} />
                        </div>
                      </div>
                    </div>

                    <div className="py-2 order_detail">
                      <Accordion>
                        <AccordionSummary
                          className="my-0 px-1 py-0"
                          aria-controls="panel1d-content"
                        >
                          <div className="row w-100">
                            <div className="col-7 ps-0">
                              <div className="d-flex cursor">
                                <Skeleton height={14} width={14} />

                                <div className="ps-1">
                                  <Skeleton height={10} width={140} />
                                </div>
                              </div>
                            </div>
                            <div className="col-2 py-0 px-1">
                              <Skeleton height={10} width={40} />
                            </div>
                            <div className="col-3 pe-0">
                              <Skeleton height={10} width={100} />
                            </div>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails className=" w-100">
                          <div className=" w-100">
                            <div className="row pb-2 px-0">
                              <div className="col-7 pe-1 ps-0">
                                <Skeleton height={10} width={140} />
                              </div>
                              <div className="col-2">
                                <Skeleton height={10} width={100} />
                              </div>
                              <div className="col-3 p-0">
                                <Skeleton height={10} width={140} />
                              </div>
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </div>
                </div>

                <div className="mb-2">
                  <div className="card p-3 mt-2">
                    <div className="mb-3">
                      <Skeleton height={10} width={80} />
                    </div>
                    <div className="pb-2 d-flex align-items-center">
                      <Skeleton height={10} width={120} />
                      <div className="ms-auto">
                        <Skeleton height={10} width={120} />
                      </div>
                    </div>
                    <div className="pb-2 d-flex align-items-center">
                      <Skeleton height={10} width={100} />

                      <div className="ms-auto">
                        <Skeleton height={10} width={50} />
                      </div>
                    </div>
                    <div className="pb-2 d-flex align-items-center">
                      <Skeleton height={10} width={100} />

                      <div className="ms-auto">
                        <Skeleton height={10} width={50} />
                      </div>
                    </div>
                    <div className="pb-2 d-flex align-items-center border-bottom-dot">
                      <Skeleton height={10} width={100} />

                      <div className="ms-auto">
                        <Skeleton height={10} width={50} />
                      </div>
                    </div>
                    <div className="py-2 d-flex align-items-center">
                      <Skeleton height={10} width={100} />

                      <div className="ms-auto">
                        <Skeleton height={10} width={50} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          // {/* Skeleton end */}
        ):  (
          order_data?.map((ele: any, i_item: any) => (
            <div className="col-md-6 px-sm-0 px-md-2">
              <div className="mb-5 pb-5">
                <div className=" pb-5">
                  <div className="mb-2">
                    <div className="px-2 py-3 card mt-2">
                      <div className=" border-bottom p-2 row align-items-center">
                        <div className="col-3 ps-0">
                          <p className="v-small text-black fw-bold pop-font">
                            #{ele.id}
                          </p>
                        </div>
                        <div className="col-9 pe-0 text-end">
                          <p className="extra-small text-gray pop-font">
                            {ele.time}
                          </p>
                        </div>
                      </div>

                      <div className="bg-fff p-2 pb-3">
                        <div className="pt-2">
                          <div className="d-flex py-1">
                            {/* <img
                              src={require("../../../assets/icon/pin2.png")}
                              className="bank-img"
                            /> */}
                            <img
                              src={
                                AppConfig.CDN_Media_url +
                                "0501db4e100fe9cabf9224567d4b835c.svg"
                              }
                              className="w-14px"
                            />
                            <div className="px-2">
                              <p className="text-dark fw-bold ">
                                {ele.brand_name}
                              </p>
                              <p className="text-gray pt-1 v-small">
                                {ele.outlet_name}
                              </p>
                            </div>
                          </div>
                          <div className="d-flex  py-1">
                            {/* <img
                          src={require("../../../assets/icon/location-pin1.png")}
                          className="bank-img"
                        /> */}
                       
                          
                              {/* <div className="d-flex"> */}
                              <div className="d-flex">
                              <img
                              src={
                                AppConfig.CDN_Media_url +
                                "ae924dcbac229efe90c8d1f14489c082.svg"
                              }
                              className="w-14px"
                            />

                                <div>
                                  <p className="text-dark fw-bold px-2">
                                    {ele.customer_name}
                                  </p>
                                  <p className="text-gray pt-1 v-small">
                                    {ele?.delivery_address?.address != ""
                                      ? (ele?.delivery_address?.door_no,
                                        ele?.delivery_address?.address,
                                        ele?.delivery_address?.city)
                                      : ele?.delivery_address.map_address}
                                  </p>
                                </div>
                                </div>


                                <div className="ms-auto">
                                  {ele.customer_mobile && (
                                    <>
                                      <div className="px-md-3 px-sm-0 py-2 cursor center ms-auto">
                                        <span
                                          onClick={() => {
                                            del_partner_number(ele.id, "cus");
                                          }}
                                        >
                                          <img
                                            src={
                                              AppConfig.CDN_Media_url +
                                              "2a0bde684c6872edfb1b17bc9c31a59f.svg"
                                            }
                                            // className="bank-img"
                                            className="delivery"
                                          />
                                        </span>
                                        <div
                                          className="m-2"
                                          onClick={() => {
                                            let send_data = {
                                              order_id: ele.id,
                                              chatto: "customer",
                                            };
                                            chat_pickup(send_data);
                                          }}
                                          style={{width: 22}}
                                        >
                                          <img
                                            src={
                                              AppConfig.CDN_Media_url +
                                              "0de0411aecb1f133a79605154a7e62b6.svg"
                                            }
                                            className="img-fluid"
                                          />
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              {/* </div> */}
                            {/* </div> */}
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-2">
                    <div className="card px-2 py-3 mt-2 ">
                      <div className="d-flex align-items-center py-2">
                        {ele.hasOwnProperty("brand_logo") ? (
                          <div className="center">
                            <img
                              src={ele.brand_logo}
                              // {AppHelper.imageSrc(
                              //   context.app_data.image_base_url,
                              //   ele.brand_logo,
                              //   {
                              //     format: "webp",
                              //     width: 320,
                              //   }
                              // )}
                              className="resta-img w-3"
                              class="rounded-float-start"
                              height="40"
                              width="40"
                            />
                          </div>
                        ) : (
                          // <img
                          //   src={require("../../../assets/icon/tray.png")}
                          //   className="resta-img"
                          // />
                          ""
                        )}
                        <div className="px-1 w-100">
                          <div className="d-flex align-items-center pt-1">
                            <p className="online text-dark fw-bold mb-0">
                              {ele.brand_name}
                            </p>

                            <div className="d-flex align-items-center ms-auto">
                              <div
                                className={
                                  ele.order_status == "Cancelled"
                                    ? "w-10px text-red"
                                    : ele.order_status == "Processing"
                                    ? "w-10px text-prime"
                                    : "w-10px text-green"
                                }
                              ></div>
                              <p
                                className={
                                  ele.order_status == "Cancelled"
                                    ? "text-red extra-small ps-1 fw-600"
                                    : ele.order_status == "Processing"
                                    ? "text-prime extra-small ps-1 fw-600"
                                    : "text-green extra-small ps-1 fw-600"
                                }
                              >
                                {ele.order_status}
                              </p>
                            </div>
                          </div>

                          <div className=" mt-1">
                            <div className="d-flex">
                            <p className="oneline text-gray v-small">
                              {ele.outlet_name}
                            </p>
{ele.dm_order &&
                            <span
                                        className={
                                      "badge-1 me-2 badge-blue-2 ms-auto"
                                            
                                        }
                                      >
                                        
                                        Demo
                                          
                                          
                                      </span>
}

                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="py-2 order_detail">
                        {ele.hasOwnProperty("ordered_products") &&
                          ele?.ordered_products?.map((p_ele: any) => (
                            <Accordion>
                              <AccordionSummary
                                className="my-0 px-1 py-0"
                                aria-controls="panel1d-content"
                              >
                                <div className="row w-100">
                                  <div className="col-7 ps-0">
                                    <div className="d-flex cursor">
                                      <MaterialIcons
                                        name="keyboard-arrow-right"
                                        size={14}
                                        color="black"
                                      />

                                      <p className="ps-1 v-small text-darkgray">
                                        {p_ele.variant_name}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="col-2 py-0 px-1">
                                    <p className="v-small text-gray">
                                      × {p_ele.quantity}
                                    </p>
                                  </div>
                                  <div className="col-3 pe-0">
                                    <p className="text-end text-black v-small fw-bold">
                                      <FontAwesome
                                        name="rupee"
                                        size={10}
                                        color="#000"
                                      />
                                      <span className="pop-font ps-1">
                                        {p_ele.amount}
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails className=" w-100">
                                <div className=" w-100">
                                  {p_ele.product_name == "" ? (
                                    <div className="row pb-2 px-0">
                                      <p className="col-7 text-gray  extra-small pe-1 ps-0">
                                        {p_ele.variant_name}{" "}
                                        {"  ( ₹" +
                                          p_ele.price.price_without_gst +
                                          ")"}{" "}
                                      </p>
                                      <p className="col-2 text-gray extra-small">
                                        × {p_ele.quantity}
                                      </p>
                                      <p className="text-end col-3  extra-small text-dark p-0">
                                        {
                                          p_ele.price
                                            .total_amount_with_qty_without_gst
                                        }
                                      </p>
                                    </div>
                                  ) : (
                                    <div className="row pb-2 px-0">
                                      <p className="col-7 text-gray extra-small pe-1 ps-0">
                                        {p_ele.product_name}{" "}
                                        {"  ( ₹" +
                                          p_ele.price.price_without_gst +
                                          ")"}{" "}
                                      </p>
                                      <p className="col-2 text-gray extra-small">
                                        × {p_ele.quantity}
                                      </p>
                                      <p className="col-3 text-end text-gray extra-small  p-0">
                                        ₹{" "}
                                        {
                                          p_ele.price
                                            .total_amount_with_qty_without_gst
                                        }
                                      </p>
                                    </div>
                                  )}
                                  <div className="row pb-2 px-0">
                                    <p className="col-7 text-gray extra-small ps-0">
                                      GST Amount {"%" + p_ele.price.gst_percent}{" "}
                                      / {"  ( ₹" + p_ele.price.gst_amount + ")"}{" "}
                                    </p>
                                    <p className="col-2 text-gray extra-small">
                                      × {p_ele.quantity}
                                    </p>
                                    <p className="col-3 text-end text-gray extra-small p-0">
                                      ₹ {p_ele.price.total_gst_amount_with_qty}
                                    </p>
                                  </div>

                                  {p_ele.addons.length > 0 ? (
                                    <div className="py-2  px-0">
                                      <p className="fw-bold text-black v-small mb-2 fs-7">
                                        Addons
                                      </p>
                                      {p_ele?.addons?.map(
                                        (a_ele: any, a_index: any) => (
                                          <div className="mb-2" key={a_index}>
                                            <div className="row pb-2  px-0">
                                              <p className="text-gray extra-small col-7 pe-1 ps-0 ">
                                                {a_ele.name}{" "}
                                                {"(₹" +
                                                  a_ele.price
                                                    .price_without_gst +
                                                  ")"}{" "}
                                              </p>
                                              <p className="text-gray col-2 extra-small">
                                                × {a_ele.quantity}
                                              </p>
                                              <p className="col-3 text-end text-gray p-0 extra-small">
                                                ₹{" "}
                                                {
                                                  a_ele.price
                                                    .total_amount_with_qty_without_gst
                                                }
                                              </p>
                                            </div>
                                            <div className="row mb-2">
                                              <p className="text-gray col-7 extra-small pe-1 ps-0">
                                                GST Amount{" "}
                                                {"%" + a_ele.price.gst_percent}{" "}
                                                /{" "}
                                                {"(₹" +
                                                  a_ele.price.gst_amount +
                                                  ")"}{" "}
                                              </p>
                                              <p className="text-gray col-2 extra-small">
                                                × {a_ele.quantity}
                                              </p>
                                              <p className="col-3 text-end text-gray p-0 extra-small">
                                                ₹{" "}
                                                {
                                                  a_ele.price
                                                    .total_gst_amount_with_qty
                                                }
                                              </p>
                                            </div>
                                          </div>
                                        )
                                      )}
                                      <div className="row py-2">
                                        <p className="text-darkgray small col-8 ps-0">
                                          Sub Total Addon Amount
                                        </p>
                                        <p className="col-4 text-end ms-auto text-darkgray small pe-0">
                                          ₹{" "}
                                          {
                                            p_ele.price
                                              .total_addon_amount_without_gst
                                          }
                                        </p>
                                      </div>
                                      <div className="row pb-2">
                                        <p className="text-darkgray small col-8 ps-0">
                                          Total Addon GST Amount
                                        </p>
                                        <p className="col-4 text-end ms-auto text-darkgray small pe-0">
                                          ₹ {p_ele.price.total_addon_gst_amount}
                                        </p>
                                      </div>
                                      <div className="row pb-2">
                                        <p className="text-darkgray small col-8 ps-0">
                                          Total Addon Amount
                                        </p>
                                        <p className="col-4 text-end ms-auto text-darkgray small pe-0">
                                          ₹{" "}
                                          {
                                            p_ele.price
                                              .total_addon_amount_with_gst
                                          }
                                        </p>
                                      </div>
                                    </div>
                                  ) : null}

                                  <div className="row pb-2 pt-3 border-top-dot">
                                    <p className="text-darkgray small col-8 ps-0">
                                      Sub Total Product Amount
                                    </p>
                                    <p className="col-4 text-end ms-auto text-darkgray small pe-0">
                                      ₹{" "}
                                      {
                                        p_ele.price
                                          .total_amount_with_qty_without_gst
                                      }
                                    </p>
                                  </div>

                                  <div className="row pb-2">
                                    <p className="text-darkgray small col-8 ps-0">
                                      Total Product GST Amount
                                    </p>
                                    <p className="col-4 text-end ms-auto text-darkgray small pe-0">
                                      ₹ {p_ele.price.total_gst_amount_with_qty}
                                    </p>
                                  </div>

                                  <div className="row pb-2">
                                    <p className="text-darkgray small col-8 ps-0">
                                      Total Product Amount
                                    </p>
                                    <p className="col-4 text-end ms-auto text-darkgray small pe-0">
                                      ₹{" "}
                                      {
                                        p_ele.price
                                          .total_amount_with_qty_with_gst
                                      }
                                    </p>
                                  </div>

                                  <div className="row align-items-center py-2">
                                    <p className="text-dark fw-bold small col-8 ps-0">
                                      Total Payable Amount
                                    </p>
                                    <p className="col-4 text-end fw-bold small text-dark pe-0">
                                      ₹ {p_ele.price.total_amount_payable}
                                    </p>
                                  </div>
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          ))}
                      </div>
                    </div>
                  </div>

                  <div className="mb-2">
                    {ele.hasOwnProperty("products") ? (
                      <div className="card p-3 mt-2">
                        <p className="fw-bold text-black mb-3 fs-7">
                          Order Summary
                        </p>
                        <div className="pb-2 d-flex align-items-center">
                          <p className="small text-gray">Subtotal</p>
                          <div className="ms-auto">
                            <p className="small text-gray">
                              <FontAwesome
                                name="rupee"
                                size={10}
                                color="#808080"
                              />
                              <span className="pop-font ps-1">
                                {ele.amount}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="pb-2 d-flex align-items-center">
                          <p className="small">Tax</p>
                          <div className="ms-auto">
                            <p className="small text-gray">
                              <FontAwesome
                                name="rupee"
                                size={10}
                                color="#808080"
                              />
                              <span className="pop-font ps-1">
                                {ele.gst_amount ? ele.gst_amount : 0}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="pb-2 d-flex align-items-center">
                          <p className="small">Delivery fee</p>
                          <div className="ms-auto">
                            <p className="small text-gray">
                              <FontAwesome
                                name="rupee"
                                size={10}
                                color="#808080"
                              />
                              <span className="pop-font ps-1">
                                {ele.delivery_charges}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="pb-2 d-flex align-items-center border-bottom-dot">
                          <p className="small">Convenience fee</p>
                          <div className="ms-auto">
                            <p className="small text-gray">
                              <FontAwesome
                                name="rupee"
                                size={10}
                                color="#808080"
                              />
                              <span className="pop-font ps-1">
                                {ele.convenience_fee == null
                                  ? "0"
                                  : ele.convenience_fee}
                              </span>
                            </p>
                          </div>
                        </div>
                        <div className="pt-2 d-flex align-items-center">
                          <p className="text-dark fw-bold">Grand Total</p>
                          <div className="ms-auto">
                            <p className="text-dark fw-bold">
                              <FontAwesome
                                name="rupee"
                                size={12}
                                color="#000"
                              />
                              <span className="pop-font ps-1">
                                {ele.total_amount}
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    ) : null}
                  </div>

                  {ele.order_status == "Accepted" &&
                  ele.products.length != 0 ? (
                    <div className="mb-2">
                      <div className="center pt-3">
                        <button
                          className="btn bg-prime text-white btn-round mb-2"
                          style={{ overflow: "hidden" }}
                          onClick={(event) => {
                            SetChooseItemModal({
                              is_open: true,
                              id: ele.id,
                              data: {
                                product: ele.products,
                                units: data.units,
                              },
                            });
                          }}
                        >
                          <span className="btn-inner d-flex align-items-center">
                            <span className="px-lg-4 px-4 fw-bold ">
                              Plan Shipment
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  <div className="mb-2">
                    {/* {ele.delivery.length > 0 ? ( */}
                    <div className="mt-2 card p-3">
                      <p className="fw-bold text-black mb-3 fs-7">
                        Shipment Details
                      </p>
                      {ele?.delivery?.map((d_ele: any, d_ele_index: any) => (
                        <>
                          <div className="pb-2 " key={d_ele_index}>
                            <div className="border-bottom-gray mb-2">
                              <div className="d-flex align-items-center pb-3 pt-2">
                                <p className="text-dark uppercase v-small fw-bold">
                                  {/* Shipment {d_ele_index + 1 + " (#"+d_ele.id+")"} */}
                                  Shipment {d_ele_index + 1 + " : "}
                                </p>
                                <p className="text-dark uppercase ps-1 fw-bold">
                                  {"#" + d_ele.id + ""}
                                </p>
                                {/* menu */}
                                {!d_ele.hyperlocal && (
                                  <div className="ms-auto">
                                    {" "}
                                    {/* <Feather name="edit-3" size={16} color="gray" /> */}
                                    <div className="dropdown">
                                      <button
                                        className="btn dropdown-toggle px-2 py-0"
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <Entypo
                                          name="dots-three-horizontal"
                                          size={18}
                                          color="#666"
                                        />
                                      </button>
                                      <ul
                                        className="dropdown-menu"
                                        aria-labelledby="dropdownMenuButton1"
                                      >
                                        <li>
                                          <a
                                            className="dropdown-item py-2 cursor"
                                            href="#"
                                            onClick={(event) => {
                                              console.log("edit_outlet");
                                              //   SetEditItemModal({
                                              //     is_open: true,
                                              //     id: ele_pro.id,
                                              //     data: {
                                              //       product: ele_pro.products,
                                              //       delivery: ele.delivery,
                                              //       main_product: ele.products,
                                              //     },
                                              //   });

                                              // accept(ele.id);
                                            }}
                                          >
                                            {" "}
                                            <Feather
                                              name="edit-3"
                                              size={16}
                                              color="gray"
                                            />{" "}
                                            Edit
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item py-2 cursor"
                                            href={
                                              "https://sellers.1superapp.com/app_dev/outlet/download_shipment_invoice?brand=" +
                                              id_value.brand_id +
                                              "&outlet=" +
                                              id_value.outlet_id +
                                              "&delivery_id=" +
                                              d_ele.id
                                            }
                                            rel="noopener noreferrer"
                                            target="_blank"
                                          >
                                            <Feather
                                              name="download"
                                              size={16}
                                              color="gray"
                                            />{" "}
                                            Download shipment invoice
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item py-2 cursor"
                                            // href={
                                            //   "https://sellers.1superapp.com/app_dev/outlet/download_shipment_invoice?brand=" +
                                            //   id_value.brand_id +
                                            //   "&outlet=" +
                                            //   id_value.outlet_id +
                                            //   "&delivery_id=" +
                                            //   ele_pro.id
                                            // }
                                            rel="noopener noreferrer"
                                            target="_blank"
                                          >
                                            <Feather
                                              name="download"
                                              size={16}
                                              color="gray"
                                            />{" "}
                                            Download shipment label
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item py-2 cursor"
                                            // href={
                                            //   "https://sellers.1superapp.com/app_dev/outlet/download_shipment_invoice?brand=" +
                                            //   id_value.brand_id +
                                            //   "&outlet=" +
                                            //   id_value.outlet_id +
                                            //   "&delivery_id=" +
                                            //   ele_pro.id
                                            // }
                                            rel="noopener noreferrer"
                                            target="_blank"
                                          >
                                            <Feather
                                              name="download"
                                              size={16}
                                              color="gray"
                                            />{" "}
                                            Download shipment manifest
                                          </a>
                                        </li>
                                        <li>
                                          <a
                                            className="dropdown-item py-2 cursor"
                                            href="#"
                                            onClick={(event) => {
                                              //   SetDeleteShipModal({
                                              //     is_open: true,
                                              //     data: {
                                              //       id: ele_pro.id,
                                              //     },
                                              //   });
                                            }}
                                          >
                                            <Feather
                                              name="trash"
                                              size={16}
                                              color="gray"

                                              // accept(ele.id);
                                            />{" "}
                                            Delete
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                )}
                                {/* status */}
                                <div className="d-flex align-items-center ms-auto">
                                  <FontAwesome
                                    name="circle"
                                    size={11}
                                    color={
                                      d_ele.status == "Cancelled" ||
                                      d_ele.status == "Rejected"
                                        ? "red"
                                        : d_ele.status ==
                                            "Ready For Delivery" ||
                                          d_ele.status == "Delivered"
                                        ? "green"
                                        : d_ele.status == "Preparing"
                                        ? "#3f41c3"
                                        : "orange"
                                    }
                                  />
                                  <p
                                    className={
                                      d_ele.status == "Cancelled" ||
                                      d_ele.status == "Rejected"
                                        ? "ps-1 v-small text-red"
                                        : d_ele.status ==
                                            "Ready For Delivery" ||
                                          d_ele.status == "Delivered"
                                        ? "ps-1 v-small text-green"
                                        : d_ele.status == "Preparing"
                                        ? "ps-1 v-small text-prime"
                                        : "ps-1 v-small text-orange"
                                    }
                                  >
                                    {d_ele.status}
                                  </p>
                                </div>
                              </div>
                              {/* <div className="d-flex align-items-center pb-3 ">
                                  <p className="text-dark uppercase v-small fw-bold">
                                    Shipment Id : {d_ele.id}
                                  </p>
                                </div> */}
                              <div className=" mb-2">
                                {d_ele?.products?.map((pro_ele, pro_index) => (
                                  <div className="" key={pro_index}>
                                    <div className="row align-items-center pb-1">
                                      <div className="col-2 col-md-1 ps-0">
                                        {pro_ele.product_images?.length > 0 &&
                                        pro_ele.hasOwnProperty("key_name") ? (
                                          <img
                                            src={AppHelper.imageSrc(
                                              context.app_data.image_base_url,
                                              pro_ele.product_images[0].url,
                                              {
                                                format: "webp",
                                              }
                                            )}
                                            className="shipment-img"
                                          />
                                        ) : (
                                          <img
                                            src={require("../../../assets/icon/no-img.jpg")}
                                            className="shipment-img"
                                          />
                                        )}
                                      </div>
                                      <div className="col-10 col-md-11 p-0">
                                        <div className="row align-items-center p-0">
                                          <div className="col-6 col-md-7 p-0">
                                            <p className="text-darkgray oneline v-small">
                                              {pro_ele.variant_name}
                                            </p>
                                          </div>
                                          <div className="col-2 col-md-2">
                                            <p className="v-small text-gray">
                                              × {pro_ele.quantity}
                                            </p>
                                          </div>
                                          <div className="col-4 col-md-3 ps-0 pe-1 text-end ms-auto">
                                            <p className="text-dark fw-bold v-small">
                                              ₹ {pro_ele.amount}
                                            </p>
                                          </div>
                                        </div>
                                        {pro_ele.addons.length > 0 ? (
                                          // addon
                                          <div className="mb-2 order_detail pt-1">
                                            <p className="extra-small text-black">
                                              {pro_ele?.addons?.map(
                                                (a_ele, add_index) =>
                                                  a_ele.name +
                                                  " (" +
                                                  a_ele.quantity +
                                                  "), "
                                              )}
                                            </p>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                {ele.order_status_id == 2 ||
                                (ele.order_status_id == 5 &&
                                  ele.delivery[0].status_id == "1") ? (
                                  <div className="text-sm-center text-md-left w-100">
                                    <button
                                      className="btn border-prime text-prime radius btn-shadow fw-bold px-4 w-md-25 cursor my-2 small w-sm-50"
                                      style={{ overflow: "hidden" }}
                                      onClick={(event) => {
                                        {
                                          d_ele.package_details
                                            .package_breadth == null
                                            ? SetCreatePackageModal({
                                                is_open: true,
                                                id: d_ele.id,
                                                data: {
                                                  units: data.units,
                                                  id: d_ele.id,
                                                },
                                              })
                                            : submit_ready_ship(d_ele.id);
                                        }
                                      }}
                                    >
                                      <span className="small">Preparing</span>
                                    </button>
                                  </div>
                                ) : (
                                  ele.delivery[0].status_id == "3" && (
                                    <div className="text-sm-center text-md-left w-100">
                                      <button
                                        className="btn border-prime text-prime radius btn-shadow fw-bold px-4 w-md-25 cursor my-2  w-sm-50 "
                                        style={{ overflow: "hidden" }}
                                        onClick={(event) => {
                                          {
                                            d_ele.package_details
                                              .package_breadth == null
                                              ? SetCreatePackageModal({
                                                  is_open: true,
                                                  id: d_ele.id,
                                                  data: {
                                                    units: data.units,
                                                    id: d_ele.id,
                                                  },
                                                })
                                              : submit_ready_ship(d_ele.id);
                                          }
                                        }}
                                      >
                                        <span className="small">Ready</span>
                                      </button>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>

                            {Object.keys(d_ele.hyperlocal_pickup).length > 0 ? (
                              <div className="py-2">
                                <p className="uppercase v-small text-dark fw-bold pb-3">
                                  Delivery Person Details
                                </p>
                                <div className="d-flex align-items-center  border-top-dash pe-2 pb-2">
                                  <div className="p-0">
                                    {d_ele.hyperlocal_pickup
                                      .delivery_partner_dp != null &&
                                    d_ele.hyperlocal_pickup
                                      .delivery_partner_dp != "" ? (
                                      <img
                                        alt="16.a1d4f3f3"
                                        className="img-radius"
                                        height="34"
                                        width="34"
                                        src={
                                          d_ele.hyperlocal_pickup
                                            .delivery_partner_dp
                                        }
                                        // className="delivery-img"
                                      />
                                    ) : (
                                      //   <img
                                      //     src={require("../../../assets/img/prof.jpg")}
                                      //     className="delivery-img"
                                      //   />
                                      <i className="fa fa-user m-2"></i>
                                    )}
                                  </div>
                                  <div className="px-2 d-flex align-items-center w-100">
                                    {d_ele.hyperlocal_pickup
                                      .delivery_partner_name != null &&
                                    d_ele.hyperlocal_pickup
                                      .delivery_partner_name != "" ? (
                                      <p className="text-darkgray">
                                        <b className="text-dark fs-7">
                                          {
                                            d_ele.hyperlocal_pickup
                                              .delivery_partner_name
                                          }
                                        </b>
                                        {d_ele.hyperlocal_pickup.status}
                                      </p>
                                    ) : (
                                      <p className="text-darkgray">
                                        No delivery partner assigned
                                      </p>
                                    )}

                                    {d_ele.hyperlocal_pickup
                                      .delivery_partner_mobile && (
                                      <>
                                        <div className="px-md-3 px-sm-0 py-2 cursor center ms-auto">
                                          <span
                                            onClick={() => {
                                              del_partner_number(
                                                ele.id,
                                                d_ele.hyperlocal_pickup
                                                  .delivery_partner_mobile_delivery_id
                                              );
                                            }}
                                          >
                                            <img
                                              src={
                                                AppConfig.CDN_Media_url +
                                                "2a0bde684c6872edfb1b17bc9c31a59f.svg"
                                              }
                                              // className="bank-img"
                                              className="delivery"
                                            />
                                          </span>
                                        </div>
                                        <div
                                          className="m-2"
                                          onClick={() => {
                                            let send_data = {
                                              order_id: ele.id,
                                              chatto: "delivery_partner",
                                              delivery_id : d_ele.hyperlocal_pickup
                                              .delivery_partner_mobile_delivery_id
                                            };
                                            chat_pickup(send_data);
                                          }}
                                          style={{width: 33}}
                                        >
                                          <img
                                            src={
                                              AppConfig.CDN_Media_url +
                                              "0de0411aecb1f133a79605154a7e62b6.svg"
                                            }
                                            className="img-fluid"
                                          />
                                        </div>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>
                            ) : null}

                            <div className="py-2">
                              <div className="row align-items-center bg-lightprime p-2 radius">
                                <div className="col-8 p-0 d-flex">
                                  {/* <MaterialIcons
                                      name="delivery-dining"
                                      size={19}
                                      color="#666"
                                    /> */}
                                  <img
                                    src={require("../../../assets/icon/scooter.png")}
                                    className="img-14px mt-1"
                                  />
                                  <div className="ps-2">
                                    <p className=" extra-small">
                                      Delivery Type
                                    </p>
                                    <p className="v-small pt-1 text-dark fw-bold">
                                      {d_ele.hyperlocal == true
                                        ? "Hyper Local"
                                        : null}
                                    </p>
                                  </div>
                                </div>

                                <div className="col-4 end p-0 ">
                                  {d_ele.live_tracking == true && (
                                    <div className="d-flex w-100">
                                      {/* <div
                                        className="text-prime mx-auto w-50 cursor"
                                        onClick={() => {
                                          SetOpenTimeline((prevalue: any) => {
                                            let index_value;
                                            index_value = prevalue.findIndex(
                                              (ele: any) => ele == d_ele.id
                                            );
                                            if (index_value != -1) {
                                              prevalue.splice(index_value, 1);
                                            } else {
                                              prevalue.push(d_ele.id);
                                            }
                                            return [...prevalue];
                                          });
                                        }}
                                      >
                                        {/* <MaterialIcons
                                            name="timeline"
                                            size={20}
                                            color={check_timeline_open(d_ele) ? "#3f41c3" : "#555"}
                                          /> 
                                        <div className="text-center w-100">
                                          <img
                                            src={require("../../../assets/icon/timeline.png")}
                                            className="delivery mx-auto "
                                          />
                                          <p className="extras-small pt-1 text-darkgray text-center">
                                            Timeline
                                          </p>
                                        </div>
                                      </div> */}
                                      <div
                                        className="center border-left w-50 cursor"
                                        onClick={() => {
                                          // SetMapDriverModal({
                                          //   is_open: true,
                                          //   lat: ele.delivery[0]?.hyperlocal_pickup
                                          //     ?.pickup_lat,
                                          //   lon: ele.delivery[0]?.hyperlocal_pickup
                                          //     ?.pickup_lng,
                                          //   id: ele.delivery[0].id,
                                          // });
                                          SetOrderTrackingModal(
                                            (prevalue: any) => {
                                              prevalue.is_open = true;
                                              prevalue.delivery_index =
                                                d_ele_index;
                                              prevalue.delivery_id = d_ele.id;
                                              prevalue.brand_id =
                                              ele.brand_id;
                                              prevalue.outlet_id =
                                              ele.outlet_id;
                                              return { ...prevalue };
                                            }
                                          );
                                        }}
                                      >
                                        {/* <Entypo
                                            name="location-pin"
                                            size={14}
                                            color="#666"
                                          /> */}
                                        <div>
                                          <img
                                            src={require("../../../assets/icon/pin.png")}
                                            className="delivery"
                                          />

                                          <p className="extras-small pt-1 text-darkgray">
                                            Track
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>

                                {/* {ele.delivery[0].live_tracking ==
                                    true && (
                                    <div className="cursor px-3">
                                      <img
                                        onClick={() => {
                                          SetMapDriverModal({
                                            is_open: true,
                                            lat: ele.delivery[0]
                                              ?.hyperlocal_pickup
                                              ?.pickup_lat,
                                            lon: ele.delivery[0]
                                              ?.hyperlocal_pickup
                                              ?.pickup_lng,
                                            id: ele.delivery[0].id,
                                          });
                                        }}
                                        src={
                                          // "https://img.icons8.com/ios/14/map-marker.png"
                                          require("../../../assets/icon/map.png")
                                        }
                                        className="delivery"
                                      />
                                    </div>
                                  )} */}
                              </div>

                              {check_timeline_open(d_ele) && (
                                <div className="mt-3">
                                  <p className="text-dark fw-bold py-2">
                                    Timeline Details
                                  </p>
                                  <div className="bg-fff pb-3 radius ">
                                    <ul className="delivery_status_vertical w-100">
                                      {ele?.timeline?.map((t_ele: any) => (
                                        <li>
                                          <time className="">
                                            {t_ele.time_formatted}
                                          </time>
                                          <div className="timeline ">
                                            <div className="d-flex align-items-center">
                                              {t_ele.type ==
                                              "delivery_partner" ? (
                                                <img
                                                  src={require("../../../assets/icon/delivery-boy.png")}
                                                  className="w-10 mx-1"
                                                  height="16"
                                                  width="16"
                                                />
                                              ) : null}
                                              {t_ele.type == "shipment" ? (
                                                <img
                                                  src={require("../../../assets/icon/cutlery.png")}
                                                  className="w-10 mx-1"
                                                  height="16"
                                                  width="16"
                                                />
                                              ) : null}
                                              <p className="text-gray small ps-1">
                                                {t_ele.status}
                                              </p>
                                            </div>
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>

                          {ele.sector_id != 2 && (
                            <>
                              {d_ele.package_details.package_breadth == null ? (
                                <div className="py-3 border-top">
                                  <div className="d-flex align-items-center">
                                    <p className="mb-3 text-dark v-small uppercase fw-bold">
                                      Package Details
                                    </p>

                                    <div
                                      className="d-flex align-items-center ms-auto cursor"
                                      onClick={(event) => {
                                        SetEditPackageModal({
                                          is_open: true,
                                          id: d_ele.id,
                                          data: {
                                            // product: ele.products,
                                            units: data.units,
                                            id: d_ele.id,
                                            package_details:
                                              d_ele.package_details,
                                          },
                                        });
                                      }}
                                    >
                                      <img
                                        src={require("../../../assets/icon/edit.png")}
                                        className="img-14px"
                                      />

                                      {/* <Feather name="edit" size={12} color="#3f41c3" />*/}
                                      <p className="ms-1 text-dark extra-small">
                                        Edit
                                      </p>
                                    </div>
                                  </div>
                                  <p className="mt-2 v-small text-center">
                                    No Package Data
                                  </p>
                                </div>
                              ) : (
                                <div className="py-3">
                                  <div className="d-flex align-items-center">
                                    <p className="mb-3 text-dark v-small uppercase fw-bold">
                                      Package Details
                                    </p>

                                    <div
                                      className="d-flex align-items-center ms-auto cursor"
                                      onClick={(event) => {
                                        SetEditPackageModal({
                                          is_open: true,
                                          id: d_ele.id,
                                          data: {
                                            // product: ele.products,
                                            units: data.units,
                                            id: d_ele.id,
                                            package_details:
                                              d_ele.package_details,
                                          },
                                        });
                                      }}
                                    >
                                      <img
                                        src={require("../../../assets/icon/edit.png")}
                                        className="img-14px"
                                      />
                                      <p className="ms-1 text-dark extra-small">
                                        Edit
                                      </p>
                                    </div>
                                  </div>
                                  <div className="d-flex align-items-center mb-2">
                                    <p className="text-black small">L x B : </p>
                                    <p className="text-darkgray ps-1 small">
                                      {d_ele.package_details.package_length +
                                        " " +
                                        d_ele.package_details
                                          .package_length_unit_name}{" "}
                                      x{" "}
                                      {d_ele.package_details.package_breadth +
                                        " " +
                                        d_ele.package_details
                                          .package_breadth_unit_name}
                                    </p>
                                  </div>

                                  <div className="d-flex align-items-center">
                                    <p className="text-black small">H x W : </p>
                                    <p className="text-darkgray ps-1 small">
                                      {d_ele.package_details.package_height +
                                        " " +
                                        d_ele.package_details
                                          .package_height_unit_name}{" "}
                                      x{" "}
                                      {d_ele.package_details.package_weight +
                                        " " +
                                        d_ele.package_details
                                          .package_weight_unit_name}
                                    </p>
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </>
                      ))}
                    </div>
                  </div>

                  {/* ) : null} */}

                  <div className="mb-2">
                    <div className="mt-2 card p-3">
                      <div className="py-1 ">
                        <div className="pb-sm-3">
                          <p className="fw-bold text-black mb-3 fs-7">
                            Shipping Address
                          </p>
                          {ele.hasOwnProperty("delivery_address") ? (
                            <div className="mb-2">
                              <p className="text-dark fw-bold mb-2 ">
                                {ele.delivery_address.name}
                              </p>
                              {ele.delivery_address.map_address != "" ? (
                                <p className="v-small text-gray">
                                  {ele.delivery_address.map_address}
                                </p>
                              ) : (
                                <p className="v-small text-gray">
                                  {ele.delivery_address.door_no},{" "}
                                  {ele.delivery_address.address},{" "}
                                  {ele.delivery_address.city},{" "}
                                  {ele.delivery_address.state},{" "}
                                  {ele.delivery_address.country} -{" "}
                                  {ele.delivery_address.pincode}
                                </p>
                              )}
                            </div>
                          ) : null}
                        </div>

                        <div className="">
                          <p className="my-3 text-dark v-small uppercase fw-bold">
                            Delivery Details
                          </p>

                          <div className="d-flex pb-2">
                            {/* <MaterialIcons
                            name="delivery-dining"
                            size={19}
                            color="#666"
                          /> */}
                            <img
                              src={require("../../../assets/icon/scooter.png")}
                              className="img-14px mt-1"
                            />
                            <div className="ps-2">
                              <p className=" extra-small">Delivery Type</p>
                              <p className="v-small pt-1 text-dark fw-bold">
                                {ele.delivery_type}
                              </p>
                            </div>
                          </div>

                          {/* <div className="col-7 text-right px-0">
                        <p className="extra-small">Delivery type</p>
                        <div className="d-flex mt-1">
                          <MaterialIcons
                            name="delivery-dining"
                            size={16}
                            color="#777"
                          />
                          <p className="text-dark ps-1 fs-7">
                            {ele.delivery_type}
                          </p>
                        </div>
                      </div> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-2">
                    <div className="mt-2 card p-3">
                      <div className="py-1">
                        <div className="row px-0">
                          {/* <div className={ele.order_status_id == 1 ? "col-md-6 border-right-md border-bottom-sm pb-sm-2" : "col-md-6 border-right-md pb-sm-2"}> */}
                          <p className="fw-bold text-black mb-3 fs-7 ps-0">
                            Payment Method
                          </p>
                          <div className="d-flex align-items-center p-0">
                            <p className="text-darkgray ps-0">
                              {ele.payment_type}
                            </p>
                            <div className="ms-auto">
                              <p className="fw-bold text-dark pe-0">
                                ₹ {ele.amount}
                              </p>
                            </div>
                          </div>
                          {/* <p className="mt-2">{ele.settlement_not_eligible && "Not eligible to payment"}</p> */}

                          {ele?.cod_order == true &&
                          ele?.cod_payment_status == true ? (
                            <span className="badge-2 w-25 w-auto">Paid</span>
                          ) : (
                            ele?.cod_order == true &&
                            ele.order_status_id != 3 &&
                            ele.order_status_id != 4 && (
                              <span className="badge-2 w-auto">
                                Yet To Be Paid
                              </span>
                            )
                          )}
                          {/* </div> */}

                          <div className="text-end px-0 pt-4">
                            {/* {ele.order_status_id == 1 && (
                              <p className="v-small uppercase fw-bold text-dark mb-3">
                                Order Actions
                              </p>
                            )} */}

                            {/* {ack && (
                              <button
                                className="btn text-prime bg-white px-4 py-1 fw-bold btn-shadow btn-radius btn-sm mb-3"
                                onClick={() => {
                                  submitAcknowledge();
                                }}
                              >
                                Acknowledge
                              </button>
                            )} */}

                            {ele.cancel_eligible && (
                              <button
                                className="btn text-darkgray bg-lightgray px-4 py-2 fw-bold btn-shadow btn-radius btn-sm mb-3"
                                onClick={() => {
                                  // submitAcknowledge();
                                  SetIsCancelLoading((prevalue: any) => {
                                    if (!prevalue) {
                                      prevalue = true;
                                      get_order_details_for_cancel();
                                    } else {
                                    }
                                    return prevalue;
                                  });
                                }}
                              >
                                {is_cancel_loading ? <Spinner /> : "Cancel"}
                              </button>
                            )}

                            {/* Accpet & Reject button */}
                            {/* {!ele.open_prepare_data ? (
                              <div className="">
                                {ele.order_status_id == 1 && (
                                  <div className="row align-items-center">
                                    <div className="col-6 text-start ps-0">
                                      <button
                                        className="btn px-3 py-1 text-darkgray bg-fff btn-shadow  btn-radius fw-bold btn-sm"
                                        onClick={(event) => {
                                          SetRejectModal({
                                            is_open: true,
                                            data: {
                                              id: ele.id,
                                            },
                                          });
                                        }}
                                      >
                                        REJECT
                                      </button>
                                    </div>
                                    <div className="col-6 text-end pe-0">
                                      <button
                                        className="btn text-prime bg-white px-4 py-1 fw-bold btn-shadow btn-radius btn-sm"
                                        style={{ overflow: "hidden" }}
                                        onClick={(event) => {
                                          console.log("accept");

                                          ele.sector_id == 2 &&
                                          ele.delivery_type_id == 3
                                            ? SetOrderData((prevalue: any) => {
                                                prevalue[
                                                  i_item
                                                ].open_prepare_data = true;
                                                return [...prevalue];
                                                // prevalue.map((ele:any,index:any)=>{

                                                // })
                                              })
                                            : accept(ele.id);

                                          // SetPrepTimeModal({
                                          //   is_open: true,
                                          //   // id: ele_pro.id,
                                          //   brand: id_value.brand_id,
                                          //   approx_time:
                                          //     ele.approx_preparation_duration,
                                          //   outlet: id_value.outlet_id,
                                          //   order_id: ele.id,
                                          // });
                                        }}
                                      >
                                        ACCEPT
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            ) : null} */}

                            {/* {ele.open_prepare_data ? (
                              <div className="my-2 card p-3">
                                <p
                                  className="text-black mb-2 fs-7 small text-center"
                                  onClick={() => {
                                    console.log("ele :", ele);
                                  }}
                                >
                                  How Long will this take a time to prepare
                                </p>
                                <div className="center py-2">
                                  <div className="w-75">
                                    <div className="d-flex justify-content-between align-items-center ">
                                      <div
                                        className="mb-0 vlr cursor"
                                        onClick={() => {
                                          handleClick2();
                                        }}
                                      >
                                        <AntDesign
                                          name="minuscircle"
                                          size={28}
                                          color="#3f41c3"
                                        />
                                      </div>

                                      <p className="mx-2 mb-0  fw-bold text-dark">
                                        {counter} MINS
                                      </p>

                                      <div
                                        className="mb-0 vlr cursor"
                                        onClick={() => {
                                          handleClick1();
                                        }}
                                      >
                                        <AntDesign
                                          name="pluscircle"
                                          size={28}
                                          color="#3f41c3"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="mt-3 d-flex align-items-center">
                                  <button
                                    className="btn px-3 py-1 text-darkgray bg-fff btn-shadow  btn-radius fw-bold"
                                    onClick={() => {
                                      SetPrepTimeModal({
                                        is_open: false,
                                        id: "",
                                      });
                                      SetOrderData((prevalue: any) => {
                                        prevalue[i_item].open_prepare_data =
                                          false;
                                        return [...prevalue];
                                      });
                                    }}
                                  >
                                    Cancel
                                  </button>
                                  <div className="ms-auto">
                                    <div
                                      className="btn text-prime bg-white px-4 py-1 fw-bold btn-shadow btn-radius"
                                      onClick={() => {
                                        update_prepare(ele.id);
                                      }}
                                    >
                                      Ok
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : null} */}

                            {/* {ele.order_status != "Cancelled" ? (
                            order_data.order_cancel_eligible ? (
                              <div
                                className="d-flex align-items-center mb-3 pb-1 cursor"
                                onClick={() => {
                                  // cancel_order();
                                  console.log("cancel order");
                                  SetCancelModal((prevValue: any) => {
                                    prevValue.is_open = true;
                                    return { ...prevValue };
                                  });
                                }}
                              >
                                <MaterialCommunityIcons
                                  name="cancel"
                                  size={15}
                                  color="#777"
                                />
                                <p className="ps-2 text-darkgray fw-bold">
                                  Cancel Order
                                </p>
                              </div>
                            ) : null
                          ) : null} */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="mb-2">
                    <div className="mt-2 card p-3">
                      <div className="py-1">
                        <div className="row px-0">
                          <div className="fw-bold text-black mb-3 fs-7 ps-0">
                            Settlement
                          </div>
                          <div className="timeline-1">
                            <div className="d-flex">
                              <div
                                className={
                                  ele?.settlement_eligible
                                    ? "timeline-dot active"
                                    : "timeline-dot"
                                }
                              >
                                {ele?.settlement_eligible && (
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "be453854bc89e35e154efa5e26814afa.svg"
                                    }
                                    className="img-fluid"
                                    width={10}
                                  />
                                )}
                              </div>
                              <div className="w-100 ps-2">
                                Settlement Eligible
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="timeline-line-container">
                                <div
                                  className={
                                    ele?.settlement_approved
                                      ? "timeline-line active"
                                      : "timeline-line"
                                  }
                                ></div>
                              </div>
                              <div className="w-100"></div>
                            </div>
                            <div className="d-flex">
                              <div
                                className={
                                  ele?.settlement_approved
                                    ? "timeline-dot active"
                                    : "timeline-dot"
                                }
                              >
                                {ele?.settlement_approved && (
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "be453854bc89e35e154efa5e26814afa.svg"
                                    }
                                    className="img-fluid"
                                    width={10}
                                  />
                                )}
                              </div>
                              <div className="w-100 ps-2">
                                Settlement Approved
                              </div>
                            </div>
                            <div className="d-flex">
                              <div className="timeline-line-container">
                                <div
                                  className={
                                    ele?.settlement_paid
                                      ? "timeline-line active"
                                      : "timeline-line"
                                  }
                                ></div>
                              </div>
                              <div className="w-100"></div>
                            </div>
                            <div className="d-flex">
                              <div
                                className={
                                  ele?.settlement_paid
                                    ? "timeline-dot active"
                                    : "timeline-dot"
                                }
                              >
                                {ele?.settlement_paid && (
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "be453854bc89e35e154efa5e26814afa.svg"
                                    }
                                    className="img-fluid"
                                    width={10}
                                  />
                                )}
                              </div>
                              <div className="w-100 ps-2">
                                Settlement Paid
                                {ele?.settlement_paid && (
                                  <div>
                                    <div>{ele?.settlement_paid_time}</div>
                                    <div className="fw-500 fs-15">
                                      &#8377; {ele?.settlement_paid_amount}
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="mb-2"> */}
                  <div className="mt-2 card p-3">
                    {/* <div className="py-1 ">
                        <div className="pb-sm-3"> */}
                    {/* <div className="border-top"> */}
                    <Accordion className="px-0" defaultExpanded={true}>
                      <AccordionSummary
                        expandIcon={
                          <img
                            src={
                              AppConfig.CDN_Media_url +
                              "78f45bd73c51ade6a32e1185735e5439.svg"
                            }
                            className="w-10px"
                          />
                        }
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        className="px-1"
                      >
                        <p className="fw-bold text-black mb-3 fs-7 ps-0">
                          {" "}
                          Timeline Details
                        </p>
                      </AccordionSummary>
                      <AccordionDetails className="px-1">
                        <div className="bg-fff pb-3 radius ">
                          <ul className="delivery_status_vertical w-100">
                            {ele?.timeline?.map((t_ele: any) => (
                              <li>
                                <time className="">{t_ele.time_formatted}</time>
                                <div className="timeline ">
                                  <div className="d-flex align-items-center">
                                    {t_ele.type == "delivery_partner" ? (
                                      <img
                                        src={require("../../../assets/icon/delivery-boy.png")}
                                        className="w-10 mx-1"
                                        height="16"
                                        width="16"
                                      />
                                    ) : null}
                                    {t_ele.type == "shipment" ? (
                                      <img
                                        src={require("../../../assets/icon/cutlery.png")}
                                        className="w-10 mx-1"
                                        height="16"
                                        width="16"
                                      />
                                    ) : null}
                                    <p className="text-gray small ps-1">
                                      {t_ele.status}
                                    </p>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* </div>
                    </div>
                    </div>
                    </div> */}

                  {/* Payment Mode / Payment Status*/}
                  {/* <div className="mt-2 card shadow px-2 py-3">
                      <div className="row pb-2">
                        <div className="col-6 p-0">
                          <p>Payment Mode</p>
                        </div>
                        <div className="col-6 p-0 text-end">
                          <p className="text-dark fw-bold">
                            {ele.payment_type}
                          </p>
                        </div>
                      </div>
                      <div className="row ">
                        <div className="col-6 p-0">
                          <p>Payment Status</p>
                        </div>
                        <div className="col-6 p-0 text-end">
                          {ele.cod_order ? (
                            <p className="text-dark fw-bold">
                              {ele.cod_payment_status
                                ? "Paid"
                                : "Not yet paid"}
                            </p>
                          ) : (
                            <p className="text-dark fw-bold">Paid</p>
                          )}
                        </div>
                      </div>
                    </div> */}

                  {/* Order Action */}
                  {/* <div className="my-2 card shadow px-2 py-3">
                      <h6 className="pb-2">Order Action</h6>

                      {ele.order_status_id != 6 &&
                      ele.order_status_id != 4 ? (
                        <div
                          className="d-flex align-items-center py-2 w-100 cursor"
                          onClick={() => {
                            SetCancelModal((prevValue: any) => {
                              prevValue.is_open = true;
                              return { ...prevValue };
                            });
                          }}
                        >
                          <MaterialCommunityIcons
                            name="cancel"
                            size={15}
                            color="#777"
                          />
                          <p className="ps-2 text-darkgray fw-bold">
                            Cancel Order
                          </p>
                        </div>
                      ) : null}



                  </div> */}
                </div>
              </div>

              {/* {Accpet & Reject button} */}
              {!ele.open_prepare_data ? (
                <div className="sm-bottom-custom-fixed md-bottom-fixed shadow-top">
                  {ele.order_status_id == 1 && (
                    <div className="row align-items-center">
                      {/* REJECT */}
                      <div className="col-6  p-0">
                        <div
                          className=" px-3 py-3 text-darkgray bg-fff small text-center fw-bold w-100 border-right"
                          onClick={(event) => {
                            SetRejectModal({
                              is_open: true,
                              data: {
                                id: ele.id,
                                brand_id : id_value.brand_id,
                                outlet_id : id_value.outlet_id,
                              },
                            });
                          }}
                        >
                          REJECT
                          {/* <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10.6654 5.33496L5.33203 10.6683" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                            </path>
                                                <path d="M10.6643 10.6663L5.33203 5.33301" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round">
                                                </path>
                                            </svg> */}
                        </div>
                      </div>
                      {/* ACCEPT */}
                      <div className="col-6 p-0">
                        <div
                          className=" text-prime bg-fff small px-4 py-3 fw-bold text-center w-100"
                          style={{ overflow: "hidden" }}
                          onClick={(event) => {
                            console.log("accept");

                            // ele.sector_id == 2 && ele.delivery_type_id == 3
                            //   ? SetOrderData((prevalue: any) => {
                            //       prevalue[i_item].open_prepare_data = true;
                            //       return [...prevalue];
                            //       // prevalue.map((ele:any,index:any)=>{

                            //       // })
                            //     })
                            //   : accept(ele.id);

                            if (
                              (ele.sector_id == 2 ||
                                ele.sector_id == 3 ||
                                ele.sector_id == 1) &&
                              ele.delivery_type_id == 3
                            ) {
                              SetOrderData((prevalue: any) => {
                                prevalue[i_item].open_prepare_data = true;
                                let sec_value =
                                  prevalue[i_item].approx_preparation_duration;
                                let minutes = Math.floor(sec_value / 60);
                                setCounter(minutes);
                                SetPrepTimeModal({
                                  is_open: true,
                                  // id: ele_pro.id,
                                  brand: id_value.brand_id,
                                  approx_time: ele.approx_preparation_duration,
                                  outlet: id_value.outlet_id,
                                  order_id: ele.id,
                                  data: ele,
                                  index: i_item,
                                  max_prep_time: ele.max_preparation_duration,
                                });
                                return [...prevalue];
                                // prevalue.map((ele:any,index:any)=>{

                                // })
                              });
                            } else {
                              accept(ele.id);
                            }
                          }}
                        >
                          ACCEPT
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ) : null}

              {/* Acknowledge */}
              {ack && (
                <div className="sm-bottom-custom-fixed md-bottom-fixed shadow-top">
                  <div className="row align-items-center">
                    <div className="col-12  p-0">
                      <div
                        className=" bg-fff px-4 py-3  text-center w-100"
                        style={{ overflow: "hidden" }}
                        onClick={(event) => {
                          // SetRejectModal({
                          //   is_open: true,
                          //   data: {
                          //     id: ele.id,
                          //   },
                          // });
                          submitAcknowledge();
                        }}
                      >
                        <p className="text-prime fw-bold fs-7">Acknowledge</p>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))
        ) }
      </div>

      <div className="px-3">
        <div className="lg-container">
          <div className="row">
            {/* <OrderComponent
			   data = {response_data}
			/> */}
            {/* <div class="col-sm-6 col-xs-12 track-det">
					<div class="card" style={{marginTop:"20px"}}>
						<div class="">
							<div class="clearfix  delievery_status d-flex">
								<div class="pull-left status_cont ms-3">
																<div class="status_check clearfix">
									<span className="mt-3" >Delivered On</span>
									<div class="edd_info">
										<span style={{display: "inline"}} class="edd_day " id="edd_day">Monday</span>
										<strong class="edd_month" id="edd_month">December</strong>
										<strong class="edd_date" id="edd_date">27<span class="year_txt">2021</span></strong>
									</div>
								</div>
								
								
						
									<span id="shipment_status_label">Status:</span>
									<strong id="shipment_status">
                  <i class="fas fa-check-circle mt-2 me-2" style={{color:"green"}}></i>
														
																				DELIVERED
									</strong>

																		
									
								</div>


				

																							</div>



						</div>
					</div>
					<div class="">
												<div class="courier_info clearfix d-flex">
	
							<div class="pull-left courier_logo" style={{backgroundImage: "url('https://sr-cdn-1.shiprocket.in/img/sr/common/email-templates/seller/delhivery-tracking.png')",backgroundRepeat: "no-repeat"}}>
								
							</div>
							<div class="pull-left">
								<span><b>Delhivery</b></span>
	
							</div>
							<div class="pull-right ms-auto">
								<span><b>Tracking ID</b> </span>
								<span class="tracking_id">1091188857722</span>
							</div>
							
						</div>
												<div class="delievery_info ">
																				
							<div class="delievery_list_wrap clearfix">
								<ul>
									
																								
									
																		<li class="active">
																			<span><b>Activity : </b>  <activity>Delivered - Delivered to consignee</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai_DaulatNgr_D (Maharashtra)</activity></span>
																		
																				
										<div class="date_info_wrap">
											<span class="date">27 Dec</span> 
											<span class="time">01:02 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>Dispatched - Call placed to consignee</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai_DaulatNgr_D (Maharashtra)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">27 Dec</span> 
											<span class="time">12:56 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>Dispatched - Out for delivery</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai_DaulatNgr_D (Maharashtra)</activity></span>
																	
																				
										<div class="date_info_wrap">
											<span class="date">27 Dec</span> 
											<span class="time">08:16 AM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>Pending - Reattempt as per Client's instruction</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai_DaulatNgr_D (Maharashtra)</activity></span>
																	
																				
										<div class="date_info_wrap">
											<span class="date">27 Dec</span> 
											<span class="time">12:19 AM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>Pending - Consignee Unavailable</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai_DaulatNgr_D (Maharashtra)</activity></span>
																			
																				
										<div class="date_info_wrap">
											<span class="date">26 Dec</span> 
											<span class="time">07:42 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>Dispatched - Call placed to consignee</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai_DaulatNgr_D (Maharashtra)</activity></span>
													
																				
										<div class="date_info_wrap">
											<span class="date">26 Dec</span> 
											<span class="time">07:41 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>Dispatched - Out for delivery</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai_DaulatNgr_D (Maharashtra)</activity></span>
																			
																				
										<div class="date_info_wrap">
											<span class="date">26 Dec</span> 
											<span class="time">04:26 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>Pending - Shipment Received at Facility</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai_DaulatNgr_D (Maharashtra)</activity></span>
																	
																				
										<div class="date_info_wrap">
											<span class="date">26 Dec</span> 
											<span class="time">02:26 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Trip received</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai_DaulatNgr_D (Maharashtra)</activity></span>
																
																				
										<div class="date_info_wrap">
											<span class="date">26 Dec</span> 
											<span class="time">02:12 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Vehicle Arrived</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai_DaulatNgr_D (Maharashtra)</activity></span>
															
																				
										<div class="date_info_wrap">
											<span class="date">26 Dec</span> 
											<span class="time">02:11 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Added to Trip</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai_MiraRdIndEstate_I (Maharashtra)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">26 Dec</span> 
											<span class="time">12:23 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Added to Bag</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai_MiraRdIndEstate_I (Maharashtra)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">26 Dec</span> 
											<span class="time">09:53 AM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Shipment Received at Facility</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai_MiraRdIndEstate_I (Maharashtra)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">26 Dec</span> 
											<span class="time">09:42 AM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Trip received</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai_MiraRdIndEstate_I (Maharashtra)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">26 Dec</span> 
											<span class="time">08:33 AM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Vehicle Arrived</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai_MiraRdIndEstate_I (Maharashtra)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">26 Dec</span> 
											<span class="time">08:26 AM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Added to Trip</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai Hub (Maharashtra)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">26 Dec</span> 
											<span class="time">06:55 AM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Trip received</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai Hub (Maharashtra)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">26 Dec</span> 
											<span class="time">03:32 AM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Vehicle Arrived</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai Hub (Maharashtra)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">26 Dec</span> 
											<span class="time">03:19 AM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Added to Trip</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai_DT (Maharashtra)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">26 Dec</span> 
											<span class="time">12:47 AM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Trip received</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai_DT (Maharashtra)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">26 Dec</span> 
											<span class="time">12:47 AM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Vehicle Arrived</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai_DT (Maharashtra)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">26 Dec</span> 
											<span class="time">12:40 AM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Air Offload - airline capacity constraint</activity></span>
																				<span><b>Location : </b>  <activity>Delhi_Airport_OT (Delhi)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">25 Dec</span> 
											<span class="time">10:41 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Added to Trip</activity></span>
																				<span><b>Location : </b>  <activity>Delhi_Airport_OT (Delhi)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">25 Dec</span> 
											<span class="time">02:26 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Trip received</activity></span>
																				<span><b>Location : </b>  <activity>Delhi_Airport_OT (Delhi)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">25 Dec</span> 
											<span class="time">02:26 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Vehicle Arrived</activity></span>
																				<span><b>Location : </b>  <activity>Delhi_Airport_OT (Delhi)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">25 Dec</span> 
											<span class="time">01:28 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Added to Trip</activity></span>
																				<span><b>Location : </b>  <activity>Delhi_Airport_GW (Delhi)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">25 Dec</span> 
											<span class="time">11:58 AM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Offloaded from vehicle</activity></span>
																				<span><b>Location : </b>  <activity>Delhi_Airport_GW (Delhi)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">25 Dec</span> 
											<span class="time">04:53 AM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Added to Trip</activity></span>
																				<span><b>Location : </b>  <activity>Delhi_Airport_GW (Delhi)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">25 Dec</span> 
											<span class="time">04:53 AM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Added to Bag</activity></span>
																				<span><b>Location : </b>  <activity>Delhi_Airport_GW (Delhi)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">25 Dec</span> 
											<span class="time">04:13 AM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - System weight captured</activity></span>
																				<span><b>Location : </b>  <activity>Delhi_Airport_GW (Delhi)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">25 Dec</span> 
											<span class="time">04:00 AM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Shipment Received at Facility</activity></span>
																				<span><b>Location : </b>  <activity>Delhi_Airport_GW (Delhi)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">24 Dec</span> 
											<span class="time">10:27 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Trip received</activity></span>
																				<span><b>Location : </b>  <activity>Delhi_Airport_GW (Delhi)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">24 Dec</span> 
											<span class="time">10:01 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Vehicle Arrived</activity></span>
																				<span><b>Location : </b>  <activity>Delhi_Airport_GW (Delhi)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">24 Dec</span> 
											<span class="time">07:59 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Vehicle delayed</activity></span>
																				<span><b>Location : </b>  <activity>Gurgaon_Tauru_GW (Haryana)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">24 Dec</span> 
											<span class="time">07:24 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Added to Trip</activity></span>
																				<span><b>Location : </b>  <activity>Gurgaon_Tauru_GW (Haryana)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">24 Dec</span> 
											<span class="time">04:48 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Trip received</activity></span>
																				<span><b>Location : </b>  <activity>Gurgaon_Tauru_GW (Haryana)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">24 Dec</span> 
											<span class="time">03:00 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Vehicle Arrived</activity></span>
																				<span><b>Location : </b>  <activity>Gurgaon_Tauru_GW (Haryana)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">24 Dec</span> 
											<span class="time">01:08 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Added to Trip</activity></span>
																				<span><b>Location : </b>  <activity>Faridabad_Sector27_I (Haryana)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">24 Dec</span> 
											<span class="time">02:59 AM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Added to Bag</activity></span>
																				<span><b>Location : </b>  <activity>Faridabad_Sector27_I (Haryana)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">23 Dec</span> 
											<span class="time">11:45 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Shipment Received at Facility</activity></span>
																				<span><b>Location : </b>  <activity>Faridabad_Sector27_I (Haryana)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">23 Dec</span> 
											<span class="time">11:38 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Trip received</activity></span>
																				<span><b>Location : </b>  <activity>Faridabad_Sector27_I (Haryana)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">23 Dec</span> 
											<span class="time">10:17 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Vehicle Arrived</activity></span>
																				<span><b>Location : </b>  <activity>Faridabad_Sector27_I (Haryana)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">23 Dec</span> 
											<span class="time">10:13 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Added to Trip</activity></span>
																				<span><b>Location : </b>  <activity>Palwal_NewColony_D (Haryana)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">23 Dec</span> 
											<span class="time">07:56 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Added to Bag</activity></span>
																				<span><b>Location : </b>  <activity>Palwal_NewColony_D (Haryana)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">23 Dec</span> 
											<span class="time">07:46 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Shipment Recieved at Origin Center</activity></span>
																				<span><b>Location : </b>  <activity>Palwal_NewColony_D (Haryana)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">23 Dec</span> 
											<span class="time">07:44 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>In Transit - Shipment picked up</activity></span>
																				<span><b>Location : </b>  <activity>Palwal_NewColony_D (Haryana)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">23 Dec</span> 
											<span class="time">02:23 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>Manifested - Pickup scheduled</activity></span>
																				<span><b>Location : </b>  <activity>Palwal_NewColony_D (Haryana)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">23 Dec</span> 
											<span class="time">02:19 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>Manifested - Consignment Manifested</activity></span>
																				<span><b>Location : </b>  <activity>Palwal_NewColony_D (Haryana)</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">23 Dec</span> 
											<span class="time">02:19 PM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																
									
																		<li>
																			<span><b>Activity : </b>  <activity>Order Received</activity></span>
																				<span><b>Location : </b>  <activity>Mumbai</activity></span>
																				
																				
																				
										<div class="date_info_wrap">
											<span class="date">19 Dec</span> 
											<span class="time">12:00 AM</span>
										</div>
										
										<i class="circle_icon"></i>

									</li>
																	
																</ul>
							</div>
																			
						</div>
					</div>
				</div> */}
          </div>
        </div>
      </div>

      <OrderChat
        open={chatModal.is_open}
        data={chatModal.data}
        close={(data: any) => {
          SetChatModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            // new_order();
          }
        }}
      />

      <ModalChooseItems
        open={chooseItemModal.is_open}
        data={chooseItemModal.data}
        id={chooseItemModal.id}
        // close={ModalOnClose}
        close={(data) => {
          console.log("close in", data);

          SetChooseItemModal((prevValue) => {
            prevValue.is_open = false;

            console.log("prevValue.is_open ", prevValue);
            return { ...prevValue };
          });
          if (data.action == "edit") {
            get_order(view_type);
          }
        }}
      />

      <ModalPackageDetails
        open={createPackageModal.is_open}
        data={createPackageModal.data}
        id={chooseItemModal.id}
        // close={ModalOnClose}
        close={(data) => {
          console.log("close in");

          SetCreatePackageModal((prevValue) => {
            prevValue.is_open = false;

            console.log("prevValue.is_open ", prevValue);
            return { ...prevValue };
          });

          if (data.action == "save") {
            get_order(view_type);
          }
        }}
      />

      <ModalCancelOrder
        open={cancel_order_modal.open}
        data={cancel_order_modal.data}
        close={(data: any) => {
          console.log("close in", data);

          SetCancelOrderModal((prevValue) => {
            prevValue.open = false;
            return { ...prevValue };
          });

          if (data.action == "save") {
            // get_order(view_type);
            cancel_order();
          }
        }}
      />

      {/* <ModalLocationLive
        open={mapdriverModal.is_open}
        lat={mapdriverModal.lat}
        lon={mapdriverModal.lon}
        id={mapdriverModal.id}
        // id={deleteModal.id}
        close={(data: any) => {
          // console.log("Franchise modl on close :", data);
          SetMapDriverModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            // get_outlets();
          }
        }}
      /> */}

      {order_data.length > 0 ? (
        <ModalOrderTracking
          open={order_tracking_modal.is_open}
          delivery_id={order_tracking_modal.delivery_id}
          delivery_index={order_tracking_modal.delivery_index}
          order_details={order_data[0]}
          brand_id={order_tracking_modal.brand_id}
          outlet_id = {order_tracking_modal.outlet_id}
          close={() => {
            SetOrderTrackingModal((prevalue: any) => {
              prevalue.is_open = false;
              prevalue.delivery_index = "";
              prevalue.delivery_id = "";
              return { ...prevalue };
            });
          }}
        />
      ) : null}

      <ModalRejected
        open={rejectModal.is_open}
        data={rejectModal.data}
        close={(data: any) => {
          SetRejectModal({
            is_open: false,
            data: {},
          });

          console.log(data);

          if (data.action == "delete") {
            get_order(view_type);
          }
        }}
      />

      <ModalEditPackageDetails
        open={editPackageModal.is_open}
        data={editPackageModal.data}
        id={editPackageModal.id}
        // close={ModalOnClose}
        close={(data) => {
          console.log("close in");

          SetEditPackageModal((prevValue) => {
            prevValue.is_open = false;

            console.log("prevValue.is_open ", prevValue);
            return { ...prevValue };
          });

          if (data.action == "edit") {
            get_order(view_type);
          }
        }}
      />

      <Dialog
        open={prepTimeModal.is_open}
        onClose={() => {
          // SetPaymentType({
          //   is_open: false,
          //   value: "",
          //   disabled: false,
          //   from: "",
          //   payment_index: "",
          // });
          SetPrepTimeModal({
            is_open: false,
            id: "",
            data: {},
            index: "",
            max_prep_time: 0,
          });
          SetOrderData((prevalue: any) => {
            prevalue[prepTimeModal.index].open_prepare_data = false;
            return [...prevalue];
          });
        }}
        classes={{ container: classes.root, paper: classes.paper }}
      >
        <div className="">
          {/* <div className="px-3 pt-4 pb-2 d-flex align-items-center w-100">
            <div
              className="ms-auto cursor"
              onClick={() => {
                SetPrepTimeModal({
                  is_open: false,
                  id: "",
                  data: {},
                  index: "",
                });
                SetOrderData((prevalue: any) => {
                  prevalue[prepTimeModal.index].open_prepare_data = false;
                  return [...prevalue];
                });
              }}
            >
              <Ionicons name="md-close" size={18} color="black" />
            </div>
          </div> */}

          <div className="">
            <div className="my-2 p-3">
              <div className="text-center">
                <img
                  src={
                    AppConfig.CDN_Media_url +
                    "b68d5cf34d1598c6ca0cdea2de3ed39d.svg"
                  }
                  className="img-fluid mb-3"
                  width={35}
                />
              </div>
              <p className="text-black mb-2 fs-7 small text-center">
                How Long will this take a time to prepare
              </p>
              <div className="center py-2">
                <div className="w-75">
                  <div className="d-flex justify-content-between align-items-center border br-20">
                    <div
                      className="mb-0 vlr cursor"
                      onClick={() => {
                        handleClick2();
                      }}
                    >
                      <AntDesign name="minuscircle" size={28} color="#3f41c3" />
                    </div>

                    <p className="mx-2 mb-0  fw-bold text-dark">
                      {counter} MINS
                    </p>

                    <div
                      className="mb-0 vlr cursor"
                      onClick={() => {
                        let pass_data: any = {
                          index: prepTimeModal.index,
                        };
                        handleClick1(pass_data);
                      }}
                    >
                      <AntDesign name="pluscircle" size={28} color="#3f41c3" />
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="mt-3 d-flex align-items-center">
                <button
                  className="btn px-3 py-1 text-darkgray bg-fff btn-shadow  btn-radius fw-bold"
                  onClick={() => {
                    SetPrepTimeModal({
                      is_open: false,
                      id: "",
                      data:{},
                      index:"",
                    });
                    SetOrderData((prevalue: any) => {
                      prevalue[i_item].open_prepare_data =
                        false;
                      return [...prevalue];
                    });
                  }}
                >
                  Cancel
                </button>
                <div className="ms-auto">
                  <div
                    className="btn text-prime bg-white px-4 py-1 fw-bold btn-shadow btn-radius"
                    onClick={() => {
                      update_prepare(ele.id);
                    }}
                  >
                    Ok
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>

        <button
          type="button"
          className="btn btn-primary br-0 text-uppercase cursor w-100 p-12"
          onClick={() => {
            console.log("Done");
            let send_data: any = {
              id_value: prepTimeModal.data.id,
              index_value: prepTimeModal.index,
            };
            update_prepare(send_data);
          }}
        >
          Done
        </button>
        {/* </div> */}
      </Dialog>
    </div>
  );
}
