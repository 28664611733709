import React, { useState, useEffect } from "react";
import bootstrap from "../../../assets/libraries/bootstrap/js/bootstrap";
import Select from "react-select";
import AsyncSelect from "react-select/async";
// import makeAnimated from "react-select/animated";
import { api } from "../../../utils/Api";
import { useParams } from "react-router-dom";
import SelectUserName from "../../../components/SelectUserName/SelectUserName";

let myModal = {};

const userRoles = [
  { value: "admin", label: "Admin" },
  { value: "admin1", label: "Admin1" },
  { value: "admin2", label: "Admin2" },
];

const franchise_initial_value ={
  organization_id: "",
  user_id: "",
  roles: {},
  address: "",
  mobile: "",
  email: "",
  whatsapp: "",
  website: "",
  selected_user: { name: '' },
}

export default function ModalCreateFranchise(props: any) {
  const [cfran_data, SetCFranData] = useState([]);
  const [errors, setErrors] = useState({});


  const [next_page, SetNextPage] = useState(false);
  const [timer, SetTimer] = useState(null);


  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SerIdValue] = useState(useParams());
  const [save_data, SetSaveData] = useState(franchise_initial_value);
  const [roles, SetRoles] = useState([]);
  const [custom_roles, SetCustomRoles] = useState([]);
  const [page_no, SetPageNo] = useState(1);
  const WAIT_INTERVAL = 700;

  const [selected_roles, SetSelectedRoles] = useState([]);
  const [selected_custom_roles, SetSelectedCustomRoles] = useState([]);

  const [userOpen, setUserOpen] = useState(false);


  const [organization_data, SetOrganizationData] = useState([]);
  {
    /* ---------------------------------------------------------------------------------Formdata */
  }
  const [selected_organization_data, SetSelectedOrganizationData] = useState(
    {}
  );
  {
    /* ---------------------------------------------------------------------------------Individual */
  }
  const [franchiseType, setfranchiseType] = useState("individual");

  const [user_data, SetUserData] = useState({});
  const [users_data, SetUsersData] = useState([]);


  async function get_fcreate() {
    if (!validateFields()) return; 
    let save_data_value = save_data;
    let role_data: any = [];
    let c_role_data: any = [];

    // save_data_value.user_id = user_data.id;

    if (franchiseType != "individual") {
      save_data_value.organization_id = selected_organization_data.value;
    }

    selected_roles.map((item: any) => {
      role_data.push(item.value);
    });

    selected_custom_roles.map((item: any) => {
      c_role_data.push(item.value);
    });

    save_data_value.roles = {
      roles: role_data,
      custom_roles: c_role_data,
    };

    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
      post: save_data_value,
    };

    console.log("pass_data", pass_data);

    let response: any = await api("/brand/create_franchise", pass_data);
    console.log("/brand/franchise response :", response);
    if (response.status_code == 201) {

      SetSaveData(franchise_initial_value);
      let item = close_data;
      item.action = "save";
      SetCloseData(item);

      myModal.hide();
    }
  }
  const validateFields = () => {
    const {  mobile, whatsapp, email } = save_data;
    let errors = {mobile: "", whatsapp: "", email: "",};
    let valid = true;

    if (mobile && !/^\d{10}$/.test(mobile)) {
      errors.mobile = "Enter a valid 10-digit mobile number.";
      valid = false;
    }
    if (whatsapp && !/^\d{10}$/.test(whatsapp)) {
      errors.whatsapp = "Enter a valid 10-digit WhatsApp number.";
      valid = false;
    }
    if (email && !/^\S+@\S+\.\S+$/.test(email)) {
      errors.email = "Enter a valid email address.";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };


  const onTextChanged = (e: any) => {
    clearTimeout(timer);
    const value = e.target.value;
    const updatedSelectedUser = { ...save_data.selected_user, name: value };

    SetSaveData((oldValues) => ({
      ...oldValues,
      selected_user: updatedSelectedUser 
      // user_id: item.id,
    }));
    // SetOutletData({ selected_user: updatedSelectedUser });
    console.log("Text onchange: ", value);

    if (value === "") {
      const page_no_value = 1;
      SetPageNo(page_no_value);
      get_users(page_no_value);
    } else {
      SetTimer(
        window.setTimeout(() => {
          get_users(1, value);
        }, WAIT_INTERVAL)
      );
    }
  };
  useEffect(() => {
    if (props.open) {
      console.log("ModalFranchise On Open :", props);
      myModal = new bootstrap.Modal(
        document.getElementById("franchiseModal"),
        {}
      );
      myModal.show();
    }
    get_init();
    get_users(page_no);

  }, [props.open]);

  useEffect(() => {
    console.log("ModalFranchise On mount :", props);
    let myModal1 = document.getElementById("franchiseModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      props.close(close_data);
    });
  }, []);

  async function get_init() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };
    let response: any = await api("/brand/create_franchise_init", pass_data);
    console.log("/brand/franchise create_franchise_init :", response);
    if (response.status_code == 200) {
      if (response.response.hasOwnProperty("roles")) {
        SetRoles(response.response.roles);
      } else {
        SetRoles([]);
      }
      if (response.response.hasOwnProperty("custom_roles")) {
        SetCustomRoles(response.response.custom_roles);
      } else {
        SetCustomRoles([]);
      }
      if (response.response.hasOwnProperty("organization")) {
        SetOrganizationData(response.response.organization);
      } else {
        SetOrganizationData([]);
      }
      if (response.response.hasOwnProperty("user_details")) {
        SetUserData(response.response.user_details);
      } else {
        SetUserData({});
      }
    }
  }


  async function get_users(page_value: any, search_value = null) {
    let pass_data = {};
    if (search_value != null) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_value,
          search: search_value,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_value,
        },
      };
    }
    let response = await api("/brand/get_users", pass_data);
    console.log("Outlet create /brand/get_users response :", response);
    if (response.response.data) {
      SetUsersData(response.response.data);
    }else{
      SetUsersData([]);
    }
    SetNextPage({});
  }

  const handleDataSelected = (userData) => {
    // Do something with the received data in the parent component
    setUserOpen(false)
    console.log("Selected user data in parent:", userData);
            SetSaveData((oldValues) => ({
              ...oldValues,
              selected_user: userData,
              user_id: userData.id,
            }))
  };


  function franchise_value_on_change(name: any, value: any) {

    
    if (name === "mobile" || name === "whatsapp") {
      if (/^\d{10}$/.test(value)) {
        setErrors({ ...errors, [name]: "" }); // Clear error if valid
      } 
    }

    if (name === "email") {
      if (/^\S+@\S+\.\S+$/.test(value)) {
        setErrors({ ...errors, email: "" }); // Clear error if valid
      } 
    }
    SetSaveData((oldValues) => ({
      ...oldValues,
      [name]: value,
    }));
  }

  return (
    <div
      className="modal"
      id="franchiseModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-fullscreen-sm-down modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header shadow-bottom">
            <h6 className="  modal-title" id="ModalLabel">
              {props.type == "create"
                ? "Create Franchise"
                : props.type == "edit"
                  ? "Edit Franchise"
                  : props.type == "outlet"
                    ? "Outlet Franchise"
                    : null}
            </h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>



          <div className="modal-body">
            <p className="text-gray small py-3">Choose your franchise type</p>
            <div className="row pb-3 px-md-3 align-items-center">
              <div className="form-check col-md-6 px-sm-1 mb-3">
                <label className={franchiseType === "individual" ? "cursor fran-type-active form-check-label" : "cursor fran-type form-check-label "} >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    checked={franchiseType === "individual"}
                    onChange={() => {
                      setfranchiseType("individual");
                    }}
                  />
                  <h6 className={franchiseType === "individual" ? "text-prime mb-1 fw-bold" : "text-gray mb-1"}>Individual</h6>
                  <p className="extra-small">Create individual account</p>
                </label>
              </div>
              {/* ---------------------------------------------------------------------------------Organization*/}
              <div className="form-check col-md-6 px-sm-1 mb-3">
                <label className={franchiseType === "Organization" ? "cursor fran-type-active form-check-label" : "cursor fran-type form-check-label "} >
                  <input
                    className="form-check-input d-none"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                    checked={franchiseType === "Organization"}
                    onChange={() => {
                      setfranchiseType("Organization");
                    }}
                  />
                  <h6 className={franchiseType === "Organization" ? "text-prime mb-1 fw-bold" : "text-gray mb-1"}>Organization</h6>
                  <p className="extra-small">Create organization account</p>
                </label>
              </div>
            </div>

            {userOpen ? (
//                 <div className="row pt-3">
//                   <div className="col-12">
//                     {/* <label 
//                     className="form-label" htmlFor="userName"
//                     >Select user</label> */}
//                     <input
//                       type="text"
//                       className="form-control"
//                       id="userName"

//                       placeholder="Search User,User Name"
//                       value={save_data?.selected_user?.name}
//                       onChange={(e) => {
//                         onTextChanged(e);
//                       }}
//                     />
//                   </div>
// {users_data.length !=0 ?
//                   <h6 className="my-4">Available Users</h6> :  <p className="my-4 mt-4">No results found</p> 
// }
//                   {users_data &&
//                   <div className="mt-2 px-3">
//                     {users_data?.map((item, sugIndex) => (
//                       <div
//                         className="cursor d-flex align-items-center mb-3"
//                         key={sugIndex}
//                         onClick={() => {
//                           SetSaveData((oldValues) => ({
//                             ...oldValues,
//                             selected_user: item,
//                             user_id: item.id,
//                           }));

                          
//                           setUserOpen(false);
//                           console.log("Select item:", item);
//                         }}
//                       >

//                         {item.dp_url ?
//                           <img
//                           // src={
//                           //   "https://cdn.1superapp.com/images/320/" +
//                           //   img_src.url
//                           // }
//                           src={item.dp_url}
//                           height={20}
//                           width={20}

//                           // className="cover_img_brand radius"
//                         />  :
//                         <div className="pb-2">
//                            <img
//                     src={require('../../../assets/icon/user.png')}

//                           // src={
//                           //   "https://cdn.1superapp.com/images/320/" +
//                           //   img_src.url
//                           //    src={item.dp_url}
//                           height={20}
//                           width={20}

//                           // className="cover_img_brand radius"
//                         />  
//                       </div>
//                         }

                       
//                         <span><p className="ps-2 text-black pb-2">{item.name}</p>
//                         <p className="ps-2 text-black pb-2">@{item.user_name}</p>
//                         </span>


                        
//                       </div>
//                     ))} 
                    
//                      {/* <FontAwesome5 name="user-alt" size={14} color="#777" /> */}
//                   </div>
// }

//                   {/* {next_page ? (
//                     <p className="text-prime fw-bold mt-3 cursor fs-7"
//                       onClick={() => {
//                         load_more();
//                       }}
//                     >
//                       Load More
//                     </p>
//                   ) : null} */}
//                 </div>
<SelectUserName onDataSelected={handleDataSelected} type="franchise"/>
              ) : (
            <div className="row pt-3">
              {/* <div className="col-md-6 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Franchise type"
                  readOnly={props.type == "edit" ? true : false}
                />
              </div> */}

              {/* <div className="col-md-6 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Franchise User"
                />
              </div> */}

              {/* Org */}

              {franchiseType == "Organization" && (
                <div className="col-md-6 mb-3">
                  <Select
                    options={organization_data}
                    // isMulti
                    placeholder="Organization"
                    onChange={(e: any) => {
                      console.log("SetSelectedRoles", e);
                      SetSelectedOrganizationData(e);
                    }}
                  />
                </div>
              )}
              
                {/* User Name */}
                <div className="col-12 col-md-5 mb-3 ">
                    <label htmlFor="userName" className="form-label">
                      User Name*
                    </label>
                    <div
                      onClick={() => {
                        setUserOpen(true);
                        // SetSaveError(false);
                      }}
                      className="cursor"
                    >
                      <input
                        type="text"
                        className="form-control"
                        id="userName"
                        value={save_data?.selected_user?.name}
                        placeholder="Select User Name"
                      />
                    </div>
                    {/* {errors.userName && (
                      <small className="text-red">{errors.userName}</small>
                    )}
                    {save_error == true ? (
                      <small className="text-red">* Required fields</small>
                    ) : null} */}
                  </div>
              {/* roles */}
              <div className="col-md-6 mb-3">
                <Select
                  options={roles}
                  isMulti
                  placeholder="Roles"
                  onChange={(e: any) => {
                    console.log("SetSelectedRoles", e);
                    SetSelectedRoles(e);
                  }}
                />
              </div>

              {/* cus_role */}
              <div className="col-md-6 mb-3">
                <Select
                  isMulti
                  options={custom_roles}
                  placeholder="Custom Roles"
                  onChange={(e: any) => {
                    SetSelectedCustomRoles(e);
                  }}
                />
              </div>

              {/* mobile number */}
              <div className="col-md-6 mb-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Mobile"
                  onChange={(e) => {
                    franchise_value_on_change("mobile", e.target.value);
                    // SetSaveData((prevValue: any) => {
                    //   prevValue.mobile = e.target.value;
                    //   return { ...prevValue };
                    // });
                  }}
                />
                 {errors.mobile && <span className="text-danger">{errors.mobile}</span>}
              </div>

               {/* whatsapp */}
               <div className="col-md-6 mb-3">
                <input
                  type="number"
                  className="form-control"
                  placeholder="Whatsapp number"
                  onChange={(e) => {
                    franchise_value_on_change("whatsapp", e.target.value);

                    // SetSaveData((prevValue: any) => {
                    //   prevValue.whatsapp = e.target.value;
                    //   return { ...prevValue };
                    // });
                  }}
                />
                 {errors.whatsapp && <span className="text-danger">{errors.whatsapp}</span>}

              </div>

              {/* adderss */}
              <div className="col-md-6 mb-3">
                <textarea
                  placeholder="Address"
                  rows={5}
                  className="form-control"
                  onChange={(e) => {
                    SetSaveData((prevValue: any) => {
                      prevValue.address = e.target.value;
                      return { ...prevValue };
                    });
                  }}
                />
              </div>             

              {/* email */}
              <div className="col-md-6 mb-3">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  onChange={(e) => {

                    franchise_value_on_change("email", e.target.value);
                    // SetSaveData((prevValue: any) => {
                    //   prevValue.email = e.target.value;
                    //   return { ...prevValue };
                    // });
                  }}
                />
                 {errors.email && <span className="text-danger">{errors.email}</span>}

              </div>

              {/* website */}
              <div className="col-md-6 mb-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Website"
                  onChange={(e) => {
                    SetSaveData((prevValue: any) => {
                      prevValue.website = e.target.value;
                      return { ...prevValue };
                    });
                  }}
                />
              </div>
            </div>
              )}
          </div>





          {props.type === "outlet" ? (
            <div className="pb-3">
              <label className="form-label d-flex mb-3">
                <input type="checkbox" className="form-check me-3" />
                Enable Online Store
              </label>
              <label className="form-label d-flex">
                <input type="checkbox" className="form-check me-3" />
                Enable Item Wish Order Management
              </label>
            </div>
          ) : null}

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary-outline btn-radius px-3 me-2"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                // SetSaveData(initial_value);
                myModal.hide();
              }}
            >
              Close
            </button>
            <button
              type="button"
              className="btn bg-prime text-white btn-radius px-3"
              onClick={() => {
                console.log("save");
                get_fcreate();
              }}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
