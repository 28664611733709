import React, { useState, useEffect, useContext, useLayoutEffect, useRef } from "react";
import DataContext from "../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Select from "react-select";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import {
  MaterialIcons,
  AntDesign,
  Ionicons,
  MaterialCommunityIcons,
} from "@expo/vector-icons";
import Slider from "@material-ui/core/Slider";
import { api } from "../../utils/Api";
import toast from "react-hot-toast";

function valuetext(value) {
  return `${value}°C`;
}

const pricingOptions = [
  { value: "outlet", label: "Outlet" },
  // { value: "channel", label: "Channel" },
];

const priceType = [
  { value: "margin", label: "Margin Price" },
  { value: "buy", label: "Buying Price" },
  { value: "commission", label: "Commission Price" },
];

const NewCell = (props) => (
  <div className="th-width">
    <>
      {/* sellers_price */}
      {props.selected_field_option.value == "sellers_price" ? (
        <div className="input-group mb-3">
          <span className="input-group-text" id="basic-addon1">
            {props.mrp_data.currency}
          </span>
          <input
            type="text"
            className="form-control"
            placeholder="Enter Selling Price"
            value={props.value_data.sellers_price}
            onChange={(event) => {
              let send_data = {
                outlet_id: props.outlet_data.id,
                mrp_id: props.mrp_data.id,
                event_value: event,
                change_value: "sellers_price",
              };
              if (props.from == "outlet") {
                send_data.for_value = "outlet";
              } else {
                send_data.channel_id = props.channel_data.id;
                send_data.for_value = "channel";
              }
              props.on_value_change(send_data);
            }}
          />
        </div>
      ) : null}

      {/* Currency */}
      {props.selected_field_option.value == "currency_id" ? (
        <Select
          className="Price"
          options={props.currencies_option}
          value={props.value_data.selected_currency}
          onChange={(e) => {
            console.log("currency :", e);

            let send_data = {
              outlet_id: props.outlet_data.id,
              mrp_id: props.mrp_data.id,
              event_value: e,
              change_value: "currency",
            };
            if (props.from == "outlet") {
              send_data.for_value = "outlet";
            } else {
              send_data.channel_id = props.channel_data.id;
              send_data.for_value = "channel";
            }
            props.on_value_change(send_data);
          }}
        />
      ) : null}

      {/* info Button */}
      <div
        className="ps-3 cursor"
        onClick={(event) => {
          console.log("Click Info : ", props);
          let pass_data = {
            sellers_price: "",
            mrp_data: "",
          };
          props.on_view_full_data(pass_data);
        }}
      >
        <AntDesign name="infocirlceo" size={15} color="gray" />
      </div>
    </>
  </div>
);

export default function AddonPricingComponent1(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [table_loading, SetTableLoading] = useState(true);
  const [expanded, setExpanded] = useState(false);
  const [width, SetWidth] = useState(innerWidth);
  const [id_value, SetIdValue] = useState(useParams());
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [side_drawer, SetSideDrawer] = useState({
    type: "",
  });
  const [value, setValue] = useState(30);
  const [price_for, SetPriceFor] = useState(pricingOptions[0]);

  ////////////////////////////////////////////////////////////////    10/06/2022
  const [page_no, SetPageNo] = useState(1);
  const [next_page, SetNextPage] = useState(false);

  const [view_option, SetViewOption] = useState([
    { label: "New", value: "new" },
    { label: "Active", value: "active" },
    { label: "Unavailable", value: "unavailable" },
    { label: "Paused", value: "paused" },
  ]);
  const [selected_view_option, SetSelectedViewOption] = useState({});
  const [count_option, SetCountOption] = useState([
    { label: "10", value: "10" },
    { label: "20", value: "30" },
    { label: "30", value: "30" },
  ]);
  const [selected_count_option, SetSelectedCountOption] = useState({});
  const [filter_products_value, SetFilterProductsValue] = useState({
    own_products: false,
    others_products: false,
  });
  const [search_value, SetSearchValue] = useState("");
  const [sort_option, SetSortOption] = useState([
    { label: "Ascending order", value: "asc" },
    { label: "Descending order", value: "desc" },
  ]);
  const [selected_sort_option, SetSelectedSortOption] = useState({});
  const [product_brands_option, SetProductBrandsOption] = useState([]);
  const [selected_brand, SetSelectedBrand] = useState([]);
  const [product_categories_option, SetProductCategoriesOption] = useState([]);
  const [selected_category, SetSelectedCategory] = useState([]);
  const [filter_mrp, SetFilterMrp] = useState({
    min_mrp: "",
    max_mrp: "",
  });

  const [product_data, SetProductData] = useState([]);
  const [outlets, SetOutlet] = useState([]);
  const [size_value, SetSizeValue] = useState([]);
  const [image_base_url, SetImageBaseUrl] = useState("");
  const [selling_type_option, SetSellingTypeOption] = useState([]);
  const [currencies_option, SetCurrenciesOption] = useState([]);
  //

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function slide_drawer(anchor: any, open: any, event: any) {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  }

  /////////////////////////////////

  const [outlet_data, SetOutletData] = useState([]);
  const [selected_outlet_data, SetSelectedOutletData] = useState([]);
  const [channel_data, SetChannelData] = useState([]);
  const [selected_channel_data, SetSelectedChannelData] = useState([]);
  const [product_brands, SetProductBrands] = useState([]);
  const [product_categories, SetProductCategories] = useState([]);
  const [country_option, SetCountryOption] = useState(
    context.app_data.country_data
  );
  const [selected_country, SetSelectedCountry] = useState([
    {
      id: "99",
      iso: "IN",
      iso3: "IND",
      label: "India",
      name: "INDIA",
      nicename: "India",
      numcode: "356",
      phonecode: "91",
      value: "99",
    },
  ]);
  const [field_option, SetFieldOption] = useState([
    { label: "Selling Price", value: "sellers_price" },
    // { label: "Inclusive GST", value: "inclusive_gst" },
    // { label: "Up Selling", value: "upselling" },
    // { label: "Selling Type", value: "selling_type" },
    // { label: "Min Selling Price", value: "min_selling_price" },
    // { label: "Max Selling Price", value: "max_selling_price" },
    // { label: "Fixed Selling Price", value: "fixed_selling_price" },
    { label: "Currency", value: "currency_id" },
  ]);
  const [selected_field_option, SetSelectedFieldOption] = useState(
    field_option[0]
  );
  const [table_data, SetTableData] = useState([]);
  const [heading_outlets, SetHeadingOutlets] = useState([]);
  const [heading_outlet_channels, SetHeadingOutletChannels] = useState([]);
  const [outlet_columns, SetOutletColumns] = useState("");
  const [outlet_channel_columns, SetOutletChannelColumns] = useState("");

  // //////////////////////////////////

  const [addon_group_details, SetAddonGroupDetails] = useState([]);
  const [heading_dependent_outlets, SetHeadingDependentOutlets] = useState([]);
  const [heading_dependent_outlet_channels, SetHeadingDependentOutletChannels] =
    useState([]);
  const [is_dependent, SetIsDependent] = useState(false);
  const [platform_fee_data, SetPlatformFeeData] = useState({});

  const [save_price_data, SetSavePriceData] = useState([]);
  const [save_price_channel_data, SetSavePriceChannelData] = useState([]);

  const [save_price_dependent_data, SetSavePriceDependentData] = useState([]);
  const [save_price_channel_dependent_data, SetSavePriceChannelDependentData] =
    useState([]);

  // edited only save values
  const [new_save_price_data, SetNewSavePriceData] = useState([]);
  const [new_save_price_channel_data, SetNewSavePriceChannelData] = useState(
    []
  );

  const [new_save_price_dependent_data, SetNewSavePriceDependentData] =
    useState([]);
  const [
    new_save_price_channel_dependent_data,
    SetNewSavePriceChannelDependentData,
  ] = useState([]);

  const [new_side_drawer_data, SetNewSideDrawerData] = useState({});
  const ref = useRef(null);
  const [width_ref, setWidthRef] = useState(0);
  const [height_ref, setHeightRef] = useState(0);
  const [numbers, setNumbers] = useState([]);

  useLayoutEffect(() => {
    setWidthRef(ref.current.offsetWidth);
    setHeightRef(ref.current.offsetHeight);
  }, [numbers]);


  useEffect(() => {
    console.log("Pricing Addon On mount :", props);
    console.log("Pricing Addon context :", context);
    get_addon_item_init();

    function handleWindowResize() {
      setWidthRef(ref.current.offsetWidth);
      setHeightRef(ref.current.offsetHeight);
    }
    window.addEventListener('resize', handleWindowResize);
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    console.log("save_price_data on change:", save_price_data);
  }, [save_price_data]);

  useEffect(() => {
    SetCountryOption(context.app_data.country_data);
  }, [context.app_data.country_data]);

  async function get_addon_item_init() {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
    };

    console.log("get_addon_item_init pass_data :", pass_data);
    let response = await api("/brand/outlet_addon_pricing_init", pass_data);
    console.log("/brand/outlet_addon_pricing_init response :", response);

    if (response.status_code == 200) {
      let outlet_data, channel_data;
      if (response.response.hasOwnProperty("options")) {
        if (response.response.options.hasOwnProperty("outlets")) {
          outlet_data = response.response.options.outlets;
          outlet_data.map((ele: any) => {
            ele.label = ele.name;
            ele.value = ele.id;
          });
          SetOutletData(outlet_data);
          SetSelectedOutletData(outlet_data);
        }
        if (response.response.options.hasOwnProperty("channels")) {
          channel_data = response.response.options.channels;
          channel_data.map((ele: any) => {
            ele.label = ele.name;
            ele.value = ele.id + "_" + ele.type;
          });
          SetChannelData(channel_data);
          SetSelectedChannelData(channel_data);
        }
        // if (response.response.options.hasOwnProperty("product_brands")) {
        //   SetProductBrands(response.response.options.product_brands);
        // }
        // if (response.response.options.hasOwnProperty("product_categories")) {
        //   SetProductCategories(response.response.options.product_categories);
        // }
        if (response.response.options.hasOwnProperty("sizes")) {
          SetSizeValue(response.response.options.sizes);
        }
        if (response.response.options.hasOwnProperty("image_base_url")) {
          SetImageBaseUrl(response.response.options.image_base_url);
        }
        if (response.response.options.hasOwnProperty("selling_type")) {
          let selling_type_value = response.response.options.selling_type;
          selling_type_value.map((ele) => {
            ele.label = ele.name;
            ele.value = ele.id;
          });
          SetSellingTypeOption(selling_type_value);
        }
        if (response.response.options.hasOwnProperty("currencies")) {
          SetCurrenciesOption(response.response.options.currencies);
        }
      }

      let filter_value = {
        dependent: is_dependent ? 2 : 1,
        selected_country: selected_country,
        outlet_data: outlet_data,
        channel_data: selected_channel_data,
        price_for: price_for,
      };

      get_addon_item(filter_value);
    }
  }

  async function get_addon_item(filter_value) {
    console.log("price_for :", price_for);
    console.log("filter_value :", filter_value);
    let outlet_data_value = [];
    let channel_data_value = [];
    filter_value.outlet_data.map((ele) => {
      outlet_data_value.push(ele.id);
    });
    filter_value.channel_data.map((ele) => {
      channel_data_value.push(ele.id);
    });
    let filter_countries_value = [];
    filter_value.selected_country.map((ele: any) => {
      filter_countries_value.push(ele.id);
    });

    let pass_data = {
      get: {
        brand: id_value.brand_id,
        group_id: id_value.addon_group_id,
        filter_countries: encodeURIComponent(
          JSON.stringify(filter_countries_value)
        ),
        dependent: filter_value.dependent,
        filter_outlets: encodeURIComponent(JSON.stringify(outlet_data_value)),
      },
    };

    if (filter_value.price_for.value == "channel") {
      pass_data.get.filter_channels = encodeURIComponent(
        JSON.stringify(channel_data_value)
      );
    }

    console.log("get_addon_item pass_data :", pass_data);
    let response = await api("/brand/outlet_addon_group_items_v2", pass_data);
    console.log("/brand/outlet_addon_group_items_v2 response :", response);
    if (response.status_code == 200) {
      // toast.success("price saved")
      if (response.response.hasOwnProperty("heading_dependent_outlets")) {
        SetHeadingDependentOutlets(response.response.heading_dependent_outlets);
      }

      if (
        response.response.hasOwnProperty("heading_dependent_outlet_channels")
      ) {
        SetHeadingDependentOutletChannels(
          response.response.heading_dependent_outlet_channels
        );
      }

      if (response.response.hasOwnProperty("addon_group_details")) {
        SetAddonGroupDetails(response.response.addon_group_details);
        SetPlatformFeeData(response.response.addon_group_details.platform_fee);
      }

      if (response.response.hasOwnProperty("table_data")) {
        let table_data_value = response.response.table_data;
        SetTableData(table_data_value);
      }
      if (response.response.hasOwnProperty("heading_outlets")) {
        SetHeadingOutlets(response.response.heading_outlets);
      }
      if (response.response.hasOwnProperty("heading_outlet_channels")) {
        SetHeadingOutletChannels(response.response.heading_outlet_channels);
      }
      if (response.response.hasOwnProperty("outlet_columns")) {
        SetOutletColumns(response.response.outlet_columns);
      }
      if (response.response.hasOwnProperty("outlet_channel_columns")) {
        SetOutletChannelColumns(response.response.outlet_channel_columns);
      }

      SetSavePriceData([]);
      SetSavePriceDependentData([]);
      SetSavePriceChannelData([]);
      SetSavePriceChannelDependentData([]);
      SetNewSavePriceData([]);
      SetNewSavePriceDependentData([]);
      SetNewSavePriceChannelData([]);
      SetNewSavePriceChannelDependentData([]);
      SetNewSideDrawerData({});
    }
  }

  function found_index(prevValue, data, from) {
    let found_index = prevValue.findIndex((element) => {
      if (from == "outlet") {
        if (!is_dependent) {
          if (
            element.outlet_id == data.outlet_id &&
            element.addon_group == data.addon_group &&
            element.addon_item == data.addon_item &&
            element.mrp == data.mrp
          ) {
            return true;
          }
        } else {
          if (
            element.outlet_id == data.outlet_id &&
            element.addon_group == data.addon_group &&
            element.addon_item == data.addon_item &&
            element.specification == data.specification &&
            element.mrp == data.mrp
          ) {
            return true;
          }
        }
      } else {
        if (!is_dependent) {
          if (
            element.outlet_id == data.outlet_id &&
            element.channel_id == data.channel_id &&
            element.addon_group == data.addon_group &&
            element.addon_item == data.addon_item &&
            element.mrp == data.mrp
          ) {
            return true;
          }
        } else {
          if (
            element.outlet_id == data.outlet_id &&
            element.channel_id == data.channel_id &&
            element.addon_group == data.addon_group &&
            element.addon_item == data.addon_item &&
            element.specification == data.specification &&
            element.mrp == data.mrp
          ) {
            return true;
          }
        }
      }
    });
    return found_index;
  }

  async function save_product_pricing(selected_country_value, page_no_value) {
    let filter_countries_value = [];
    selected_country_value.map((ele: any) => {
      filter_countries_value.push(ele.id);
    });
    let pass_data = {
      get: {
        brand: id_value.brand_id,
        filter_countries: encodeURIComponent(
          JSON.stringify(filter_countries_value)
        ),
        // page: page_no_value,
        group_id: id_value.addon_group_id,
        dependent: is_dependent ? 2 : 1,
      },
      post: {},
    };

    if (!is_dependent) {
      if (price_for.value == "outlet") {
        pass_data.post = { data: save_price_data };
      } else {
        pass_data.post = { data: save_price_channel_data };
      }
    } else {
      if (price_for.value == "outlet") {
        pass_data.post = { data: save_price_dependent_data };
      } else {
        pass_data.post = { data: save_price_channel_dependent_data };
      }
    }

    // console.log("save_outlet_addon_pricing_v2 pass_data :", pass_data);

    let pass_data1 = {
      get: {
        brand: id_value.brand_id,
        filter_countries: encodeURIComponent(
          JSON.stringify(filter_countries_value)
        ),
        group_id: id_value.addon_group_id,
        dependent: is_dependent ? 2 : 1,
      },
      post: {},
    };

    if (!is_dependent) {
      if (price_for.value == "outlet") {
        pass_data1.post = { data: new_save_price_data };
      } else {
        pass_data1.post = { data: new_save_price_channel_data };
      }
    } else {
      if (price_for.value == "outlet") {
        pass_data1.post = { data: new_save_price_dependent_data };
      } else {
        pass_data1.post = { data: new_save_price_channel_dependent_data };
      }
    }

    console.log(
      "edited only save_outlet_addon_pricing_v2 pass_data1 :",
      pass_data1
    );

    let response = await api(
      "/brand/save_outlet_addon_pricing_v2",
      pass_data1,
      false,
      false,
      "raw_data"
    );
    if (response.status_code == 200) {
      toast.success("price saved");
    }
    console.log("/brand/save_outlet_addon_pricing_v2 response :", response);
  }

  function get_new_cell_value(props, from, row_data) {
    let find_index;

    if (from == "outlet") {
      // outlet
      // finding index
      if (!is_dependent) {
        find_index = save_price_data.findIndex((element) => {
          if (
            element.outlet_id == props.outlet_id &&
            element.addon_group == props.addon_group &&
            element.addon_item == props.addon_item &&
            element.mrp == props.mrp
          ) {
            return true;
          }
        });
      } else {
        // console.log("is_dependent :", is_dependent);
        // console.log("props,from,row_data :", props, from, row_data);
        find_index = save_price_dependent_data.findIndex((element) => {
          if (
            element.outlet_id == props.outlet_id &&
            element.addon_group == props.addon_group &&
            element.addon_item == props.addon_item &&
            element.specification == props.specification &&
            element.mrp == props.mrp
          ) {
            return true;
          }
        });
      }
      if (find_index !== -1) {
        if (!is_dependent) {
          let return_value = {
            sellers_price: save_price_data[find_index].price.sellers_price,
            selected_currency: save_price_data[find_index].selected_currency,
          };
          return return_value;
        } else {
          let return_value = {
            sellers_price:
              save_price_dependent_data[find_index].price.sellers_price,
            selected_currency:
              save_price_dependent_data[find_index].selected_currency,
          };
          return return_value;
        }
      } else {
        if (
          row_data.outlet_data.hasOwnProperty("price") &&
          Object.keys(row_data.outlet_data.price).length > 0
        ) {
          let selected_currency_value;
          currencies_option.map((cu_ele: any, cu_index: any) => {
            if (cu_ele.id == row_data.value_data.currency_id) {
              selected_currency_value = cu_ele;
            }
          });
          let push_value = {
            outlet_id: props.outlet_id,
            addon_group: props.addon_group,
            addon_item: props.addon_item,
            mrp: props.mrp,
            selected_currency: selected_currency_value,
            price: {
              sellers_price: row_data.outlet_data.price.sellers_price,
              currency_id: selected_currency_value.id,
            },
          };
          if (!is_dependent) {
            SetSavePriceData((prevValue: any) => {
              prevValue.push(push_value);
              return [...prevValue];
            });
          } else {
            push_value.specification = props.specification;
            SetSavePriceDependentData((prevValue: any) => {
              prevValue.push(push_value);
              return [...prevValue];
            });
          }
          // if (for_value == "sellers_price") {
          //   return row_data.outlet_data.price.sellers_price;
          // }
          // if (for_value == "currency") {
          //   return selected_currency_value;
          // }
          let return_value = {
            sellers_price: row_data.outlet_data.price.sellers_price,
            selected_currency: selected_currency_value,
          };
          return return_value;
        } else {
          return "";
        }
      }
    } else {
      // channel
      // finding index
      if (!is_dependent) {
        find_index = save_price_channel_data.findIndex((element) => {
          if (
            element.outlet_id == props.outlet_id &&
            element.channel_id == props.channel_id &&
            element.addon_group == props.addon_group &&
            element.addon_item == props.addon_item &&
            element.mrp == props.mrp
          ) {
            return true;
          }
        });
      } else {
        find_index = save_price_channel_dependent_data.findIndex((element) => {
          if (
            element.outlet_id == props.outlet_id &&
            element.channel_id == props.channel_id &&
            element.addon_group == props.addon_group &&
            element.addon_item == props.addon_item &&
            element.mrp == props.mrp
          ) {
            return true;
          }
        });
      }

      if (find_index !== -1) {
        if (!is_dependent) {
          if (for_value == "sellers_price") {
            return save_price_channel_data[find_index].price.sellers_price;
          }
          if (for_value == "currency") {
            return save_price_channel_data[find_index].selected_currency;
          }
        } else {
          if (for_value == "sellers_price") {
            return save_price_channel_dependent_data[find_index].price
              .sellers_price;
          }
          if (for_value == "currency") {
            return save_price_channel_dependent_data[find_index]
              .selected_currency;
          }
        }
      } else {
        if (Object.keys(row_data.outlet_data.price).length > 0) {
          let selected_currency_value;
          currencies_option.map((cu_ele: any, cu_index: any) => {
            if (cu_ele.id == row_data.value_data.currency_id) {
              selected_currency_value = cu_ele;
            }
          });
          let push_value = {
            outlet_id: props.outlet_id,
            addon_group: props.addon_group,
            addon_item: props.addon_item,
            mrp: props.mrp,
            selected_currency: selected_currency_value,
            price: {
              sellers_price: row_data.channel_data.price.sellers_price,
              inclusive_gst: row_data.channel_data.price.inclusive_gst,
              upselling: row_data.channel_data.price.upselling,
              selling_type: row_data.channel_data.price.selling_type,
              min_selling_price: row_data.channel_data.price.min_selling_price,
              max_selling_price: row_data.channel_data.price.max_selling_price,
              fixed_selling_price:
                row_data.channel_data.price.fixed_selling_price,
              currency_id: selected_currency_value.id,
            },
          };
          if (!is_dependent) {
            SetSavePriceChannelData((prevValue: any) => {
              prevValue.push(push_value);
              return [...prevValue];
            });
          } else {
            SetSavePriceChannelDependentData((prevValue: any) => {
              prevValue.push(push_value);
              return [...prevValue];
            });
          }
          if (for_value == "sellers_price") {
            return row_data.channel_data.price.sellers_price;
          }
        } else {
          return "";
        }
      }
    }
  }

  function value_on_change_outlet(get_data: any) {
    if (!is_dependent) {
      SetSavePriceData((prevValue: any) => {
        let is_found_index = found_index(
          prevValue,
          {
            outlet_id: get_data.outlet_id,
            addon_group: get_data.o_ele.addon_groups,
            addon_item: get_data.o_ele.addon_item,
            mrp: get_data.o_ele.mrp_id,
          },
          "outlet"
        );

        if (is_found_index !== -1) {
          if (get_data.change_value == "sellers_price") {
            prevValue[is_found_index].price.sellers_price =
              get_data.event_value.target.value;
          }
          if (get_data.change_value == "currency") {
            prevValue[is_found_index].selected_currency = get_data.event_value;
            prevValue[is_found_index].price.currency_id =
              get_data.event_value.id;
          }
        } else {
          let push_data = {
            outlet_id: get_data.outlet_id,
            addon_group: get_data.o_ele.addon_groups,
            addon_item: get_data.o_ele.addon_item,
            mrp: get_data.o_ele.mrp_id,
            selected_currency: currencies_option[0],
            price: {
              currency_id: currencies_option[0].id,
            },
          };
          if (get_data.change_value == "sellers_price") {
            push_data.price.sellers_price = get_data.event_value.target.value;
          }
          if (get_data.change_value == "currency") {
            push_data.selected_currency = get_data.event_value;
            push_data.price.currency_id = get_data.event_value.id;
          }
          prevValue.push(push_data);
        }

        SetNewSavePriceData((prevValue1: any) => {
          let is_found_index1 = found_index(
            prevValue1,
            {
              outlet_id: get_data.outlet_id,
              addon_group: get_data.o_ele.addon_groups,
              addon_item: get_data.o_ele.addon_item,
              mrp: get_data.o_ele.mrp_id,
            },
            "outlet"
          );

          if (is_found_index1 !== -1) {
            if (get_data.change_value == "sellers_price") {
              prevValue1[is_found_index1].price.sellers_price =
                get_data.event_value.target.value;
            }
            if (get_data.change_value == "currency") {
              prevValue1[is_found_index1].selected_currency =
                get_data.event_value;
              prevValue1[is_found_index1].price.currency_id =
                get_data.event_value.id;
            }
          } else {
            let push_data1 = {
              outlet_id: get_data.outlet_id,
              addon_group: get_data.o_ele.addon_groups,
              addon_item: get_data.o_ele.addon_item,
              mrp: get_data.o_ele.mrp_id,
              selected_currency: currencies_option[0],
              price: {
                currency_id: currencies_option[0].id,
              },
            };

            if (get_data.change_value == "sellers_price") {
              push_data1.price.sellers_price =
                get_data.event_value.target.value;
            }
            if (get_data.change_value == "currency") {
              push_data1.selected_currency = get_data.event_value;
              push_data1.price.currency_id = get_data.event_value.id;
            }
            prevValue1.push(push_data1);
          }
          return [...prevValue1];
        });

        return [...prevValue];
      });
    } else {
      SetSavePriceDependentData((prevValue: any) => {
        let is_found_index = found_index(
          prevValue,
          {
            outlet_id: get_data.outlet_id,
            addon_group: get_data.o_ele.addon_groups,
            addon_item: get_data.o_ele.addon_item,
            specification: get_data.o_ele.specification_item,
            mrp: get_data.o_ele.mrp_id,
          },
          "outlet"
        );

        if (is_found_index !== -1) {
          if (get_data.change_value == "sellers_price") {
            prevValue[is_found_index].price.sellers_price =
              get_data.event_value.target.value;
          }
          if (get_data.change_value == "currency") {
            prevValue[is_found_index].selected_currency = get_data.event_value;
            prevValue[is_found_index].price.currency_id =
              get_data.event_value.id;
          }
        } else {
          let push_data = {
            outlet_id: get_data.outlet_id,
            addon_group: get_data.o_ele.addon_groups,
            addon_item: get_data.o_ele.addon_item,
            mrp: get_data.o_ele.mrp_id,
            specification: get_data.o_ele.specification_item,
            selected_currency: currencies_option[0],
            price: {
              currency_id: currencies_option[0].id,
            },
          };
          if (get_data.change_value == "sellers_price") {
            push_data.price.sellers_price = get_data.event_value.target.value;
          }
          if (get_data.change_value == "currency") {
            push_data.selected_currency = get_data.event_value;
            push_data.price.currency_id = get_data.event_value.id;
          }
          prevValue.push(push_data);
        }
        SetNewSavePriceDependentData((prevValue1: any) => {
          let is_found_index1 = found_index(
            prevValue1,
            {
              outlet_id: get_data.outlet_id,
              addon_group: get_data.o_ele.addon_groups,
              addon_item: get_data.o_ele.addon_item,
              specification: get_data.o_ele.specification_item,
              mrp: get_data.o_ele.mrp_id,
            },
            "outlet"
          );

          if (is_found_index1 !== -1) {
            if (get_data.change_value == "sellers_price") {
              prevValue1[is_found_index1].price.sellers_price =
                get_data.event_value.target.value;
            }
            if (get_data.change_value == "currency") {
              prevValue1[is_found_index1].selected_currency =
                get_data.event_value;
              prevValue1[is_found_index1].price.currency_id =
                get_data.event_value.id;
            }
          } else {
            let push_data1 = {
              outlet_id: get_data.outlet_id,
              addon_group: get_data.o_ele.addon_groups,
              addon_item: get_data.o_ele.addon_item,
              mrp: get_data.o_ele.mrp_id,
              specification: get_data.o_ele.specification_item,
              selected_currency: currencies_option[0],
              price: {
                currency_id: currencies_option[0].id,
              },
            };

            if (get_data.change_value == "sellers_price") {
              push_data1.price.sellers_price =
                get_data.event_value.target.value;
            }
            if (get_data.change_value == "currency") {
              push_data1.selected_currency = get_data.event_value;
              push_data1.price.currency_id = get_data.event_value.id;
            }
            prevValue1.push(push_data1);
          }
          return [...prevValue1];
        });
        return [...prevValue];
      });
    }
  }

  function value_on_change_channel(get_data: any) {
    console.log("value_on_change_channel get_data :", get_data);
  }

  function on_information_change(get_data: any) {
    console.log("on_information_change get_data :", get_data);
    if (get_data.from == "outlet") {
      // outlet
      let push_data = {
        outlet_id: get_data.row_data.outlet_id,
        addon_group: get_data.row_data.addon_group,
        addon_item: get_data.row_data.addon_item,
        mrp: get_data.row_data.mrp,
        selected_currency: get_data.row_data.selected_currency,
        price: {},
      };
      if (!is_dependent) {
        SetSavePriceData((prevValue: any) => {
          if (get_data.index != -1) {
            prevValue[get_data.index] = get_data.row_data;
          } else {
            push_data.price = get_data.row_data.price;
            prevValue.push(push_data);
          }
          SetNewSavePriceData((prevValue1: any) => {
            if (get_data.new_index != -1) {
              prevValue1[get_data.new_index] = get_data.row_data;
            } else {
              push_data.price = get_data.row_data.price;
              prevValue1.push(push_data);
            }
            return [...prevValue1];
          });
          return [...prevValue];
        });
      } else {
        push_data.specification = get_data.row_data.specification;
        SetSavePriceDependentData((prevValue: any) => {
          if (get_data.index != -1) {
            prevValue[get_data.index] = get_data.row_data;
          } else {
            push_data.price = get_data.row_data.price;
            prevValue.push(push_data);
          }
          SetNewSavePriceDependentData((prevValue1: any) => {
            if (get_data.new_index != -1) {
              prevValue1[get_data.new_index] = get_data.row_data;
            } else {
              push_data.price = get_data.row_data.price;
              prevValue1.push(push_data);
            }
            return [...prevValue1];
          });
          return [...prevValue];
        });
      }
    } else {
      // channel
      if (get_data.index != -1) {
        if (!is_dependent) {
          SetSavePriceChannelData((prevValue: any) => {
            prevValue[get_data.index].price.sellers_price = e.target.value;
            return [...prevValue];
          });
        } else {
          SetSavePriceChannelDependentData((prevValue: any) => {
            prevValue[get_data.index].price.sellers_price = e.target.value;
            return [...prevValue];
          });
        }
      } else {
        if (!is_dependent) {
          SetSavePriceChannelData((prevValue: any) => {
            let push_data = {
              outlet_id: get_data.row_data.outlet_id,
              channel_id: get_data.row_data.channel_id,
              // mrp_id: get_data.row_data.mrp_id,
              addon_group: get_data.row_data.addon_group,
              addon_item: get_data.row_data.addon_item,
              mrp: get_data.row_data.mrp,
              // specification : get_data.row_data.spec,
              price: {
                sellers_price: e.target.value,
              },
            };
            prevValue.push(push_data);
            return [...prevValue];
          });
        } else {
          SetSavePriceChannelDependentData((prevValue: any) => {
            let push_data = {
              outlet_id: get_data.row_data.outlet_id,
              channel_id: get_data.row_data.channel_id,
              // mrp_id: get_data.row_data.mrp_id,
              addon_group: get_data.row_data.addon_group,
              addon_item: get_data.row_data.addon_item,
              mrp: get_data.row_data.mrp,
              specification: get_data.row_data.spec,
              price: {
                sellers_price: e.target.value,
              },
            };
            prevValue.push(push_data);
            return [...prevValue];
          });
        }
      }
    }
  }

  return (
    <div className="">
      {/* Header */}
      {/* <div
        className={
          OutletContext
            ? table_data.length == 0
              ? "head-bar w-100" : "head-bar table-head-bar  sticky-sm" : "head-bar "
        }
        style={OutletContext == false ? { width: width } : {}}
      > */}

      <div className={OutletContext ? table_data.length == 0 ? " head-bar w-100" : " head-bar sticky-sm" : "head-bar sticky-top"}
        style={OutletContext == false ? { width: width } : table_data.length != 0 ? { width: width_ref + 46 } : {}}
      >
        <div className="row align-items-center">
          <div className="col-md-6 d-flex align-items-center sm-mb-2">
            <div
              className="cursor pe-2"
              onClick={() => {
                window.history.back(1);
              }}
            >
              {/* <Link to={"/brand/" + props.value.brand_id + "/product_assign"}> */}
              <MaterialCommunityIcons name="arrow-left" size={20} color="black" />
              {/* </Link> */}
            </div>

            <h6 className="mb-0">{props.title}</h6>


          </div>

          <div className="col-md-6 end">
            {/* Filter */}
            <div
              className="d-flex align-items-center cursor me-3 border-right px-3"
              onClick={(e) => {
                SetNewSideDrawerData((prevValue: any) => {
                  prevValue.type = "filter";
                  return { ...prevValue };
                });
                if (width < 768) {
                  slide_drawer("bottom", true, event);
                } else {
                  slide_drawer("right", true, event);
                }

                // SetSideDrawer((prevValue: any) => {
                //   prevValue.type = "filter";
                //   slide_drawer("right", true, e);
                //   return { ...prevValue };
                // });
              }}
            >
              <img
                src={require("../../assets/icon/filter.png")}
                className="type-img"
              />
              {width > 548 ? (
                <p className="text-darkgray mb-0 ps-3">Filter</p>
              ) : null}

            </div>
            {/* Dependent / Independent */}
            <div className="d-flex align-items-center px-2">
              <div className="">
                <label className="form-label mb-0">Independent</label>
              </div>

              <div className="form-check form-switch px-3 m-0 center">
                <input
                  type="checkbox"
                  className="form-check-input m-0"
                  checked={is_dependent}
                  onChange={(event) => {
                    // onChange(rowData, props, event);
                    console.log("event on change :", event);
                    SetIsDependent(event.target.checked);
                    let filter_value = {
                      dependent: event.target.checked ? 2 : 1,
                      selected_country: selected_country,
                      outlet_data: selected_outlet_data,
                      channel_data: selected_channel_data,
                      price_for: price_for,
                    };
                    get_addon_item(filter_value);
                  }}
                />
              </div>
              <div className="pe-0 ">
                <label className="form-label mb-0">Dependent</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div
        className={
          OutletContext
            ? table_data.length == 0
              ? "mt-3 px-md-3 px-sm-0 w-100"
              : "mt-3 px-md-3 px-sm-0 overflow-page-hidden"
            : "mt-3 px-md-3 px-sm-0 overflow-hidden mb-5"
        }
        style={OutletContext == false ? { width: width } : {}}
      > */}

      <div className={OutletContext ? table_data.length == 0 ? "mt-3 px-md-3 px-sm-0 w-100" : "mt-3 px-md-3 px-sm-0 overflow-page-hidden" : "mb-5 mt-3 px-md-3 px-sm-0 overflow-hidden"}
        style={OutletContext == false ? { width: width } : table_data.length != 0 ? { width: width_ref + 46 } : {}}
      >
        {/* Body */}
        <div
          className="p-3 mt-2 w-100 bg-fff" ref={ref}
        // style={props.is_menu_open == false ? { width: width } : {}}
        >
          {table_data.length != 0 ? (
            <div className="mt-3 table-responsive  table-container ">
              {/* table */}
              <div className="row tle-fixed px-0">
                <table
                  border="1"
                  className="table table-bordered table-hover table-condensed"
                >
                  {/* thead */}
                  {!is_dependent ? (
                    <thead className="sticky-table-head bg-fff">
                      {price_for.value == "outlet"
                        ? heading_outlets.map((ho_ele: any, ho_index: any) => (
                          <tr key={ho_index} className="">
                            {ho_ele.map((ho1_ele: any, ho1_index: any) => (
                              <th
                                className={
                                  ho_index == 0 && ho1_index == 0
                                    ? "ps-3 th-prod text-center col p-0"
                                    : ""
                                }
                                key={ho1_index}
                                rowSpan={
                                  ho1_ele.hasOwnProperty("rowspan")
                                    ? ho1_ele.rowspan
                                    : ""
                                }
                                colSpan={
                                  ho1_ele.hasOwnProperty("colspan")
                                    ? ho1_ele.colspan
                                    : ""
                                }
                              >
                                {ho1_ele.name}
                              </th>
                            ))}
                          </tr>
                        ))
                        : null}
                      {price_for.value == "channel"
                        ? heading_outlet_channels.map(
                          (hoc_ele: any, hoc_index: any) => (
                            <tr key={hoc_index}>
                              {hoc_ele.map(
                                (hoc1_ele: any, hoc1_index: any) => (
                                  <th
                                    className="w-50"
                                    key={hoc1_index}
                                    rowSpan={
                                      hoc1_ele.hasOwnProperty("rowspan")
                                        ? hoc1_ele.rowspan
                                        : ""
                                    }
                                    colSpan={
                                      hoc1_ele.hasOwnProperty("colspan")
                                        ? hoc1_ele.colspan
                                        : ""
                                    }
                                  >
                                    {hoc1_ele.name}
                                  </th>
                                )
                              )}
                            </tr>
                          )
                        )
                        : null}
                    </thead>
                  ) : (
                    <thead className="sticky-table-head bg-fff">
                      {price_for.value == "outlet"
                        ? heading_dependent_outlets.map(
                          (ho_ele: any, ho_index: any) => (
                            <tr key={ho_index} className="">
                              {ho_ele.map((ho1_ele: any, ho1_index: any) => (
                                <th
                                  className={
                                    ho_index == 0 && ho1_index == 0
                                      ? "ps-3 th-prod text-center col p-0"
                                      : ""
                                  }
                                  key={ho1_index}
                                  rowSpan={
                                    ho1_ele.hasOwnProperty("rowspan")
                                      ? ho1_ele.rowspan
                                      : ""
                                  }
                                  colSpan={
                                    ho1_ele.hasOwnProperty("colspan")
                                      ? ho1_ele.colspan
                                      : ""
                                  }
                                >
                                  {ho1_ele.name}
                                </th>
                              ))}
                            </tr>
                          )
                        )
                        : null}
                      {price_for.value == "channel"
                        ? heading_dependent_outlet_channels.map(
                          (hoc_ele: any, hoc_index: any) => (
                            <tr key={hoc_index}>
                              {hoc_ele.map(
                                (hoc1_ele: any, hoc1_index: any) => (
                                  <th
                                    className="w-50"
                                    key={hoc1_index}
                                    rowSpan={
                                      hoc1_ele.hasOwnProperty("rowspan")
                                        ? hoc1_ele.rowspan
                                        : ""
                                    }
                                    colSpan={
                                      hoc1_ele.hasOwnProperty("colspan")
                                        ? hoc1_ele.colspan
                                        : ""
                                    }
                                  >
                                    {hoc1_ele.name}
                                  </th>
                                )
                              )}
                            </tr>
                          )
                        )
                        : null}
                    </thead>
                  )}
                  <tbody>
                    {table_data.map((ele: any, index: any) =>
                      !is_dependent ? (
                        <>
                          {ele.hasOwnProperty("countries") &&
                            ele.countries.length > 0 ? (
                            ele.countries.map(
                              (country: any, country_index: any) =>
                                country.hasOwnProperty("states") &&
                                  country.states.length > 0 ? (
                                  country.states.map(
                                    (state: any, state_index: any) =>
                                      state.hasOwnProperty("type") &&
                                        state.type.length > 0 ? (
                                        state.type.map(
                                          (type: any, type_index: any) =>
                                            type.hasOwnProperty("mrp") &&
                                              type.mrp.length > 0 ? (
                                              type.mrp.map(
                                                (
                                                  value: any,
                                                  value_index: any
                                                ) => (
                                                  <tr>
                                                    {/* others */}
                                                    <>
                                                      {/* variant name */}
                                                      {country_index == 0 &&
                                                        state_index == 0 &&
                                                        type_index == 0 &&
                                                        value_index == 0 ? (
                                                        <td
                                                          rowSpan={ele.rowspan}
                                                          className="ps-3 sticky-left bg-fff"
                                                        >
                                                          {ele.name}
                                                        </td>
                                                      ) : null}
                                                      {/* country name */}
                                                      {state_index == 0 &&
                                                        type_index == 0 &&
                                                        value_index == 0 ? (
                                                        <td
                                                          className=""
                                                          rowSpan={
                                                            country.rowspan
                                                          }
                                                        >
                                                          {country.name}
                                                        </td>
                                                      ) : null}
                                                      {/* state name */}
                                                      {type_index == 0 &&
                                                        value_index == 0 ? (
                                                        <td
                                                          className=""
                                                          rowSpan={
                                                            state.rowspan
                                                          }
                                                        >
                                                          {state.name}
                                                        </td>
                                                      ) : null}
                                                      {/* mrp type */}
                                                      {value_index == 0 ? (
                                                        <td
                                                          className=""
                                                          rowSpan={type.rowspan}
                                                        >
                                                          {type.name}
                                                        </td>
                                                      ) : null}
                                                      {/* mrp value */}
                                                      <td className="">
                                                        {value.name}
                                                      </td>
                                                      <td className="">
                                                        {value.name}
                                                      </td>
                                                    </>
                                                    {/* outlets / channel */}
                                                    {value.outlets.map(
                                                      (
                                                        o_ele: any,
                                                        o_index: any
                                                      ) => (
                                                        <>
                                                          {/* for outlet */}
                                                          {price_for.value ==
                                                            "outlet" ? (
                                                            <td className="">
                                                              {o_ele.enabled ==
                                                                true ? (
                                                                <>
                                                                  <NewCell
                                                                    from={
                                                                      "outlet"
                                                                    }
                                                                    row_data={
                                                                      ele
                                                                    }
                                                                    outlet_data={
                                                                      o_ele
                                                                    }
                                                                    outlet_index={
                                                                      o_index
                                                                    }
                                                                    mrp_data={
                                                                      value
                                                                    }
                                                                    selected_field_option={
                                                                      selected_field_option
                                                                    }
                                                                    currencies_option={
                                                                      currencies_option
                                                                    }
                                                                    value_data={get_new_cell_value(
                                                                      {
                                                                        outlet_id:
                                                                          o_ele.id,
                                                                        addon_group:
                                                                          o_ele.addon_groups,
                                                                        addon_item:
                                                                          o_ele.addon_item,
                                                                        mrp: o_ele.mrp_id,
                                                                      },
                                                                      "outlet",
                                                                      {
                                                                        outlet_data:
                                                                          o_ele,
                                                                        outlet_index:
                                                                          o_index,
                                                                        value_data:
                                                                          value,
                                                                      }
                                                                    )}
                                                                    on_value_change={(
                                                                      data_value
                                                                    ) => {
                                                                      data_value.o_ele =
                                                                        o_ele;
                                                                      console.log(
                                                                        "on_value_change data_value :",
                                                                        data_value
                                                                      );
                                                                      value_on_change_outlet(
                                                                        data_value
                                                                      );
                                                                    }}
                                                                    on_view_full_data={(
                                                                      data_value
                                                                    ) => {
                                                                      console.log(
                                                                        "on_view_full_data data_value :",
                                                                        data_value
                                                                      );
                                                                      let is_found_index_check =
                                                                        found_index(
                                                                          save_price_data,
                                                                          {
                                                                            outlet_id:
                                                                              o_ele.id,
                                                                            addon_group:
                                                                              id_value.addon_group_id,
                                                                            addon_item:
                                                                              ele.id,
                                                                            mrp: value.id,
                                                                          },
                                                                          "outlet"
                                                                        );
                                                                      let is_found_new_index_check =
                                                                        found_index(
                                                                          new_save_price_data,
                                                                          {
                                                                            outlet_id:
                                                                              o_ele.id,
                                                                            addon_group:
                                                                              id_value.addon_group_id,
                                                                            addon_item:
                                                                              ele.id,
                                                                            mrp: value.id,
                                                                          },
                                                                          "outlet"
                                                                        );
                                                                      if (
                                                                        width <
                                                                        768
                                                                      ) {
                                                                        slide_drawer(
                                                                          "bottom",
                                                                          true,
                                                                          event
                                                                        );
                                                                      } else {
                                                                        slide_drawer(
                                                                          "right",
                                                                          true,
                                                                          event
                                                                        );
                                                                      }
                                                                      SetSideDrawer(
                                                                        {
                                                                          type: "information",
                                                                          from: "outlet",
                                                                          index:
                                                                            is_found_index_check,
                                                                          new_index:
                                                                            is_found_new_index_check,
                                                                          row_data:
                                                                          {
                                                                            outlet_id:
                                                                              o_ele.id,
                                                                            addon_group:
                                                                              o_ele.addon_groups,
                                                                            addon_item:
                                                                              o_ele.addon_item,
                                                                            mrp: o_ele.mrp_id,
                                                                          },
                                                                          varriant_data:
                                                                            ele,
                                                                          outlet_data:
                                                                            o_ele,
                                                                        }
                                                                      );
                                                                      let pass_value =
                                                                      {
                                                                        type: "information",
                                                                        from: "outlet",
                                                                        mrp_value:
                                                                          value,
                                                                        index:
                                                                          is_found_index_check,
                                                                        new_index:
                                                                          is_found_new_index_check,
                                                                        row_data:
                                                                        {
                                                                          outlet_id:
                                                                            o_ele.id,
                                                                          addon_group:
                                                                            o_ele.addon_groups,
                                                                          addon_item:
                                                                            o_ele.addon_item,
                                                                          mrp: o_ele.mrp_id,
                                                                          selected_currency:
                                                                            currencies_option[0],
                                                                          price:
                                                                          {
                                                                            sellers_price:
                                                                              "",
                                                                            currency_id:
                                                                              currencies_option[0]
                                                                                .id,
                                                                          },
                                                                        },
                                                                        varriant_data:
                                                                          ele,
                                                                        outlet_data:
                                                                          o_ele,
                                                                      };
                                                                      if (
                                                                        is_found_index_check !=
                                                                        -1
                                                                      ) {
                                                                        pass_value.row_data.selected_currency =
                                                                          save_price_data[
                                                                            is_found_index_check
                                                                          ].selected_currency;
                                                                        pass_value.row_data.price =
                                                                          save_price_data[
                                                                            is_found_index_check
                                                                          ].price;
                                                                      } else {
                                                                        pass_value.row_data.selected_currency =
                                                                          currencies_option[0];
                                                                        pass_value.row_data.price =
                                                                        {
                                                                          sellers_price:
                                                                            "",
                                                                          currency_id:
                                                                            currencies_option[0]
                                                                              .id,
                                                                        };
                                                                      }
                                                                      SetNewSideDrawerData(
                                                                        pass_value
                                                                      );
                                                                    }}
                                                                  />
                                                                </>
                                                              ) : null}
                                                            </td>
                                                          ) : null}

                                                          {/* for chhannel */}
                                                          {/* {price_for.value ==
                                                        "channel"
                                                          ? o_ele.hasOwnProperty(
                                                              "channels"
                                                            )
                                                            ? o_ele.channels.map(
                                                                (
                                                                  c_ele: any,
                                                                  c_index: any
                                                                ) => (
                                                                  <td className="">
                                                                    {o_ele.enabled ==
                                                                    true ? (
                                                                      Object.keys(
                                                                        c_ele
                                                                      ).length >
                                                                      0 ? (
                                                                        <NewCell
                                                                          from={
                                                                            "channel"
                                                                          }
                                                                          row_data={
                                                                            ele
                                                                          }
                                                                          outlet_data={
                                                                            o_ele
                                                                          }
                                                                          channel_data={
                                                                            c_ele
                                                                          }
                                                                          outlet_index={
                                                                            o_index
                                                                          }
                                                                          channel_index={
                                                                            c_index
                                                                          }
                                                                          mrp_data={
                                                                            value
                                                                          }
                                                                          selected_field_option={
                                                                            selected_field_option
                                                                          }
                                                                          currencies_option={
                                                                            currencies_option
                                                                          }
                                                                          value_data={get_new_cell_value(
                                                                            {
                                                                              outlet_id:
                                                                                o_ele.id,
                                                                              channel_id:
                                                                                c_ele.id,
                                                                              addon_group:
                                                                                o_ele.addon_groups,
                                                                              addon_item:
                                                                                o_ele.addon_item,
                                                                              // spec: o_ele.specification_item,
                                                                              mrp: o_ele.mrp_id,
                                                                            },
                                                                            "channel",
                                                                            {
                                                                              outlet_data:
                                                                                o_ele,
                                                                              outlet_index:
                                                                                o_index,
                                                                              channel_data:
                                                                                c_ele,
                                                                              channel_index:
                                                                                c_index,
                                                                              value_data:
                                                                                value,
                                                                            }
                                                                          )}
                                                                          on_value_change={(
                                                                            data_value
                                                                          ) => {
                                                                            data_value.o_ele =
                                                                              o_ele;
                                                                            console.log(
                                                                              "on_value_change channel data_value :",
                                                                              data_value
                                                                            );
                                                                            value_on_change_channel(
                                                                              data_value
                                                                            );
                                                                          }}
                                                                          on_view_full_data={(
                                                                            data_value
                                                                          ) => {
                                                                            console.log(
                                                                              "on_view_full_data data_value channel :",
                                                                              data_value
                                                                            );
                                                                            let is_found_index_check =
                                                                              found_index(
                                                                                save_price_channel_data,
                                                                                {
                                                                                  outlet_id:
                                                                                    o_ele.id,
                                                                                  channel_id:
                                                                                    c_ele.id,
                                                                                  addon_group:
                                                                                    id_value.addon_group_id,
                                                                                  addon_item:
                                                                                    ele.id,
                                                                                  mrp: value.id,
                                                                                },
                                                                                "channel"
                                                                              );

                                                                            let is_found_new_index_check =
                                                                              found_index(
                                                                                new_save_price_channel_data,
                                                                                {
                                                                                  outlet_id:
                                                                                    o_ele.id,
                                                                                  channel_id:
                                                                                    c_ele.id,
                                                                                  addon_group:
                                                                                    id_value.addon_group_id,
                                                                                  addon_item:
                                                                                    ele.id,
                                                                                  mrp: value.id,
                                                                                },
                                                                                "channel"
                                                                              );

                                                                            if (
                                                                              width <
                                                                              768
                                                                            ) {
                                                                              slide_drawer(
                                                                                "bottom",
                                                                                true,
                                                                                event
                                                                              );
                                                                            } else {
                                                                              slide_drawer(
                                                                                "right",
                                                                                true,
                                                                                event
                                                                              );
                                                                            }

                                                                            SetSideDrawer(
                                                                              {
                                                                                type: "information",
                                                                                from: "channel",
                                                                                index:
                                                                                  is_found_index_check,
                                                                                new_index:
                                                                                  is_found_new_index_check,
                                                                                row_data:
                                                                                  {
                                                                                    outlet_id:
                                                                                      o_ele.id,
                                                                                    channel_id:
                                                                                      c_ele.id,
                                                                                    mrp_id:
                                                                                      value.id,
                                                                                  },
                                                                                varriant_data:
                                                                                  ele,
                                                                                outlet_data:
                                                                                  o_ele,
                                                                              }
                                                                            );
                                                                          }}
                                                                        />
                                                                      ) : null
                                                                    ) : null}
                                                                  </td>
                                                                )
                                                              )
                                                            : null
                                                          : null} */}
                                                        </>
                                                      )
                                                    )}
                                                  </tr>
                                                )
                                              )
                                            ) : (
                                              <tr>
                                                {country_index == 0 &&
                                                  state_index == 0 &&
                                                  type_index == 0 ? (
                                                  <td
                                                    rowSpan={ele.rowspan}
                                                    className="ps-3 sticky-left bg-fff"
                                                  >
                                                    {ele.name}
                                                  </td>
                                                ) : null}

                                                {state_index == 0 &&
                                                  type_index == 0 ? (
                                                  <td rowSpan={country.rowspan}>
                                                    {country.name}
                                                  </td>
                                                ) : null}

                                                {type_index == 0 ? (
                                                  <td rowSpan={state.rowspan}>
                                                    {state.name}
                                                  </td>
                                                ) : null}

                                                <td rowSpan={type.rowspan}>
                                                  {type.name}
                                                </td>
                                              </tr>
                                            )
                                        )
                                      ) : (
                                        <tr>
                                          {country_index == 0 &&
                                            state_index == 0 ? (
                                            <td
                                              rowSpan={ele.rowspan}
                                              className="ps-3 sticky-left bg-fff"
                                            >
                                              {ele.name}
                                            </td>
                                          ) : null}

                                          {state_index == 0 ? (
                                            <td rowSpan={country.rowspan}>
                                              {country.name}
                                            </td>
                                          ) : null}

                                          <td rowSpan={state.rowspan}>
                                            {state.name}
                                          </td>
                                        </tr>
                                      )
                                  )
                                ) : (
                                  <tr>
                                    {country_index == 0 ? (
                                      <>
                                        <td
                                          rowSpan={ele.rowspan}
                                          className="sticky-left bg-fff"
                                        >
                                          {ele.name}
                                        </td>
                                        <td rowSpan={country.rowspan}>
                                          {country.name}
                                        </td>
                                      </>
                                    ) : (
                                      <td rowSpan={country.rowspan}>
                                        {country.name}
                                      </td>
                                    )}
                                  </tr>
                                )
                            )
                          ) : (
                            <tr>
                              <td rowSpan={ele.rowspan}>{ele.name}</td>
                            </tr>
                          )}
                        </>
                      ) : (
                        // dependent
                        <>
                          {ele.hasOwnProperty("name") ? (
                            <tr className="border-bottom">
                              <th className="td-prod p-0 w-100">
                                <div className=" fs-7 bg-fff p-2 ps-3 w-100" colSpan={outlet_columns}>
                                  {ele.name}
                                </div>
                              </th>
                            </tr>
                          ) : null}
                          {ele.hasOwnProperty("specification") &&
                            ele.specification.length > 0
                            ? ele.specification.map(
                              (spec: any, spec_index: any) =>
                                spec.hasOwnProperty("items") &&
                                  spec.items.length > 0 ? (
                                  spec.items.map(
                                    (item: any, item_index: any) =>
                                      item.hasOwnProperty("countries") &&
                                        item.countries.length > 0 ? (
                                        item.countries.map(
                                          (
                                            country: any,
                                            country_index: any
                                          ) =>
                                            country.hasOwnProperty(
                                              "states"
                                            ) && country.states.length > 0 ? (
                                              country.states.map(
                                                (
                                                  state: any,
                                                  state_index: any
                                                ) =>
                                                  state.hasOwnProperty(
                                                    "type"
                                                  ) &&
                                                    state.type.length > 0 ? (
                                                    state.type.map(
                                                      (
                                                        type: any,
                                                        type_index: any
                                                      ) =>
                                                        type.hasOwnProperty(
                                                          "mrp"
                                                        ) &&
                                                          type.mrp.length >
                                                          0 ? (
                                                          type.mrp.map(
                                                            (
                                                              mrp: any,
                                                              mrp_index: any
                                                            ) => (
                                                              <tr>
                                                                <>
                                                                  {spec_index ==
                                                                    0 &&
                                                                    item_index ==
                                                                    0 &&
                                                                    country_index ==
                                                                    0 &&
                                                                    state_index ==
                                                                    0 &&
                                                                    type_index ==
                                                                    0 &&
                                                                    mrp_index ==
                                                                    0 ? (
                                                                    <td
                                                                      className="td-prod  ps-3 bg-fff"
                                                                      rowSpan={
                                                                        spec.rowspan
                                                                      }
                                                                    >
                                                                      {spec.name}
                                                                    </td>
                                                                  ) : null}

                                                                  {country_index ==
                                                                    0 &&
                                                                    state_index ==
                                                                    0 &&
                                                                    type_index ==
                                                                    0 &&
                                                                    mrp_index ==
                                                                    0 ? (
                                                                    <td
                                                                      rowSpan={
                                                                        item.rowspan
                                                                      }
                                                                    >
                                                                      {
                                                                        item.name
                                                                      }
                                                                    </td>
                                                                  ) : null}

                                                                  {state_index ==
                                                                    0 &&
                                                                    type_index ==
                                                                    0 &&
                                                                    mrp_index ==
                                                                    0 ? (
                                                                    <td
                                                                      rowSpan={
                                                                        country.rowspan
                                                                      }
                                                                    >
                                                                      {
                                                                        country.name
                                                                      }
                                                                    </td>
                                                                  ) : null}

                                                                  {/* state name */}
                                                                  {type_index ==
                                                                    0 &&
                                                                    mrp_index ==
                                                                    0 ? (
                                                                    <td
                                                                      rowSpan={
                                                                        state.rowspan
                                                                      }
                                                                    >
                                                                      {
                                                                        state.name
                                                                      }
                                                                    </td>
                                                                  ) : null}

                                                                  {/* ) : null} */}

                                                                  {type_index ==
                                                                    0 &&
                                                                    mrp_index ==
                                                                    0 ? (
                                                                    <td
                                                                      rowSpan={
                                                                        type.rowspan
                                                                      }
                                                                    >
                                                                      {
                                                                        type.name
                                                                      }
                                                                    </td>
                                                                  ) : null}

                                                                  {/* {mrp_index ==
                                                                      0 ? ( */}
                                                                  <td
                                                                    rowSpan={
                                                                      mrp.rowspan
                                                                    }
                                                                  >
                                                                    {mrp.name}
                                                                  </td>
                                                                  {/* ) : null} */}

                                                                  <td
                                                                    rowSpan={
                                                                      mrp.rowspan
                                                                    }
                                                                  >
                                                                    {mrp.name}
                                                                  </td>
                                                                </>
                                                                {mrp.hasOwnProperty(
                                                                  "outlets"
                                                                ) &&
                                                                  mrp.outlets
                                                                    .length > 0
                                                                  ? mrp.outlets.map(
                                                                    (
                                                                      outlet: any,
                                                                      outlet_index: any
                                                                    ) => (
                                                                      <>
                                                                        {price_for.value ==
                                                                          "outlet" ? (
                                                                          <td >
                                                                            {outlet.enabled ==
                                                                              true ? (
                                                                              <NewCell
                                                                                from={
                                                                                  "outlet"
                                                                                }
                                                                                row_data={
                                                                                  ele
                                                                                }
                                                                                outlet_data={
                                                                                  outlet
                                                                                }
                                                                                outlet_index={
                                                                                  outlet_index
                                                                                }
                                                                                mrp_data={
                                                                                  mrp
                                                                                }
                                                                                selected_field_option={
                                                                                  selected_field_option
                                                                                }
                                                                                currencies_option={
                                                                                  currencies_option
                                                                                }
                                                                                value_data={get_new_cell_value(
                                                                                  {
                                                                                    outlet_id:
                                                                                      outlet.id,
                                                                                    addon_group:
                                                                                      outlet.addon_groups,
                                                                                    addon_item:
                                                                                      outlet.addon_item,
                                                                                    specification:
                                                                                      outlet.specification_item,
                                                                                    mrp: outlet.mrp_id,
                                                                                  },
                                                                                  "outlet",
                                                                                  {
                                                                                    outlet_data:
                                                                                      outlet,
                                                                                    outlet_index:
                                                                                      outlet_index,
                                                                                    value_data:
                                                                                      mrp,
                                                                                  }
                                                                                )}
                                                                                on_value_change={(
                                                                                  data_value
                                                                                ) => {
                                                                                  data_value.o_ele =
                                                                                    outlet;
                                                                                  console.log(
                                                                                    "on_value_change data_value :",
                                                                                    data_value
                                                                                  );
                                                                                  value_on_change_outlet(
                                                                                    data_value
                                                                                  );
                                                                                }}
                                                                                on_view_full_data={(
                                                                                  data_value
                                                                                ) => {
                                                                                  console.log(
                                                                                    "on_view_full_data data_value :",
                                                                                    data_value
                                                                                  );
                                                                                  console.log(
                                                                                    "save_price_dependent_data :",
                                                                                    save_price_dependent_data
                                                                                  );
                                                                                  console.log(
                                                                                    "check ;",
                                                                                    {
                                                                                      outlet_id:
                                                                                        outlet.id,
                                                                                      addon_group:
                                                                                        outlet.addon_groups,
                                                                                      addon_item:
                                                                                        outlet.addon_item,
                                                                                      specification:
                                                                                        outlet.specification_item,
                                                                                      mrp: outlet.mrp_id,
                                                                                    }
                                                                                  );
                                                                                  let is_found_index_check =
                                                                                    found_index(
                                                                                      save_price_dependent_data,
                                                                                      {
                                                                                        outlet_id:
                                                                                          outlet.id,
                                                                                        addon_group:
                                                                                          outlet.addon_groups,
                                                                                        addon_item:
                                                                                          outlet.addon_item,
                                                                                        specification:
                                                                                          outlet.specification_item,
                                                                                        mrp: outlet.mrp_id,
                                                                                      },
                                                                                      "outlet"
                                                                                    );
                                                                                  let is_found_new_index_check =
                                                                                    found_index(
                                                                                      new_save_price_dependent_data,
                                                                                      {
                                                                                        outlet_id:
                                                                                          outlet.id,
                                                                                        addon_group:
                                                                                          outlet.addon_groups,
                                                                                        addon_item:
                                                                                          outlet.addon_item,
                                                                                        specification:
                                                                                          outlet.specification_item,
                                                                                        mrp: outlet.mrp_id,
                                                                                      },
                                                                                      "outlet"
                                                                                    );
                                                                                  if (
                                                                                    width <
                                                                                    768
                                                                                  ) {
                                                                                    slide_drawer(
                                                                                      "bottom",
                                                                                      true,
                                                                                      event
                                                                                    );
                                                                                  } else {
                                                                                    slide_drawer(
                                                                                      "right",
                                                                                      true,
                                                                                      event
                                                                                    );
                                                                                  }
                                                                                  // SetSideDrawer(
                                                                                  //   {
                                                                                  //     type: "information",
                                                                                  //     from: "outlet",
                                                                                  //     index:
                                                                                  //       is_found_index_check,
                                                                                  //     new_index:
                                                                                  //       is_found_new_index_check,
                                                                                  //     row_data:
                                                                                  //       {
                                                                                  //         outlet_id:
                                                                                  //           outlet.id,
                                                                                  //         addon_group:
                                                                                  //           outlet.addon_groups,
                                                                                  //         addon_item:
                                                                                  //           outlet.addon_item,
                                                                                  //         specification:
                                                                                  //           outlet.specification_item,
                                                                                  //         mrp: outlet.mrp_id,
                                                                                  //       },
                                                                                  //     varriant_data:
                                                                                  //       ele,
                                                                                  //     outlet_data:
                                                                                  //       outlet,
                                                                                  //   }
                                                                                  // );
                                                                                  let pass_value =
                                                                                  {
                                                                                    type: "information",
                                                                                    from: "outlet",
                                                                                    mrp_value:
                                                                                      value,
                                                                                    index:
                                                                                      is_found_index_check,
                                                                                    new_index:
                                                                                      is_found_new_index_check,
                                                                                    row_data:
                                                                                    {
                                                                                      outlet_id:
                                                                                        outlet.id,
                                                                                      addon_group:
                                                                                        outlet.addon_groups,
                                                                                      addon_item:
                                                                                        outlet.addon_item,
                                                                                      specification:
                                                                                        outlet.specification_item,
                                                                                      mrp: outlet.mrp_id,
                                                                                      selected_currency:
                                                                                        currencies_option[0],
                                                                                      price:
                                                                                      {
                                                                                        sellers_price:
                                                                                          "",
                                                                                        currency_id:
                                                                                          currencies_option[0]
                                                                                            .id,
                                                                                      },
                                                                                    },
                                                                                    varriant_data:
                                                                                      ele,
                                                                                    outlet_data:
                                                                                      outlet,
                                                                                  };
                                                                                  if (
                                                                                    is_found_index_check !=
                                                                                    -1
                                                                                  ) {
                                                                                    pass_value.row_data.selected_currency =
                                                                                      save_price_dependent_data[
                                                                                        is_found_index_check
                                                                                      ].selected_currency;
                                                                                    pass_value.row_data.price =
                                                                                      save_price_dependent_data[
                                                                                        is_found_index_check
                                                                                      ].price;
                                                                                  } else {
                                                                                    pass_value.row_data.selected_currency =
                                                                                      currencies_option[0];
                                                                                    pass_value.row_data.price =
                                                                                    {
                                                                                      sellers_price:
                                                                                        "",
                                                                                      currency_id:
                                                                                        currencies_option[0]
                                                                                          .id,
                                                                                    };
                                                                                  }
                                                                                  console.log(
                                                                                    "on full dependent :",
                                                                                    pass_value
                                                                                  );
                                                                                  SetNewSideDrawerData(
                                                                                    pass_value
                                                                                  );
                                                                                }}
                                                                              />
                                                                            ) : null}
                                                                          </td>
                                                                        ) : null}

                                                                        {/* {price_for.value ==
                                                                            "channel"
                                                                              ? outlet.hasOwnProperty(
                                                                                  "channels"
                                                                                )
                                                                                ? outlet.channels.map(
                                                                                    (
                                                                                      c_ele: any,
                                                                                      c_index: any
                                                                                    ) => (
                                                                                      <td>
                                                                                        <NewCell
                                                                                          from={
                                                                                            "channel"
                                                                                          }
                                                                                          row_data={
                                                                                            ele
                                                                                          }
                                                                                          outlet_data={
                                                                                            outlet
                                                                                          }
                                                                                          outlet_index={
                                                                                            outlet_index
                                                                                          }
                                                                                          channel_data={
                                                                                            c_ele
                                                                                          }
                                                                                          channel_index={
                                                                                            c_index
                                                                                          }
                                                                                          mrp_data={
                                                                                            mrp
                                                                                          }
                                                                                          selected_field_option={
                                                                                            selected_field_option
                                                                                          }
                                                                                          currencies_option={
                                                                                            currencies_option
                                                                                          }
                                                                                          seller_price_value_channel={
                                                                                            ""
                                                                                          }
                                                                                          on_seller_price_change_channel={
                                                                                            ""
                                                                                          }
                                                                                          seller_type_value_channel={
                                                                                            ""
                                                                                          }
                                                                                          on_seller_type_change_channel={
                                                                                            ""
                                                                                          }
                                                                                          min_price_value_channel={
                                                                                            ""
                                                                                          }
                                                                                          on_min_price_change_channel={
                                                                                            ""
                                                                                          }
                                                                                          max_price_value_channel={
                                                                                            ""
                                                                                          }
                                                                                          on_max_price_change_channel={
                                                                                            ""
                                                                                          }
                                                                                          fixed_price_value_channel={
                                                                                            ""
                                                                                          }
                                                                                          on_fixed_price_change_channel={
                                                                                            ""
                                                                                          }
                                                                                          currency_value_channel={
                                                                                            ""
                                                                                          }
                                                                                          on_currency_change_channel={
                                                                                            ""
                                                                                          }
                                                                                          on_view_full_data={() => {}}
                                                                                        />
                                                                                      </td>
                                                                                    )
                                                                                  )
                                                                                : null
                                                                              : null} */}
                                                                      </>
                                                                    )
                                                                  )
                                                                  : null}
                                                              </tr>
                                                            )
                                                          )
                                                        ) : (
                                                          // no mrp
                                                          <tr>
                                                            {item_index ==
                                                              0 &&
                                                              country_index ==
                                                              0 &&
                                                              state_index ==
                                                              0 &&
                                                              type_index ==
                                                              0 ? (
                                                              <td className="td-prod  ps-3 bg-fff"
                                                                rowSpan={
                                                                  spec.rowspan
                                                                }
                                                              >
                                                                {spec.name}
                                                              </td>
                                                            ) : null}

                                                            {country_index ==
                                                              0 &&
                                                              state_index ==
                                                              0 &&
                                                              type_index ==
                                                              0 ? (
                                                              <td
                                                                rowSpan={
                                                                  item.rowspan
                                                                }
                                                              >
                                                                {item.name}
                                                              </td>
                                                            ) : null}

                                                            {state_index ==
                                                              0 &&
                                                              type_index ==
                                                              0 ? (
                                                              <td
                                                                rowSpan={
                                                                  country.rowspan
                                                                }
                                                              >
                                                                {country.name}
                                                              </td>
                                                            ) : null}

                                                            {type_index ==
                                                              0 ? (
                                                              <td
                                                                rowSpan={
                                                                  state.rowspan
                                                                }
                                                              >
                                                                {state.name}
                                                              </td>
                                                            ) : null}

                                                            <td
                                                              rowSpan={
                                                                type.rowspan
                                                              }
                                                            >
                                                              {type.name}
                                                            </td>

                                                            <td
                                                              rowSpan={
                                                                type.rowspan
                                                              }
                                                            >
                                                              {type.name}
                                                            </td>
                                                          </tr>
                                                        )
                                                    )
                                                  ) : (
                                                    // no type
                                                    <tr>
                                                      {item_index == 0 &&
                                                        country_index == 0 &&
                                                        state_index == 0 ? (
                                                        <td className="td-prod  ps-3 bg-fff"
                                                          rowSpan={
                                                            spec.rowspan
                                                          }
                                                        >
                                                          {spec.name}
                                                        </td>
                                                      ) : null}

                                                      {country_index == 0 &&
                                                        state_index == 0 ? (
                                                        <td
                                                          rowSpan={
                                                            item.rowspan
                                                          }
                                                        >
                                                          {item.name}
                                                        </td>
                                                      ) : null}

                                                      {state_index == 0 ? (
                                                        <td rowSpan={country.rowspan}>
                                                          {country.name}
                                                        </td>
                                                      ) : null}

                                                      <td rowSpan={state.rowspan}>
                                                        {state.name}
                                                      </td>
                                                    </tr>
                                                  )
                                              )
                                            ) : (
                                              // no state
                                              <tr>
                                                {item_index == 0 &&
                                                  country_index == 0 ? (
                                                  <td className="td-prod  ps-3 bg-fff" rowSpan={spec.rowspan}>
                                                    {console.log(
                                                      "country spec.rowspan :",
                                                      spec.rowspan
                                                    )}
                                                    {spec.name}
                                                  </td>
                                                ) : null}

                                                {country_index == 0 ? (
                                                  <td rowSpan={item.rowspan}>
                                                    {item.name}
                                                  </td>
                                                ) : null}

                                                <td rowSpan={country.rowspan}>
                                                  {country.name}
                                                </td>
                                              </tr>
                                            )
                                        )
                                      ) : (
                                        // no countries
                                        <tr>
                                          {item_index == 0 ? (
                                            <>
                                              <td className="td-prod ps-3 bg-fff" rowSpan={spec.rowspan}>
                                                {spec.name}
                                              </td>
                                              <td rowSpan={item.rowspan}>
                                                {item.name}
                                              </td>
                                            </>
                                          ) : (
                                            <td rowSpan={item.rowspan}>
                                              {item.name}
                                            </td>
                                          )}
                                        </tr>
                                      )
                                  )
                                ) : (
                                  // no item
                                  <tr>
                                    <td className="td-prod ps-3 bg-fff" rowSpan={spec.rowspan}>
                                      {spec.name}
                                    </td>
                                  </tr>
                                  // null
                                )
                            )
                            : null}
                        </>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="center p-3 bg-fff">
              <div>
                <img src={require("../../assets/icon/empty.png")} className="empty-img" />
                <p className="text-center">No data found!</p>
              </div>
            </div>
          )}

          {table_data.length != 0 ? (
            <div className="w-100 text-end mt-3">
              <button
                className="btn bg-prime px-4 btn-sm btn-radius text-white fw-bold"
                onClick={() => {
                  save_product_pricing(selected_country, page_no);
                }}
              >
                Save
              </button>
            </div>
          ) : null}
        </div>
      </div>

      {["left", "right", "top", "bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={(event) => {
              SetNewSideDrawerData({});
              slide_drawer(anchor, false, event);
            }}
            onOpen={(event) => {
              slide_drawer(anchor, true, event);
            }}
          >
            {new_side_drawer_data.type == "information" ? (
              <div className="">
                <div className="mt-3 scrollbar-none mb-5">
                  {/* header */}
                  <div
                    className="d-flex align-items-center shadow-bottom pb-3 cursor px-3 end">
                    <h6 className="mb-0 fs-5 fw-500">Filter</h6>
                    <div className="ms-auto px-2"
                      onClick={(event) => {
                        SetNewSideDrawerData({});
                        slide_drawer(anchor, false, event);
                      }}>
                      <Ionicons name="close" size={20} color="Blackl" />
                    </div>
                    {/* <p className="mb-0 ps-1">Close</p> */}
                  </div>
                  {/* body */}
                  <div>
                    {/* sellers price */}
                    <div className="m-3">
                      <label>Sellers Price</label>
                      <input
                        className="form-control"
                        type="number"
                        value={
                          new_side_drawer_data.row_data.price.sellers_price
                        }
                        onChange={(e) => {
                          SetNewSideDrawerData((prevValue: any) => {
                            prevValue.row_data.price.sellers_price =
                              e.target.value;
                            return { ...prevValue };
                          });
                        }}
                      />
                    </div>
                    {/* currency */}
                    <div className="m-3">
                      <label>Currency</label>
                      <Select
                        className="Price"
                        options={currencies_option}
                        value={new_side_drawer_data.row_data.selected_currency}
                        onChange={(e) => {
                          console.log("currency :", e);
                          SetNewSideDrawerData((prevValue: any) => {
                            prevValue.row_data.selected_currency = e;
                            prevValue.row_data.price.currency_id = e.id;
                            return { ...prevValue };
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* Save Button */}
                <div className="pt-5 mt-2">
                  <div
                    className="text-center p-3 cursor bottom bg-prime fw-bold text-white"
                    onClick={(event) => {
                      slide_drawer(anchor, false, event);
                      let send_data = {
                        index: new_side_drawer_data.index,
                        new_index: new_side_drawer_data.new_index,
                        from: new_side_drawer_data.from,
                        row_data: new_side_drawer_data.row_data,
                      };
                      on_information_change(send_data);
                      // SetNewSideDrawerData({});
                    }}
                  >
                    OK
                  </div>
                </div>
              </div>
            ) : null}

            {new_side_drawer_data.type == "filter" ? (
              <div className="">
                <div className="p-3 bg-fff d-flex align-items-center sticky-top border-bottom">
                  <h6 className="mb-0">Filter</h6>
                  <div className="ms-auto cursor" onClick={(event) => {
                    slide_drawer(anchor, false, event);
                  }}>
                    <Ionicons name="ios-close" size={24} color="black" />
                  </div>
                </div>
                <div className="px-2">
                  <div className="px-2 pb-5 mb-5">
                    {/* Set price to */}
                    <div className="mt-3 pe-0 sm-mb-2">
                      <label className="form-label mb-0">Set price to</label>
                      <div className="mt-2">
                        <Select
                          options={pricingOptions}
                          defaultValue={pricingOptions[0]}
                          value={price_for}
                          onChange={(e) => {
                            console.log("selected_type :", e.value);
                            SetPriceFor(e);

                            let filter_value = {
                              dependent: is_dependent ? 2 : 1,
                              selected_country: selected_country,
                              outlet_data: selected_outlet_data,
                              channel_data: selected_channel_data,
                              price_for: e,
                            };
                            get_addon_item(filter_value);
                          }}
                        />
                      </div>
                    </div>
                    {/* Set Dependent */}
                    {/* <div className="col-md-12 p-2">
                    <div className="row align-items-center">
                      <div className="col-md-3 sm-mb-2">
                        <label className="form-label mb-0">Independent</label>
                      </div>

                      <div className="col-md-1 form-check form-switch">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          checked={is_dependent}
                          onChange={(event) => {
                            // onChange(rowData, props, event);
                            console.log("event on change :", event);
                            SetIsDependent(event.target.checked);
                            let filter_value = {
                              dependent: event.target.checked ? 2 : 1,
                              selected_country: selected_country,
                              outlet_data: selected_outlet_data,
                              channel_data: selected_channel_data,
                              price_for: price_for,
                            };
                            get_addon_item(filter_value);
                          }}
                        />
                      </div>
                      <div className="col-md-2 pe-0 sm-mb-2">
                        <label className="form-label mb-0">Dependent</label>
                      </div>
                    </div>
                  </div> */}
                    {/* Select Field */}
                    <div className="mt-3 pe-0 sm-mb-2">
                      <label className="form-label mb-0">Select Field</label>
                      <div className="mt-2">
                        <Select
                          className="Price"
                          options={field_option}
                          value={selected_field_option}
                          onChange={(e) => {
                            console.log("selling Field :", e.value);
                            // SetSelectedSellingType(e);
                            SetSelectedFieldOption(e);
                            // selected_field_option = e;
                          }}
                        />
                      </div>
                    </div>
                    {/* Select Outlets */}
                    <div className="mt-3 pe-0 sm-mb-2">
                      <label className="form-label mb-0">Select Outlet</label>
                      <div className="mt-2 p-0">
                        <Select
                          className="Price"
                          options={outlet_data}
                          value={selected_outlet_data}
                          isMulti
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(selected_data) => {
                            console.log("selected_outlets :", selected_data);
                            SetSelectedOutletData(selected_data);
                            let filter_value = {
                              dependent: is_dependent ? 2 : 1,
                              selected_country: selected_country,
                              outlet_data: selected_data,
                              channel_data: selected_channel_data,
                              price_for: price_for,
                            };
                            get_addon_item(filter_value);
                          }}
                        />
                      </div>
                    </div>
                    {/* Select Channels */}
                    {price_for.value == "channel" ? (
                      <div className="mt-3 pe-0 sm-mb-2">
                        <label className="form-label mb-0">
                          Select Channels
                        </label>
                        <div className="mt-2">
                          <Select
                            className="Price"
                            options={channel_data}
                            value={selected_channel_data}
                            isMulti
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={(selected_data) => {
                              console.log("selected_channels :", selected_data);
                              SetSelectedChannelData(selected_data);
                              let filter_value = {
                                dependent: is_dependent ? 2 : 1,
                                selected_country: selected_country,
                                outlet_data: selected_outlet_data,
                                channel_data: selected_data,
                                price_for: price_for,
                              };
                              get_addon_item(filter_value);
                            }}
                          />
                        </div>
                      </div>
                    ) : null}
                    {/* Select Country */}
                    <div className="mt-3 pe-0 sm-mb-2">
                      <label className="form-label mb-0">
                        Select Country
                      </label>
                      <div className="mt-2 p-0">
                        <Select
                          className="Price"
                          options={country_option}
                          value={selected_country}
                          isMulti
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={(selected_data) => {
                            console.log("selected_channels :", selected_data);
                            SetSelectedCountry(selected_data);
                            let filter_value = {
                              dependent: is_dependent ? 2 : 1,
                              selected_country: selected_data,
                              outlet_data: selected_outlet_data,
                              channel_data: selected_channel_data,
                              price_for: price_for,
                            };
                            get_addon_item(filter_value);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  {/* Save Button */}
                  <div className={width < 786 ? "pt-5 mt-2 bottom-fix w-100 right-0 left-0" : "pt-5 mt-2 bottom-fix w-30 right-0"}>
                    <div
                      className="text-center p-3 cursor bottom bg-prime fw-bold text-white"
                      onClick={(event) => {
                        slide_drawer(anchor, false, event);
                      }}
                    >
                      OK
                    </div>
                  </div>
                </div>
              </div>

            ) : null}
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
