import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { api } from "../../../utils/Api";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { Map, TileLayer } from "react-leaflet";
import { AppHelper } from "../../../utils/Helper";
import Skeleton from "react-loading-skeleton";

import ModalEditOutlet from "./ModalEditOutlet";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import DeleteAlert from "../../../components/Alert/DeleteAlert";
import ModalDeleteOutlet from "./ModalDeleteOutlet";
import {
  AntDesign,
  Entypo,
  Feather,
  FontAwesome,
  Fontisto,
  Ionicons,
  MaterialCommunityIcons,
  MaterialIcons,
  SimpleLineIcons,
} from "@expo/vector-icons";
import { AppConfig } from "../../../config";
// import "bootstrap/dist/css/bootstrap.min.css"; 
// import "@popperjs/core"; 
// import "bootstrap";

const table_config = {
  key_column: "id",
  page_size: 10,
  length_menu: [10, 20, 50],
  show_filter: true,
  show_pagination: true,
  button: {
    excel: false,
    print: false,
  },
  language: {
    loading_text: "Please be patient while data loads...",
  },
};

const table_header = [
  {
    name: "Name",
    width: "30",
  },
  {
    name: "Address",
    width: "30",
  },
  {
    name: "Mobile Number",
    width: "30",
  },
  {
    name: "",
    width: "10",
  },
];

export default function BrandOutlet(props: any) {
  const context = useContext(DataContext);
  const [is_loading, SetIsLoading] = useState(false);

  const OutletContext = useOutletContext();
  const [id_value, SerIdValue] = useState(useParams());
  const [outlet_data, SetOutletData] = useState([]);
  const [outer_data, SetOuterData] = useState([]);

  const [modal_outlet, SetModalOutlet] = useState({
    is_open: false,
    data: {},
  });
  const [edit_outlet_modal, SetEditOutletModal] = useState({
    is_open: false,
    data: {},
    index: "",
  });
  const [state, setState] = React.useState({
    bottom: false,
  });
  const [selected_data, SetSelectedData] = useState({
    value: "",
    id: "",
  });
  // const [delete_modal, SetDeleteModal] = useState({
  //   is_open: false,
  //   title: "",
  //   record: {},
  //   index: "",
  // });

  const [create_outlet_modal, SetCreateOutletModal] = useState({
    is_open: false,
    data: {},
    type: "",
  });
  const [width, SetWidth] = useState(innerWidth);
  const [deleteModal, SetDeleteModal] = useState({
    is_open: false,
    id: "",
  });

  function swipe_fun(anchor, open, event) {
    console.log("swipe_fun anchor :", anchor);
    console.log("swipe_fun open :", open);
    console.log("swipe_fun event :", event);
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      console.log("return");
      return;
    }
    console.log("toggle else");

    setState({ ...state, [anchor]: open });
    // toggleDrawer("bottom", true);
  }

  const list = (anchor) => (
    <div
      className="mt-3"
      // className={ anchor === 'top' || anchor === 'bottom'}
      // role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <div>
        <div className="d-flex justify-content-center">
          <div className="w-25 px-4">
            <hr className="h-3" />
          </div>
        </div>
        <ul>
          {/* view */}
          <li className="p-2 pb-3 cursor align-center">
            <Link
              className="cursor text-dark"
              to={
                "/brand/" +
                id_value.brand_id +
                "/view_outlet/" +
                selected_data.value.id +
                "/details"
              }
            >
              <img
                src={require("../../../assets/icon/svg/eye.svg")}
                className="action_menu_img cursor"
              />
              &nbsp; View
            </Link>
          </li>
          {/* Edit */}
          {/* <li
            className="p-2 pb-3 cursor align-center"
            onClick={() => {
              SetEditOutletModal({
                is_open: true,
                data: selected_data.value,
                index: selected_data.i,
              });
              console.log("Edit");
              swipe_fun(anchor, false, event);
            }}
          >
            <img
              src={require("../../../assets/icon/svg/edit-3.svg")}
              className="me-2 action_menu_img cursor"
            />
            Edit
          </li> */}
          {/* Delete */}
          {/* <li
            className="p-2 pb-3 cursor align-center"
            onClick={(event) => {
              delete_outlet(selected_data.i, selected_data.value);
              swipe_fun(anchor, false, event);
            }}
          >
            <img
              src={require("../../../assets/icon/svg/trash-2.svg")}
              className="me-2 action_menu_img cursor"
            />
            Delete
          </li> */}
        </ul>
      </div>
    </div>
  );

  useEffect(() => {
    console.log("AdminOutlet On mount :", props);
    console.log("AdminOutlet context :", context);
    get_outlets();
  }, []);

  async function get_outlets() {
    SetIsLoading(true)
    let pass_data: any = {
      get: {
        brand: id_value.brand_id,
      },
    };

    if (props.hasOwnProperty("franchise_id")) {
      pass_data.get.franchise = props.franchise_id;
    }

    let response = await api("/brand/outlets", pass_data);
    console.log("/brand/outlets response :", response);
    if (response.status_code == 200) {
      SetOuterData(response.response);
      if (response.response.hasOwnProperty("outlets")) {
        SetOutletData(response.response.outlets);
      } else {
        SetOutletData([]);
      }
    SetIsLoading(false)

    }
  }

  async function delete_outlet(data: any, index: any) {
    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        outlet_id: data.id,
      },
    };
    let response = await api("/brand/delete_outlet", pass_data);
    console.log("/brand/delete_outlet response :", response);
    if (response.status_code == 200) {
      let old_outlet_data = outlet_data;
      old_outlet_data.splice(index, 1);
      SetOutletData([...old_outlet_data]);
      // get_outlets();
    }
  }

  function openMap(dir_lat: any, dir_lng: any) {
    window.open(
      "https://www.google.com/maps/search/?api=1&query=" +
        dir_lat +
        "," +
        dir_lng
    );
  }

  return (
    <div>
      <div className={props.have_header ? "" : "card p-2"}>
        <div
          className={
            props.have_header
              ? OutletContext
                ? "head-bar sticky-sm"
                : "head-bar  sticky-sm"
              : "p-2 border-bottom pb-3"
          }
          style={
            props.have_header == true
              ? OutletContext == false
                ? { width: width }
                : {}
              : null
          }
        >
          <div className="row align-items-center">
            <div className="col-6 mb-sm-1 mb-md-0">
              <h6 className="mb-0">Outlets</h6>
            </div>

            <div className="text-end col-6 ps-0 mb-sm-1 mb-md-0 pe-2">
              <Link
                className="cursor text-dark"
                to={
                  "/brand/" +
                  id_value.brand_id +
                  "/create_brand_outlet"
                }
              >
                <div
                  className="btn bg-prime text-white btn-radius px-3 py-1 text-white"
                  // onClick={() => {
                  //   console.log("Outlet Add");
                  //   SetCreateOutletModal((oldValues) => ({
                  //     ...oldValues,
                  //     is_open: true,
                  //   }));
                  // }}
                >
                  Create
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div
          className={
            props.have_header
              ? OutletContext
                ? "mt-3 px-md-2 px-sm-0"
                : "mt-3 px-md-1 px-sm-0"
              : "my-4"
          }
          // style={OutletContext == false ? { width: width } : {}}
        >
          <div className="px-0">
          {is_loading?
              <div className="row">

         { Array.from({ length: 6 }).map((_, i) => (
            <div key={i} className="col-md-6 col-lg-4 px-md-1 mb-3">
              <div className="card p-3">
                <div className="text-center">
                  <div className="center pb-3">
                    <Skeleton circle={true} height={60} width={60} className="outlet-image" />
                  </div>
                  <p className="pop-font fs-7 fw-bold text-dark">
                    <Skeleton width={100} />
                  </p>
                  <p className="small lowercase">
                    <Skeleton width={80} />
                  </p>
                  <div className="row pt-2">
                    <div className="col-6 border-right">
                      <Skeleton height={20} width={50} />
                    </div>
                    <div className="col-6">
                      <Skeleton height={20} width={50} />
                    </div>
                  </div>
                  <div className="py-3 dropdown menu">
                    <Skeleton width={60} height={30} />
                  </div>
                  <div className="row p-0">
                    <div className="col-6 p-0 text-center border-right">
                      <Skeleton height={20} width={60} />
                    </div>
                    <div className="col-6 p-0 text-center">
                      <Skeleton height={20} width={60} />
                    </div>
                  </div>
                  <div className="pt-3">
                    <Skeleton height={200} />
                  </div>
                </div>
              </div>
            </div>
          ))} </div>:
            outlet_data.length > 0 ? (
              <div className="row">
              
                {outlet_data?.map((data: any, o_index: any) => (
                  <div className="col-md-6 col-lg-4 px-md-1 mb-3">
                    <div className="card p-3">
                      <div className="text-center">
                        <div className="center pb-3">
                          {data.image != null ? (
                            <img
                              // src={"https://cdn.1superapp.com/images/320/" + data.image}

                              src={AppHelper.imageSrc(
                                outer_data.image_base_url,
                                data.image,
                                { format: "webp", width: 100 }
                              )}
                              className="outlet-image"
                            />
                          ) : (
                            <img
                              src={
                                AppConfig.CDN_Image_url +
                                "beb6e59fdba49c15dfd0fd4f2a341ca0.png"
                              }
                              className="outlet-image"
                            />
                          )}
                        </div>
                        <p className="pop-font fs-7 fw-bold text-dark ">
                          {data.name}
                        </p>
                        <p className="small lowercase">
                          created @ {data.created_user}
                        </p>
                        {data.website != "" || data.email != "" ? (
                          <div className="row pt-2">
                            {data.website != "" ? (
                              <div
                                className={
                                  data.email != ""
                                    ? "end col-6 border-right"
                                    : "center "
                                }
                              >
                                <img
                                  src={
                                    AppConfig.CDN_Media_url +
                                    "99e31c8706f3afc6bfcd17ebe5348ca9.svg"
                                  }
                                  className="w-10px"
                                />
                                {/* <Fontisto name="world-o" size={10} color="#aaa" /> */}
                                <p className="ps-1 text-gray extra-small pop-font">
                                  {data.website}
                                </p>
                              </div>
                            ) : null}
                            {data.email != "" ? (
                              <div
                                className={
                                  data.website != ""
                                    ? "d-flex align-items-center col-6 "
                                    : "center "
                                }
                              >
                                <img
                                  src={
                                    AppConfig.CDN_Media_url +
                                    "16caa78546f21f9ec62b44b82f0def04.svg"
                                  }
                                  className="w-10px"
                                />
                                {/* <Ionicons
                          name="md-mail-outline"
                          size={10}
                          color="#aaa" 
                        />*/}
                                <p className="ps-1 text-gray extra-small pop-font">
                                  {data.email}
                                </p>
                              </div>
                            ) : null}
                          </div>
                        ) : null}

                        <div className="py-3 dropdown menu">
                          <button
                            className="btn btn-white btn-sm  dropdown-toggle"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Menu
                          </button>

                          <ul className="dropdown-menu  dropdown-menu-start">
                            <Link
                              className="cursor text-dark"
                              to={
                                "/brand/" +
                                id_value.brand_id +
                                "/view_outlet/" +
                                data.id +
                                "/details"
                              }
                            >
                              <li className="py-2 cursor">
                                <div className="dropdown-item d-flex align-items-center">
                                  {/* <AntDesign name="edit" size={18} color="#555" /> */}

                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "e6f750b5e32317c84dfdf4b14930bda7.svg"
                                    }
                                    className="w-14px"
                                  />
                                  <p className="ps-2 text-black">View</p>
                                </div>
                              </li>
                            </Link>
                            <Link
                              className="cursor text-dark"
                              to={
                                "/brand/" +
                                id_value.brand_id +
                                "/available_timing/" +
                                data.id
                              }
                            >
                              <li className="py-2 cursor">
                                <div className="dropdown-item d-flex align-items-center">
                                  {/* <AntDesign name="edit" size={18} color="#555" /> */}
                                  {/* <img
                                    src={require("../../../assets/icon/svg/clock1.svg")}
                                    className="action_menu_img cursor"
                                  /> */}
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "466b661e0e1debd7f0c9f5ed2e8efc62.svg"
                                    }
                                    className="w-14px"
                                  />
                                  <p className="ps-2 text-black">
                                    Available Timing
                                  </p>
                                </div>
                              </li>
                            </Link>
                            <Link
                              className="cursor text-dark "
                              to={
                                "/brand/" +
                                id_value.brand_id +
                                "/edit_brand_outlet/" +
                                data.id
                              }
                            >
                              <li
                                className="py-2 cursor"
                                // onClick={(event) => {
                                //   let set_data = {
                                //     value: data,

                                //   };
                                //   SetEditOutletModal({
                                //     is_open: true,
                                //     index: o_index,

                                //     data: {
                                //       name: data.name,
                                //       address: data.address,
                                //       id: data.id,
                                //       mobile: data.mobile,
                                //       email: data.email,
                                //       website: data.website,
                                //       whatsapp: data.whatsapp,
                                //       country_id: data.country_id,
                                //       country_name: data.country_name,
                                //       state_id: data.state_id,
                                //       state_name: data.state_name,
                                //       door_no: data.door_no,
                                //       city: data.city,
                                //       pincode: data.pincode,
                                //     },
                                //   });
                                //   console.log("Edit");
                                // }}
                              >
                                <div className="dropdown-item d-flex align-items-center">
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "9503435d1485f430f8d16efbf1b0ed0d.svg"
                                    }
                                    className="w-14px"
                                  />

                                  {/* <AntDesign
                                    name="edit"
                                    size={18}
                                    color="#9c9c9d"
                                  /> */}
                                  <p className="ps-2 text-black">Edit</p>
                                </div>
                              </li>
                            </Link>

                            <li
                              className="cursor  py-2"
                              onClick={() => {
                                SetDeleteModal({
                                  is_open: true,
                                  id: data.id,
                                });
                              }}
                            >
                              <div className="dropdown-item d-flex align-items-center">
                                <img
                                  src={
                                    AppConfig.CDN_Media_url +
                                    "6a3db70f6b875c6205c5565dde75d1ec.svg"
                                  }
                                  className="w-14px"
                                />
                                {/* <MaterialIcons
                                  name="delete-outline"
                                  size={18}
                                  color="#9c9c9d"
                                /> */}
                                <p className="ps-2 text-black">Delete</p>
                              </div>
                            </li>

                            <Link
                              className="cursor text-dark"
                              //  9/20/dashboard
                              //   to={

                              //   }
                              to={
                                "/outlet/" +
                                id_value.brand_id +
                                "/" +
                                data.id +
                                "/dashboard"
                              }
                            >
                              <li className=" py-2 cursor">
                                <div className="dropdown-item d-flex align-items-center">
                                  {/* <AntDesign name="edit" size={18} color="#555" /> */}
                                  {/* <img
                                    src={require("../../../assets/icon/svg/trending-up.svg")}
                                    className="action_menu_img cursor"
                                  /> */}
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "fe43aac3268fea6945af3c96e4e71a92.svg"
                                    }
                                    className="w-14px"
                                  />
                                  <p className="ps-2 text-black">
                                    Outlet Dashboard
                                  </p>
                                </div>
                              </li>
                            </Link>

                            <Link
                              className="cursor text-dark"
                              to={
                                "/brand/" +
                                id_value.brand_id +
                                "/outlet_call_reminder/" +
                                data.id
                              }
                            >
                              <li className=" py-2 cursor">
                                <div className="dropdown-item d-flex align-items-center">
                                  {/* <img
                                    src={require("../../../assets/svg/call-reminder.svg")}
                                    className="action_menu_img cursor"
                                  /> */}
                                  <img
                                    src={
                                      AppConfig.CDN_Media_url +
                                      "a26aeb733fbeb6ec297f32ca6048591d.svg"
                                    }
                                    className="w-14px"
                                  />
                                  <p className="ps-2 text-black">
                                    Call Reminder
                                  </p>
                                </div>
                              </li>
                            </Link>
                          </ul>
                        </div>

                        {/* <div className="dropdown show">
                          <a className="btn btn-secondary dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            Dropdown link
                          </a>

                          <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <a className="dropdown-item" href="#">Action</a>
                            <a className="dropdown-item" href="#">Another action</a>
                            <a className="dropdown-item" href="#">Something else here</a>
                          </div>
                        </div> */}

                        {/* <div className="my-3 border-bottom-light-2px"></div> */}

                        <div className="row p-0">
                          {data.mobile != "" ? (
                            <div className="col-6 p-0 text-center border-right">
                              <p className="pb-1 text-black fw-bold extra-small pop-font">
                                {data.mobile}
                              </p>
                              <p className="extra-small pop-font">Mobile</p>
                            </div>
                          ) : null}

                          {data.whatsapp != "" ? (
                            <div className="col-6 p-0 text-center ">
                              <p className="pb-1 text-black fw-bold extra-small pop-font">
                                {data.whatsapp}
                              </p>
                              <p className="extra-small pop-font">Whatsapp</p>
                            </div>
                          ) : null}
                        </div>

                        <div className="pt-3" onClick={()=>{
                          openMap(data.latitude,data.longitude)
                        }}>
                          <img
                            className="outlet-map-img"
                            src={
                              "https://maps.google.com/maps/api/staticmap?center=" +
                              data.latitude +
                              "," +
                              data.longitude +
                              "&markers=size:mid%7Ccolor:red%7C" +
                              data.latitude +
                              "," +
                              data.longitude +
                              "&zoom=6&size=800x300&sensor=false&key="+AppConfig.google_map_api
                            }
                          />
                          {/* amp; */}

                          {/* <img className="img-fluid mb-3"src={"https://maps.google.com/maps/api/staticmap?center=" + data?.latitude +"," +data?.longitude + "&markers=size:mid%7Ccolor:red%7C"+ data?.latitude +"," +data?.longitude +"&zoom=15&amp;size=600x200&amp;sensor=false&amp;key="+AppConfig.google_map_api}/> */}
                        </div>
                      </div>

                      {/* <div className="row p-0">
                        <div className="col-11 ps-0">
                          <div className="d-flex align-items-center">
                            {data.image != null ? (
                              <img
                                // src={"https://cdn.1superapp.com/images/320/" + data.image}

                                src={AppHelper.imageSrc(
                                  outer_data.image_base_url,
                                  data.image,
                                  { format: "webp", width: 100 }
                                )}
                                className="outlet-image"
                              />
                            ) : (
                              <img
                                src={require("../../../assets/img/profile.png")}
                                className="outlet-image"
                              />
                            )}
                            <h6 className="text-black mb-0 oneline ms-2">
                              {data.name}
                            </h6>
                          </div>
                        </div>
                        <div className="col-1 end p-0">
                          <div className="dropdown menu cursor">
                            <div
                              className="dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <Entypo
                                name="dots-three-vertical"
                                size={18}
                                color="#777"
                              />
                            </div>

                            <ul className="dropdown-menu  dropdown-menu-start">
                              <Link
                                className="cursor text-dark"
                                to={
                                  "/brand/" +
                                  id_value.brand_id +
                                  "/view_outlet/" +
                                  data.id +
                                  "/details"
                                }
                              >
                                <li className="mb-2 cursor">
                                  <div className="dropdown-item d-flex align-items-center">
                                    {/* <AntDesign name="edit" size={18} color="#555" />/}
                                    <img
                                      src={require("../../../assets/icon/svg/eye.svg")}
                                      className="me-2 action_menu_img cursor"
                                    />
                                    <p className="ps-2 text-black">View</p>
                                  </div>
                                </li>
                              </Link>
                            <Link
                              className="cursor text-dark"
                              to={
                                "/brand/" +
                                id_value.brand_id +
                                "/available_timing/" +
                                data.id 
                              }
                            >
                              <li
                                className="mb-2 cursor"
                              >
                                <div className="dropdown-item d-flex align-items-center">
                                  {/* <AntDesign name="edit" size={18} color="#555" />/}
                                  <img
                                    src={require("../../../assets/icon/svg/clock1.svg")}
                                    className="me-2 action_menu_img cursor"
                                  />
                                  <p className="ps-2 text-black">Available Timing</p>
                                </div>
                              </li>
                            </Link>
                              <Link
                                className="cursor text-dark "
                                to={
                                  "/brand/" +
                                  id_value.brand_id +
                                  "/edit_brand_outlet/" +
                                  data.id
                                }
                              >
                                <li
                                  className="mb-2 cursor"
                                  // onClick={(event) => {
                                  //   let set_data = {
                                  //     value: data,

                                  //   };
                                  //   SetEditOutletModal({
                                  //     is_open: true,
                                  //     index: o_index,

                                  //     data: {
                                  //       name: data.name,
                                  //       address: data.address,
                                  //       id: data.id,
                                  //       mobile: data.mobile,
                                  //       email: data.email,
                                  //       website: data.website,
                                  //       whatsapp: data.whatsapp,
                                  //       country_id: data.country_id,
                                  //       country_name: data.country_name,
                                  //       state_id: data.state_id,
                                  //       state_name: data.state_name,
                                  //       door_no: data.door_no,
                                  //       city: data.city,
                                  //       pincode: data.pincode,
                                  //     },
                                  //   });
                                  //   console.log("Edit");
                                  // }}
                                >
                                  <div className="dropdown-item d-flex align-items-center py-2">
                                    <AntDesign
                                      name="edit"
                                      size={18}
                                      color="#555"
                                    />
                                    <p className="ps-2 text-black">Edit</p>
                                  </div>
                                </li>
                              </Link>

                              <li
                                className="cursor"
                                onClick={() => {
                                  SetDeleteModal({
                                    is_open: true,
                                    id: data.id,
                                  });
                                }}
                              >
                                <div className="dropdown-item d-flex align-items-center py-2">
                                  <MaterialIcons
                                    name="delete-outline"
                                    size={18}
                                    color="#555"
                                  />
                                  <p className="ps-2 text-black">Delete</p>
                                </div>
                              </li>

                              <Link
                                className="cursor text-dark"
                                //  9/20/dashboard
                                //   to={

                                //   }
                                to={
                                  "/outlet/" +
                                  id_value.brand_id +
                                  "/" +
                                  data.id +
                                  "/dashboard"
                                }
                              >
                                <li className="mb-2 cursor">
                                  <div className="dropdown-item d-flex align-items-center">
                                    {/* <AntDesign name="edit" size={18} color="#555" />/}
                                    <img
                                      src={require("../../../assets/icon/svg/trending-up.svg")}
                                      className="me-2 action_menu_img cursor"
                                    />
                                    <p className="ps-2 text-black">
                                      Outlet Dashboard
                                    </p>
                                  </div>
                                </li>
                              </Link>

                              <Link
                                className="cursor text-dark"
                                to={
                                  "/brand/" +
                                  id_value.brand_id +
                                  "/outlet_call_reminder/" +
                                  data.id
                                }
                              >
                                <li className="mb-2 cursor">
                                  <div className="dropdown-item d-flex align-items-center">
                                    <img
                                      src={require("../../../assets/icon/svg/trending-up.svg")}
                                      className="me-2 action_menu_img cursor"
                                    />
                                    <p className="ps-2 text-black">
                                      Call Reminder
                                    </p>
                                  </div>
                                </li>
                              </Link>
                            </ul>
                          </div>
                        </div>

                        <div className="col-6 mt-2 ps-0">
                          <div className="d-flex align-items-center pt-2">
                            <Feather name="phone-call" size={14} color="#777" />
                            <p className="ps-2 text-black fw-bold">
                              {data.mobile}
                            </p>
                          </div>
                        </div>

                        <div className="col-6 mt-2 pe-0">
                          <div className="end pt-2">
                            <FontAwesome
                              name="whatsapp"
                              size={16}
                              color="#777"
                            />
                            <p className="ps-2 text-black fw-bold">
                              {data.whatsapp}
                            </p>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex align-items-center pt-2">
                        <Ionicons
                          name="md-mail-outline"
                          size={16}
                          color="#777"
                        />
                        <p className="ps-2 text-gray">{data.email}</p>
                      </div>

                      <div className="d-flex align-items-center pt-2">
                        <Fontisto name="world-o" size={15} color="#777" />
                        <p className="ps-2 text-gray">{data.website}</p>
                      </div>

                      <div className="pt-2">
                        <p className="text-black fw-bold small">
                          <span className="pe-1">Created User :</span>
                          {data.created_user}
                        </p>
                      </div>

                      <div className="pt-2">
                        <img
                          className="outlet-map-img"
                          src={
                            "https://maps.google.com/maps/api/staticmap?center=" +
                            data.latitude +
                            "," +
                            data.longitude +
                            "&markers=size:mid%7Ccolor:red%7C" +
                            data.latitude +
                            "," +
                            data.longitude +
                            "&zoom=6&size=800x300&sensor=false&key="+AppConfig.google_map_api
                          }
                        />
                        {/* amp; /}

                        {/* <img className="img-fluid mb-3"src={"https://maps.google.com/maps/api/staticmap?center=" + data?.latitude +"," +data?.longitude + "&markers=size:mid%7Ccolor:red%7C"+ data?.latitude +"," +data?.longitude +"&zoom=15&amp;size=600x200&amp;sensor=false&amp;key="+AppConfig.google_map_api}/> /}
                      </div> */}
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className="p-2">
                <div className="center card p-3">
                  <div className="text-center">
                    <img
                      src={
                        AppConfig.CDN_Image_url +
                        "e38cbe608dba40c81ec58d266c09055f.png"
                      }
                      className="empty-img pb-2"
                    />
                    <p className="v-small mb-0">No Data, Please Add Outlet</p>
                  </div>
                </div>
              </div>
            )}

            {/* <ModalCreateOutlet
            open={edit_outlet_modal.is_open}
            franchise_id={props.hasOwnProperty('franchise_id') ? props.franchise_id : null}
            close={(data) => {
              console.log("Create Outlet Modal On Close :", data);
              SetCreateOutletModal((oldValues) => ({
                ...oldValues,
                is_open: false,
              }));
              if (data.action == "save") {
                SetOutletData((prevValue) => {
                  console.log("prevValue :", prevValue);
                  if (prevValue == undefined) {
                    prevValue = [];
                    prevValue.push(data.value);
                  } else {
                    prevValue.push(data.value);
                  }
                  return [...prevValue];
                });
              }
            }}
          />  */}

            {/* <ModalEditOutlet
            open={edit_outlet_modal.is_open}
            data={edit_outlet_modal.data}
            index={edit_outlet_modal.index}
            close={(data:any) => {
              console.log("Edit Outlet Modal On Close :", data);
              SetEditOutletModal({
                is_open: false,
                data: {},
                index: "",
              });
              if (data.action == "edit") {
                SetOutletData((prevValue) => {
                  prevValue[data.index] = data.value;
                  return [...prevValue];
                });
              }
            }}
          />  */}
          </div>
        </div>
      </div>

      {/* <DeleteAlert
        open={delete_modal.is_open}
        title={delete_modal.title}
        record={delete_modal.record}
        index={delete_modal.index}
        close={(data:any) => {
          SetDeleteModal((prevValue) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });

          if (data.action === "delete") {
            console.log("delete Outlet : ", data);
            delete_outlet(data.value, data.index);
            // delete_service(data.value, data.index);
          }
        }}
      /> */}

      <ModalDeleteOutlet
        open={deleteModal.is_open}
        id={deleteModal.id}
        close={(data: any) => {
          // console.log("Franchise modl on close :", data);
          SetDeleteModal((prevValue: any) => {
            prevValue.is_open = false;
            return { ...prevValue };
          });
          if (data.action == "save") {
            get_outlets();
          }
        }}
      />

      {/* <ModalCreateOutlet
        open={create_outlet_modal.is_open}
        franchise_id={
          props.hasOwnProperty("franchise_id") ? props.franchise_id : null
        }
        close={(data:any) => {
          console.log("Create Outlet Modal On Close :", data);
          SetCreateOutletModal((oldValues) => ({
            ...oldValues,
            is_open: false,
          }));
          if (data.action == "save") {
 

            get_outlets();
          }
        }}
      /> */}

      <ModalEditOutlet
        open={edit_outlet_modal.is_open}
        data={edit_outlet_modal.data}
        index={edit_outlet_modal.index}
        close={(data: any) => {
          console.log("Edit Outlet Modal On Close :", data);
          SetEditOutletModal({
            is_open: false,
            data: {},
            index: "",
          });
          if (data.action == "edit") {
            SetOutletData((prevValue) => {
              prevValue[data.index] = data.value;
              return [...prevValue];
            });
          }
        }}
      />

      {/* 
      {["left", "right", "top", "bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            // onClose={toggleDrawer(anchor, false)}
            // onOpen={toggleDrawer(anchor, true)}
            onClose={(event) => {
              swipe_fun(anchor, false, event);
            }}
            onOpen={(event) => {
              swipe_fun(anchor, true, event);
            }}
          >
            {list(anchor)}
          </SwipeableDrawer>
        </React.Fragment>
      ))} */}
    </div>
  );
}
