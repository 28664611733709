import React, { useState, useEffect, useContext } from "react";
import bootstrap from "bootstrap/dist/js/bootstrap";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams } from "react-router-dom";
import { api } from "../../../utils/Api";
import {
  MaterialCommunityIcons,
  Ionicons,
  MaterialIcons,
} from "@expo/vector-icons";
let myModal = {};
import Skeleton from "react-loading-skeleton";

export default function ModalVariants(props: any) {
  const [close_data, SetCloseData] = useState({
    action: "close",
  });
  const [id_value, SetIdValue] = useState(useParams());
  const [varients_data, SetVarientsData] = useState([]);
  const [product_name, SetProductName] = useState([]);
  const [product_id, SetProductId] = useState();
  const [type, SetType] = useState();
  const [unavailablity_check, SetUnavailablityCheck] = useState(false);
  const [is_loading, SetIsLoading] = useState(true);

  const [product_check, SetProductCheck] = useState(false);

  useEffect(() => {
    if (props.open) {
      console.log("ModalFranchise On Open :", props);
      myModal = new bootstrap.Modal(
        document.getElementById("VariantModal"),
        {}
      );
      // SetVarientsData(props.data);
      // SetProductName(props.product);


      get_varients(props.id);
      SetType(props.type);
      // console.log(props);
      // console.log(props.type);

      // console.log(props.checked);
      // console.log(props.product);
      // SetUnavailablityCheck(props.unavailable);
      // console.log(props.unavailable);

      // SetProductCheck(props.checked);
      myModal.show();
    }
  }, [props.open]);

  useEffect(() => {
    console.log("ModalFranchise On mount :", props);
    let myModal1 = document.getElementById("VariantModal");
    myModal1.addEventListener("hidden.bs.modal", function (event) {
      SetVarientsData([]);

      props.close(close_data);
    });
  }, []);

  async function get_varients(id) {
    SetIsLoading(true);
    let pass_data = {};
    pass_data = {
      get: {
        brand: id_value.brand_id,
        product_id: id,

      },
    };

    let response: any = await api("/brand/brand_product_details_init", pass_data);
    console.log("/brand/add_brand_product_list :", response);
    if (response.status_code == 200) {

      SetVarientsData(response.response.data);
      SetProductName(response.response.product);
      SetUnavailablityCheck(response.response.data[0].unavailable);
    SetIsLoading(false);

      //  SetType(response.response.data[0]);
      // SetType(response.response.type);
      // console.log(props);
      // console.log(props.type);

      // console.log(props.checked);
      // console.log(props.product);
      // SetUnavailablityCheck(response.response.unavailable);
      // console.log(props.unavailable);

      // SetProductCheck(response.response.checked);
      // SetData(response.response);

      // if (response.response.hasOwnProperty("data")) {
      //   SetProductData(response.response.data);
      //   SetFilter(response.response.filter);
      //   SetNextPage(response.response.next_page);
      // } else {
      //   SetProductData([]);
      // }
    }
  }

  function check_product(event: any) {
    // SetUnavailablityCheck(props.unavailable);
    SetUnavailablityCheck(!unavailablity_check)
    let key_name = event.target.name;

    SetVarientsData((prevValue) => {
      console.log(!varients_data[0].checked);
      console.log(prevValue[0]);

      {
        type == "available"
          ? (prevValue[0].unavailable = !prevValue[0].unavailable)

          : (prevValue[0].checked = !prevValue[0].checked,
            prevValue[0].variants?.map((item, index) => (
              item.checked = true,
              item.addons = true,

              item.addon_group?.map((ele) => (
                ele.checked = true,

                ele.addon_items?.map((el) => (
                  el.checked = true
                ))
              ))

            ))
          )

        // prevValue[0].variants[index].addons= true,
        // prevValue[0].variants[index].addon_group[i].checked=true,
        // prevValue[0].variants[index].addon_group[i].addon_items[j].checked)

      }

      console.log(prevValue[0]);

      return [...prevValue];
    });
  }


  function check_varient(event: any, index: any) {
    console.log(index);

    let key_name = event.target.name;

    SetVarientsData((prevValue) => {
      console.log(!varients_data[0].variants[index].checked);
      console.log(prevValue[index]);


      {
        type == "available"
          ? (prevValue[0].variants[index].unavailable = !prevValue[0].variants[index].unavailable)
          : (prevValue[0].variants[index].checked = !prevValue[0].variants[index].checked);

      }

      console.log(prevValue[0].variants[index]);

      return [...prevValue];
    });
  }

  function check_addon(event: any, index: any) {
    console.log(index);

    let key_name = event.target.name;

    SetVarientsData((prevValue) => {
      console.log(!varients_data[index]?.checked);
      console.log(prevValue[index]);

      {
        type == "available"
          ? (prevValue[0].variants[index].addons_unavailable =
            !prevValue[0].variants[index].addons_unavailable)
          : (prevValue[0].variants[index].addons = !prevValue[0].variants[index].addons);
      }

      return [...prevValue];
    });
  }


  function check_addon_group(event: any, index: any, i: any) {
    console.log(index);

    let key_name = event.target.name;

    SetVarientsData((prevValue) => {
      console.log(!varients_data[index]?.checked);
      console.log(prevValue[index]);

      {
        type == "available"
          ? (prevValue[0].variants[index].addon_group[i].unavailable =
            !prevValue[0].variants[index].addon_group[i].unavailable)
          : (prevValue[0].variants[index].addon_group[i].checked =
            !prevValue[0].variants[index].addon_group[i].checked);
      }

      return [...prevValue];
    });
  }

  function check_addon_item(event: any, index: any, i: any, j: any) {
    console.log(index);

    let key_name = event.target.name;

    SetVarientsData((prevValue) => {
      console.log(!varients_data[index]?.checked);
      console.log(prevValue[index]);

      {
        type == "available"
          ? (prevValue[0].variants[index].addon_group[i].addon_items[j].unavailable =
            !prevValue[0].variants[index].addon_group[i].addon_items[j].unavailable)
          : (prevValue[0].variants[index].addon_group[i].addon_items[j].checked =
            !prevValue[0].variants[index].addon_group[i].addon_items[j].checked);
      }

      return [...prevValue];
    });
  }

  async function add_varients() {
    var element: any = {},
      arr = [];
    element.id = varients_data[0].id;
    element.checked = varients_data[0].checked;
    element.unavailable = varients_data[0].unavailable;
    element.variants = varients_data[0].variants;

    console.log(element);

    arr.push(element);
    console.log(arr);


    console.log(varients_data);

    let pass_data = {
      get: {
        brand: id_value.brand_id,
      },
      post: {
        product_data: arr,
      },
    };
    console.log("pass_data :", pass_data);

    let data_res: any =
      type == "available"
        ? await api("/brand/brand_product_availability_v2", pass_data)
        : await api("/brand/add_brand_product_v2", pass_data);

    // let data_res: any = await api("/brand/add_brand_product", pass_data);
    console.log("edit_outlet response :", data_res.response);
    console.log("edit_outlet response :", data_res.status_code);
    if (data_res.status_code == 200) {
      SetVarientsData([]);
      console.log("/outlet/edit_service status_code 200");
      console.log("view close data:", close_data);

      let item: any = close_data;
      item.action = "add";
      item.value = data_res.response.data[0];
      item.index = props.index;

      SetCloseData(item);
      myModal.hide();
    }

  }

  return (

    <div
      className="modal"
      id="VariantModal"
      tabIndex={-1}
      aria-labelledby="ModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-fullscreen-sm-down modal-dialog-scrollable">
        <div className="modal-content">
          <div className="modal-header">

            <h6 className="mb-0">Product Details</h6>
            <button
              type="button"
              className="btn-close"
              aria-label="Close"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                myModal.hide();
              }}
            ></button>
          </div>
          <div className="modal-body">

  {is_loading ? (
            // skeleton
            <div className="mb-2">
      <div className="border radius p-2 w-100">
        {/* Variant name skeleton */}
        <div className="form-check form-switch">
          <label className="form-label d-flex align-items-center">
            <Skeleton circle={true} height={24} width={24} />
            <p className="ps-2 text-black">
              <Skeleton width={100} />
            </p>
          </label>
          <p className="mb-0 text-gray small">
            <Skeleton width={150} />
          </p>
          <div className="mt-3">
            <div className="row">
              {[1, 2].map((_, i) => (
                <div className="col-6" key={i}>
                  <Skeleton width={100} />
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* Addons section skeleton */}
        <div className="form-check form-switch my-2">
          <label className="form-check-label fw-bold text-black">
            <Skeleton circle={true} height={24} width={24} />
            <Skeleton width={100} className="ms-2" />
          </label>
        </div>
        <div className="row px-3">
          {[1, 2].map((_, i) => (
            <div className="col-md-6" key={i}>
              <div className="form-check form-switch py-1">
                <label className="form-check-label text-black">
                  <Skeleton circle={true} height={24} width={24} />
                  <Skeleton width={100} className="ms-2" />
                </label>
                <div className="px-3 mt-2">
                  {[1, 2].map((_, j) => (
                    <div className="form-check form-switch mb-3" key={j}>
                      <label className="form-check-label">
                        <Skeleton circle={true} height={24} width={24} />
                        <Skeleton width={100} className="ms-2" />
                      </label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
          ) :
          (
            <>

            {console.log(type)}
            {type == "available" ? (
              <div className="form-check form-switch d-flex justify-content-start align-items-center" >
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={!unavailablity_check}
                  // onChange={(e) => {

                  //   SetUnavailablityCheck(!unavailablity_check);
                  // }}

                  onChange={(e) => {
                    check_product(e);
                  }}
                  id="switch"
                />
                <label className="form-check-label" for="switch">
                  {" "}
                  <h6 className="modal-title mb-0 ps-2" id="ModalLabel">
                    {varients_data[0]?.name}
                  </h6>
                </label>
              </div>
            ) : (
              <div className="mb-2 d-flex align-items-center">

                <h6 className="mb-0 fw-500 modal-title" id="ModalLabel">
                <input
                    type="checkbox"
                    className="me-2 form-check-input"
                    checked={varients_data[0]?.checked}
                    name="checked"
                    // key= {index}
                    onChange={(e) => {
                      check_product(e);
                    }}
                  />
                {varients_data[0]?.name}
                </h6>
                <label
                  className="form-label d-flex align-items-center ms-auto mb-0"
                  id="ModalLabel"
                >
           
                </label>
              </div>
            )}
          
            <div className="mt-3">
              {varients_data[0]?.variants?.map((item, index) => (
                <div className="mb-2">
                  {/* {console.log("index",index)} */}
                  <div className="border radius p-2 w-100">
                    {type == "available" ? (
                      <div className="form-check form-switch" >
                        <label className="form-label d-flex">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={!item.unavailable}
                            disabled={unavailablity_check}
                            onChange={(e) => {
                              check_varient(e, index);
                            }}
                            id="switch"
                          />
                          <p className="ps-2 text-black" >
                            {item.name}
                          </p>
                        </label>
                        <p className="mb-0 text-gray small" >{item.description}</p>
                        <div className="mt-3">
                          <div className="row">
                            {item?.variant_data?.map((ele) => (
                              <div className="col-6">
                                <p>
                                  <small className="text-black pe-1 fw-500">{ele.name} :</small>
                                  <small className="text-gray fw-500">{ele.value}</small>
                                </p>
                              </div>
                              // <div className="col-6">
                              //     <p>Size : M</p>
                              // </div>
                              // <div className="col-6">
                              //     <p>MRP : 250</p>
                              // </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        <label className="form-label d-flex pt-2">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            disabled={!varients_data[0]?.checked}
                            checked={item.checked}
                            name="checked"
                            // key= {index}
                            onChange={(e) => {
                              check_varient(e, index);
                            }}
                          />
                          <p className="ps-2 text-black" >
                            {item.name}
                          </p>
                        </label>

                        <p className="mt-2  text-gray small ">{item.description}</p>
                        <div className="mt-3">
                          <div className="row">
                            {item.variant_data?.map((ele) => (
                              <div className="col-6">
                                <p>
                                  <small className="text-black pe-1 fw-500">{ele.name} :</small>
                                  <small className="text-gray fw-500">{ele.value}</small>
                                </p>
                              </div>
                              // <div className="col-6">
                              //     <p>Size : M</p>
                              // </div>
                              // <div className="col-6">
                              //     <p>MRP : 250</p>
                              // </div>
                            ))}
                          </div>
                        </div>
                      </>
                    )}


                    {item.addon_group ?
                      type == "available" ? (
                        <div className="form-check form-switch my-2" >
                          <label className="form-check-label fw-bold text-black">

                          <input
                            type="checkbox"
                            className="form-check-input"
                            checked={!item.addons_unavailable}
                            disabled={unavailablity_check || item.unavailable}
                            onChange={(e) => {
                              check_addon(e, index);
                            }}
                            id="switch"
                          />
                            {" "}
                            Addons
                          </label>
                        </div>
                      ) : (
                        <div className="form-check my-2">
                          <label className="form-label fw-bold text-black" htmlFor="addon-toggle">

                          <input
                            className="form-check-input me-2"
                            type="checkbox"
                            // id="addon-toggle"
                            disabled={!varients_data[0]?.checked || !item.checked}
                            checked={item.addons}
                            onChange={(e) => {
                              check_addon(e, index);
                            }}
                          />
                            Addons
                          </label>
                        </div>
                      )
                      : ""}
                    <div className="row px-3">
                      {item.addon_group?.map((ele, i) =>
                        type == "available" ? (
                          <div className="col-md-6">
                            <div
                              className="form-check form-switch py-1"

                            >
                              <label className="form-check-label text-black" >

                              <input
                                type="checkbox"
                                className="form-check-input mb-3"
                                disabled={
                                  item.addons_unavailable ||
                                  !varients_data[0]?.checked ||
                                  item.unavailable
                                }
                                checked={!ele.unavailable}
                                onChange={(e) => {
                                  check_addon_group(e, index, i);
                                }}
                                id="switch"
                              />
                                {" "}
                                {ele.name}
                              </label>

                              <div className="px-3 mt-2">
                                {ele.addon_items?.map((val, j) => (
                                  <>
                                    <div
                                      className="form-check form-switch mb-3"

                                    >
                                          <label
                                        className="form-check-label"
                                    
                                      >
                                      <input
                                        type="checkbox"
                                        className="form-check-input"
                                        disabled={
                                          ele.unavailable ||
                                          item.addons_unavailable ||
                                          unavailablity_check ||
                                          item.unavailable
                                        }
                                        checked={!val.unavailable}
                                        onChange={(e) => {
                                          check_addon_item(e, index, i, j);
                                        }}
                                        id="switch"
                                      />
                                  
                                        {" "}
                                        {val.name}
                                      </label>
                                    </div>

                                  </>
                                ))}
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="col-md-6">
                            <label
                              className="mb-2 form-label d-flex align-items-center text-black modal-title"
                              id="ModalLabel"
                            >
                              <input
                                type="checkbox"
                                className="me-2 form-check-input"
                                disabled={
                                  !item.addons ||
                                  !varients_data[0]?.checked ||
                                  !item.checked
                                }
                                checked={ele.checked}
                                onChange={(e) => {
                                  check_addon_group(e, index, i);
                                }}

                              />

                              {ele.name}  {"( " + ele.notes + " )"}
                            </label>
                            {/* <p className="mb-0"></p> */}

                            <div className="px-5 mt-2">
                              {ele.addon_items?.map((val, j) => (
                                <>
                                  <label
                                    className="mb-3 form-label text-gray d-flex align-items-center modal-title"
                                    id="ModalLabel"
                                  >
                                    <input
                                      type="checkbox"
                                      className="me-2 form-check-input"
                                      disabled={
                                        !item.addons ||
                                        !ele.checked ||
                                        !varients_data[0]?.checked ||
                                        !item.checked
                                      }
                                      checked={val.checked}
                                      onChange={(e) => {
                                        check_addon_item(e, index, i, j);
                                      }}
                                    />
                                    {val.name}
                                  </label>
                                  {/* <p className="mb-2">
                                     {"( " + val.notes + " )"}
                                  </p> */}
                                </>
                              ))}
                            </div>
                          </div>
                        )
                      )}
                    </div>
                  </div>

                </div>
              ))}


            </div>
            </>
          )}
          </div>
          <div className="modal-footer ">
            <button
              type="button"
              className="btn btn-secondary-outline btn-radius px-3 btn-sm"
              onClick={() => {
                let item = close_data;
                item.action = "close";
                SetCloseData(item);
                // SetSaveData(initial_value);
                myModal.hide();
              }}
            >
              Close
            </button>
            <div className="ms-auto ms-3">
              <button
                type="button"
                className="btn bg-prime text-white px-4 btn-radius btn-sm"
                onClick={() => {
                  add_varients();
                }}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
