import React, { useState, useEffect, useContext } from "react";
import DataContext from "../../../utils/Context/DataContext";
import { Link, useParams, useOutletContext } from "react-router-dom";
import { api } from "../../../utils/Api";
import Skeleton from "react-loading-skeleton";
import { AppConfig } from "../../../config";

import {
  MaterialCommunityIcons,
  Ionicons,
  MaterialIcons,
} from "@expo/vector-icons";
import ModalVariants from "./ModalVariants";
import Typography from "@material-ui/core/Typography";
import Slider from "@material-ui/core/Slider";
import { AppHelper } from "../../../utils/Helper";
import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";

function valuetext(value) {
  return `${value}°C`;
}

export default function AddProducts(props: any) {
  const context = useContext(DataContext);
  const OutletContext = useOutletContext();
  const [modal_variants, SetModalVariants] = useState({
    is_open: false,
    // product_name: "",
    product_id: "",
    // product_checked: "",
    // data: {},
  });
  const [text, setText] = useState("");
  const [id_value, SerIdValue] = useState(useParams());
  const [filter_open, SetFilterOpen] = useState(false);
  const [product_data, SetProductData] = useState([]);
  const [data, SetData] = useState([]);
  const [variant, SetVariant] = useState([]);
  const [filter, SetFilter] = useState([]);
  const [timer, SetTimer] = useState(null);
  const [sort_open, SetSortOpen] = useState(false);
  const [min_max_value, SetMinMaxValue] = React.useState(["", ""]);
  const [brand, SetBrand] = useState([]);
  const [category, SetCategory] = useState([]);
  const [f_products, SetFproducts] = useState({
    own_products: false,
    others_products: false,
  });
  const [sort, SetSort] = useState("");
  const [count, SetCount] = useState(10);

  const [search, SetSearch] = useState("");
  const [page_no, SetPageNo] = useState(1);
  const [next_page, SetNextPage] = useState(false);
  const WAIT_INTERVAL = 700;
  const PRICE_WAIT_INTERVAL = 2000;
  const [width, SetWidth] = useState(innerWidth);
  const [is_loading, SetIsLoading] = useState(true);

  // const [sliderWidth, SetSlideWidth] = useState(0);
  // const [offsetSliderWidht, SetOffsetSlideWidth] = useState(0);
  // const [min, SetMin] = useState(0);
  // const [max, SetMax] = useState(200);
  // const [minValueBetween, SetMinValueBetween] = useState(10);
  // const [currentMin, SetCurrentMin] = useState(55);
  // const [inputMin, SetInputMin] = useState(55);
  // const [currentMax, SetCurrentMax] = useState(100);
  // const [inputMax, SetInputMax] = useState(100);

  useEffect(() => {
    console.log("Add Product On mount :", props);
    console.log("Add Product context :", context);
    console.log("ProductAssign OutletContext :", OutletContext);
    get_add_products(page_no, count, min_max_value, text, sort);
    console.log(f_products);
  }, []);

  function onTextChanged(e: any) {
    clearTimeout(timer);
    const search_text = e.target.value;
    setText(search_text);
    console.log("Text onchange: ", search_text);
    if (search_text == "") {
      let page_no_value = 1;
      SetPageNo(page_no_value);
      get_add_products(page_no_value, count, min_max_value, search_text, sort);
    } else {
      SetTimer(
        setTimeout(() => {
          get_add_products(1, count, min_max_value, search_text, sort);
        }, WAIT_INTERVAL)
      );
    }
  }

  function countChange(e: any) {
    console.log("countChange:", e.target.value);
    SetCount(e.target.value);
    get_add_products(1, e.target.value, min_max_value, text, sort);
  }

  const handleChange = (event: any, newValue: any) => {
    clearTimeout(timer);
    SetMinMaxValue(newValue);
    console.log(newValue);

    if (newValue != "") {
      SetTimer(
        setTimeout(() => {
          get_add_products(1, count, newValue, text, sort);
        }, PRICE_WAIT_INTERVAL)
      );
    }
    // SetMinMaxValue(newValue);

    // get_add_products(1,count);
  };

  async function get_add_products(
    page_value: any,
    count_value: any,
    f_min_max_value: any,
    search_value: any,
    sort_value: any,
  ) {
    let pass_data = {};
    SetPageNo(page_value);
    SetIsLoading(true);
    if (search_value != null) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_value,
          search: search_value,
          filter_brand: encodeURIComponent(JSON.stringify(brand)),
          filter_categories: encodeURIComponent(JSON.stringify(category)),
          filter_min_mrp: f_min_max_value[0],
          filter_max_mrp: f_min_max_value[1],
          sort: sort_value,
          filter_products: encodeURIComponent(JSON.stringify(f_products)),
          filter_count: count_value,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_value,
          filter_brand: encodeURIComponent(JSON.stringify(brand)),
          filter_categories: encodeURIComponent(JSON.stringify(category)),
          filter_min_mrp: f_min_max_value[0],
          filter_max_mrp: f_min_max_value[1],
          sort: sort_value,
          filter_products: encodeURIComponent(JSON.stringify(f_products)),
          filter_count: count_value,
        },
      };
    }
    let response: any = await api(
      "/brand/add_brand_product_list_v2",
      pass_data
    );
    console.log("/brand/add_brand_product_list :", response);
    if (response.status_code == 200) {
      SetData(response.response);

      if (response.response.hasOwnProperty("data")) {
        SetProductData(response.response.data);
        SetFilter(response.response.filter);
        SetNextPage(response.response.next_page);
      } else {
        SetProductData([]);
      }

    SetIsLoading(false);

    }
  }

  async function input_on_change(event) {
    console.log("input_on_change value", event.target.name);

    console.log("input_on_change value", event.target.checked);
    console.log("input_on_change value", f_products.others_products);

    let arr = [];

    if (event.target.checked == true) {
      if (event.target.name == "own products") {
        f_products.own_products = true;
      }
    } else {
      if (event.target.name == "own products") {
        f_products.own_products = false;
      }
    }

    if (event.target.checked == true) {
      if (event.target.name == "others products") {
        f_products.others_products = true;
      }
    } else {
      if (event.target.name == "others products") {
        f_products.others_products = false;
      }
    }

    if (event.target.checked == true) {
      if (event.target.name == "filter_brand") {
        brand.push(event.target.value);
      }
    } else {
      if (event.target.name == "filter_brand") {
        brand.splice(brand.indexOf(event.target.value, 1));
      }
    }

    console.log(brand);
    SetBrand(brand);

    if (event.target.checked == true) {
      if (event.target.name == "filter_category") {
        category.push(event.target.value);
      }
    } else {
      if (event.target.name == "filter_category") {
        category.splice(brand.indexOf(event.target.value, 1));
      }
    }

    get_add_products(1, count, min_max_value, text, sort);
  }

  function dataSort() {
    if (sort == 'asc') {
      SetSort('desc')
      get_add_products(1, count, min_max_value, text, 'desc')
    } else {
      SetSort('asc')

      get_add_products(1, count, min_max_value, text, 'asc')
    }
  }

  async function load_more(search_value = "") {
    let page_no_value = page_no + 1;
    let pass_data = {};

    SetPageNo(page_no_value);
    if (search_value != null) {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
          search: text,
          filter_brand: encodeURIComponent(JSON.stringify(brand)),
          filter_categories: encodeURIComponent(JSON.stringify(category)),
          filter_min_mrp: min_max_value[0],
          filter_max_mrp: min_max_value[1],
          sort: sort,
          filter_products: encodeURIComponent(JSON.stringify(f_products)),
          filter_count: count,
        },
      };
    } else {
      pass_data = {
        get: {
          brand: id_value.brand_id,
          page: page_no_value,
          filter_brand: encodeURIComponent(JSON.stringify(brand)),
          filter_categories: encodeURIComponent(JSON.stringify(category)),
          filter_min_mrp: min_max_value[0],
          filter_max_mrp: min_max_value[1],
          sort: sort,
          filter_products: encodeURIComponent(JSON.stringify(f_products)),
          filter_count: count,
        },
      };
    }
    let response: any = await api(
      "/brand/add_brand_product_list_v2",
      pass_data
    );
    console.log("/brand/add_brand_product_list :", response);
    SetProductData((prevValue) => {
      response.response.data.map((item) => {
        prevValue.push(item);
      });
      return [...prevValue];
    });
    SetNextPage(response.response.next_page);
  }

  return (
    <div>
      <div className={OutletContext ? "head-bar sticky-sm" : "head-bar sticky-sm"}
        style={OutletContext == false ? { width: width } : {}} >
        <div className="row align-items-center pb-1">
          <div className="col-9 ps-2">
            <div className="d-flex align-items-center">
              <Link to={"/brand/" + id_value.brand_id + "/product_group"}>
                <MaterialCommunityIcons name="arrow-left" size={20} color="black" />
              </Link>
              <h6 className="mb-0 ms-1">Add Products</h6>
            </div>
          </div>

          <div className="col-3 pe-0 ps-sm-0 ps-md-2">
            <div
              className="px-3 cursor end"
              onClick={() => {
                dataSort()
              }}
            >
              <MaterialCommunityIcons name="sort-reverse-variant" size={16} color="#555" />
              <p className='text-darkgray ps-2 fw-bold '>Sort</p>
            </div>
          </div>

        </div>
        <div className="py-2 row align-items-center">
          <div className="col-md-6 col-9 sm-mb-2 md-mb-0">
            <div className="row md-none">
              <div className="input-icons form-group">
                <input
                  type="text"
                  className="form-control search-bar p-0"
                  placeholder="Search products"
                  defaultValue={text}
                  onChange={(e) => {
                    onTextChanged(e);
                  }}
                />
                <div className="cursor ps-2 border-left">
                  <Ionicons name="md-search" size={18} color="#663fc3" />
                </div>
              </div>
            </div>
          </div>

          <div className="col-3 col-md-2 pe-0 sm-mb-2 md-mb-0 ps-2">
            <select
              className="number-select form-select btn-radius form-control pe-0 ps-2" defaultValue="10" onChange={(e) => {
                countChange(e);
              }}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="30">30</option>

              <option value="40">40</option>

              <option value="50">50</option>
            </select>
          </div>




        </div>
      </div>
      {/* <div className=" row align-items-center head-bar">
        <div className="col-7 sm-mb-2 d-flex align-items-center">
          <Link to={"/brand/" + id_value.brand_id + "/product_group"}>
            <img
              src={require("../../../assets/icon/svg/arrow-left.svg")}
              className="cursor pe-2"
            />
          </Link>
          <h6 className="  mb-1">Add Products</h6>
        </div>
        {/* <div className="col-5 ps-0 text-end sm-mb-2">
          <Link to={"/brand/" + id_value.brand_id + "/product_assign"}>
            <button className="btn btn-primary btn-sm">Create Products</button>
          </Link>
        </div>
      </div> */}

      <div className={OutletContext ? "mt-3 px-md-3 px-sm-0" : "mt-3 px-md-3 px-sm-0"}
        style={OutletContext == false ? { width: width } : {}} >
        <div className="row p-3 pt-0">
          <div className="col-md-3 px-0 d-sm-none d-md-block">
            <div className="bg-fff radius p-3 sticky-md-50px overflowY-auto scrollw-none">
              <div className="d-flex ">
                <h6 className='fs-5 fw-500'>Filter</h6>
                <div className="ms-auto cursor">
                  <small className='text-black'>Clear</small>
                </div>
              </div>
              <div className="my-3">
                <label className="form-label d-flex align-items-center mb-3 cursor">
                  <input
                    type="checkbox"
                    className="form-check-input me-2"
                    name={"own products"}
                    // value={ele.id}
                    onChange={(event) => {
                      input_on_change(event);
                    }}
                  />
                  <p className='capitalize'>{"own product"}</p>
                </label>

                <label className="form-label d-flex align-items-center mb-3 cursor">
                  <input
                    type="checkbox"
                    className="form-check-input me-2"
                    name={"others products"}
                    // value={ele.id}
                    onChange={(event) => {
                      input_on_change(event);
                    }}
                  />
                  <p className='capitalize'>{"other product"}</p>
                </label>
              </div>
              <div className="d-flex ">
                <h6>Category</h6>
              </div>
              <div className="my-3">
                {filter.product_categories?.map((ele: any) => (
                  <label className="form-label d-flex align-items-center mb-3 cursor">
                    <input
                      type="checkbox"
                      className="form-check-input me-2"
                      name={"filter_category"}
                      value={ele.id}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                    <p className='capitalize'>{ele.name}</p>
                  </label>
                ))}
              </div>

              <div className="brand border-top border-bottom">
                <Accordion className='px-0'>
                  <AccordionSummary
                    expandIcon={<MaterialIcons name="keyboard-arrow-down" size={20} color="black" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <p className='fs-7 fw-bold text-black'>Brand</p>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className='w-100'>
                      <input
                        type="text"
                        placeholder="Search..."
                        className="form-control btn-radius"
                      />
                      <div className="p-2">
                        {filter.product_brands?.map((ele: any) => (
                          <label className="form-label d-flex align-items-center mb-3 cursor">
                            <input
                              type="checkbox"
                              className="form-check-input me-2"
                              name={"filter_brand"}
                              value={ele.id}
                              onChange={(event) => {
                                input_on_change(event);
                              }}
                            />
                            <p className='capitalize'>{ele.name}</p>
                          </label>
                        ))}
                      </div>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>

              <div className="mt-3">
                <p className='text-black' id="range-slider">
                  Mininum Maximum Price
                </p>
                <div className="d-flex align-items-center">
                  {/* <p className="pe-3">Min</p> */}
                  <Slider
                    value={min_max_value}
                    onChange={handleChange}
                    step={20}

                    onClick={() => {
                      console.log("min max :", min_max_value);
                    }}
                    valueLabelDisplay="auto"
                    aria-labelledby="range-slider"
                    getAriaValueText={valuetext}
                    min={0}
                    max={200000}
                  />
                  {/* <p className="px-3">Max</p> */}
                </div>

                <div className="row pt-2">
                  <div className="col-6">
                    <label className="form-label mb-1 cursor">Min Price</label>
                    <input
                      type="text"
                      className="form-control"
                      name={"min_mrp"}
                      value={min_max_value[0]}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                  </div>
                  <div className="col-6">
                    <label className="form-label mb-1 cursor">Max Price</label>
                    <input
                      type="text"
                      className="form-control"
                      name={"max_mrp"}
                      value={min_max_value[1]}
                      onChange={(event) => {
                        input_on_change(event);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-9 sm-mt-3">
            <div className="row">
              {/* {console.log(product_data)} */}
              {is_loading ? ( [...Array(6)].map((_, index) => (
    <div key={index} className="col-md-6 px-2 mb-3">
      <div className="p-3 card radius">
        <div className="row">
          <div className="col-3 pe-0 ps-1">
            <Skeleton height={100} width={100} />
          </div>
          <div className="col-9">
            <p className="mb-1">
              <Skeleton width={`80%`} />
            </p>
            <div className="d-flex align-items-center">
              <Skeleton width={16} height={16} />
              <p className="ps-1">
                <Skeleton width={100} />
              </p>
            </div>
            <div className="mt-2">
              <Skeleton width={80} height={30} />
            </div>
          </div>
        </div>
        <p className="text-gray small fw-500">
          <Skeleton count={2} />
        </p>
      </div>
    </div>
  ))): product_data.length>0? product_data.map((ele: any) => (
                <div className="col-md-6 mb-3 px-sm-0 px-md-2">
                  <div className="card p-3">
                    <div className="row">
                      <div className="col-3 pe-2">
                        {ele.images ? (
                          <img
                            src={AppHelper.imageSrc(data.image_base_url, ele.images[0]?.url, { format: "webp", width: 100 })}
                            className="prod-group-img"
                          />
                        ) : (
                          <img
                            src={require('../../../assets/img/no-img.jpg')}
                            alt="pic"
                            className="prod-group-img"
                          />
                        )}
                      </div>

                      <div className="col-6 p-0">
                        <h6 className='mb-2'>{ele.name}</h6>
                        <div className="d-flex">
                          <MaterialIcons name="category" size={14} color="gray" />
                          <p className='ps-1'><small className="text-black fw-500">
                            {ele.category}
                          </small></p>
                        </div>
                      </div>
                      <div className="col-2 p-0 ms-auto">

                        {ele.inactive_items ?
                          <button
                            className="btn bg-prime radius px-2 text-white btn-sm"
                            onClick={() => {
                              console.log("add variants");
                              // SetModalVariants((prevValue: any) => {
                              SetModalVariants({
                                // prevValue.is_open = true;
                                is_open: true,
                                // product_name: ele.name,
                                product_id: ele.id,
                                // product_checked: ele.checked,

                                // data: ele.variants,

                                // return { ...prevValue }
                              });
                            }}
                          >
                            Add
                          </button>
                          : <button
                            className="btn btn-outline-secondary px-2 radius btn-sm"
                            onClick={() => {
                              console.log("add variants");
                              // SetModalVariants((prevValue: any) => {
                              SetModalVariants({
                                // prevValue.is_open = true;
                                is_open: true,
                                // product_name: ele.name,
                                product_id: ele.id,
                                // product_checked: ele.checked,

                                // data: ele.variants,

                                // return { ...prevValue }
                              });
                            }}
                          >
                            View
                          </button>}

                      </div>
                    </div>
                    <div className="py-2">
                      <p className="disc-overflow text-gray small">
                        {ele.description}
                      </p>
                    </div>
                    <div className="py-2 border-bottom">
                      <p className='fs-7 fw-bold text-black'>
                        MRP :  ₹{ele.min_mrp} - ₹{ele.max_mrp}
                      </p>
                      <div className="text-end">
                        {ele.product_brand}
                      </div>
                    </div>

                    <div className="row py-2">
                      <div className="col-4">
                        <p className='text-black mb-2'>Variants</p>
                        <p className='text-gray'>{ele.total_variants +
                          '(' +
                          ele.total_active_variants +
                          ')'}</p>
                      </div>
                      {/* <div className="col-4">
                        <p className='text-black mb-2'>Addons</p>
                        <p className='text-gray'> {ele.total_addon_groups +
                          '(' +
                          ele.total_active_addon_groups +
                          ')'}</p>
                      </div> */}
                      <div className="col-4">
                        <p className='text-black mb-2'>Addon Items</p>
                        <p className='text-gray'> {ele.total_addon_items +
                          '(' +
                          ele.total_active_addon_items +
                          ')'}</p>
                      </div>
                    </div>
                    {/* <div className="row pt-3 ">
                      <div className="col-6">
                        <p>
                          Variants :{" "}
                          <span className="text-dark">
                            {ele.total_variants +
                              "(" +
                              ele.total_active_variants +
                              ")"}
                          </span>{" "}
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          Addons :{" "}
                          <span className="text-dark">
                            {ele.total_addon_groups +
                              "(" +
                              ele.total_active_addon_groups +
                              ")"}
                          </span>
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          Addon Items :{" "}
                          <span className="text-dark">
                            {ele.total_addon_items +
                              "(" +
                              ele.total_active_addon_items +
                              ")"}
                          </span>
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          Brand :{" "}
                          <span className="text-dark">{ele.product_brand}</span>
                        </p>
                      </div>
                      <div className="col-6">
                        <p>
                          Category :{" "}
                          <span className="text-dark">{ele.category}</span>
                        </p>
                      </div>
                      <div className="col-12">
                        <p>
                          MRP :{" "}
                          <span className="text-dark">
                            ₹{ele.min_mrp} - ₹{ele.max_mrp}{" "}
                          </span>
                        </p>
                      </div>
                    </div> */}
                  </div>
                </div>
              )) :  
              (  <div className="center p-3 bg-fff">
              <div>
              <img src={AppConfig.CDN_Image_url+"e38cbe608dba40c81ec58d266c09055f.png"} className="empty-img"
              />
                <p className="text-center">No data found!</p>
              </div>
            </div>
              )}
            </div>

            {
              next_page ? (
                <p
                  className="text-prime fw-bold fs-7 text-center my-2 cursor"
                  onClick={() => {
                    load_more();
                  }}
                >
                  Load More
                </p >
              ) : null}
          </div >


        </div >

        <ModalVariants
          open={modal_variants.is_open}
          // product={modal_variants.product_name}
          id={modal_variants.product_id}
          // checked={modal_variants.product_checked}
          // data={modal_variants.data}
          close={(data: any) => {
            console.log("Variants modl on close :", data);
            // SetModalVariants((prevValue: any) => {
            //     prevValue.is_open = false;
            //     return { ...prevValue }
            // })
            SetModalVariants({
              is_open: false,
              // product_name: "",
              product_id: "",
              // product_checked: "",

              // return { ...prevValue }
              // data: {},
            });

            if (data.action == "add") {
              get_add_products(1, count, min_max_value, text, sort);
            }
          }}
        />
      </div >
    </div >
  );
}
